import { useQuery } from "react-query";
import { customerBusinessService } from "../services";
import {AUTH_USER, GUEST_USER} from "../constants";

const getProfile = async (userId: string | number) => {
  if (!userId) return;
  const response = await customerBusinessService.getCustomerProfile(userId, {
    details: '[addresses,locations,vehicles]'
  })
  return response.data.data;
};

export default function useProfile(userId: string | number, userType: string = AUTH_USER) {
  return useQuery(["profile", userId], () => getProfile(userId), {
    retry: false,
    staleTime: Infinity,
    enabled: userType !== GUEST_USER
  });
}
