import React from 'react'

const TrackingVehicleDetails : React.FC<{
  make: string;
  model: string;
  color: string;
  titleStyle: string;
}> = ({ make, model, color, titleStyle }) => {
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center pb-4 pb-md-0">
        <div>
          <h4 className={`clr-dark-grey text-start h-22  ${titleStyle}`}>
          Vehicle Details
          </h4>
          <span className="d-block text-start font-Visby-cf fw-bold f-s16 f-w5 mb-2">
          {make} {model} {color}
          </span>
        </div>
        <div>
        </div>
      </div>
      <hr className="custom_hr_sty d-md-block d-none" />
    </div>
  )
}

export default TrackingVehicleDetails