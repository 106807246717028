import { useQuery } from "react-query";
import { customerBusinessService } from "../services";

const getVehicles = async () => {
    const response = await customerBusinessService.getVehicles()
    return response.data.data;
};

export default function useVehicleList() {
    return useQuery("vehicles", () => getVehicles(), {
        retry: false,
        staleTime: Infinity,
    });
}
