import React from "react";
import { persistZero } from "../../../helpers/helperMethods";

interface IItemPrice {
  price: number;
  discountPrice: number | string;
  isRedeemed: boolean;
}

const ItemPrice: React.FC<IItemPrice> = (props) => {
  const { price, isRedeemed, discountPrice } = props;
  return (
    <div className="text-center">
      <span className="d-inline-block f-w5 font-Vcf f-s16 lh-normal">
        {isRedeemed ? (
          <>
              <span className="free-tag d-inline-block me-2">{discountPrice > 0 ? `$${discountPrice}` : 'FREE'}</span>
              <span className="d-inline-block">
              <span className="clr-text-grey f-s14 font-Visby-cf d-block text-center mb-2">Total</span>
              <span className="del-text">{`\$${persistZero(price)}`}</span>
            </span>
          </>
        ) : (
            <>
            <span className="d-inline-block">
              <span className="clr-text-grey f-s14 font-Visby-cf d-block text-center mb-2">Total</span>
              <span>{`\$${persistZero(price)}`}</span>
            </span>
            </>
        )}
      </span>
    </div>
  );
};

export default ItemPrice;
