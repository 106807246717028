import React from "react";
import { LocationModel } from "../../../models/location.model";
import { Formik } from "formik";
import { selectLocationSchema } from "../../../validationSchemas/selectLocationSchema";
import { FormField } from "../../FormFields/FormField";
import { ISelectedLocation } from "../../../models/forms.model";
import SelectedLocationForPickup from "./SelectedLocationForPickup";


interface IFavoriteLocationsDropDown {
    selectedLocation: LocationModel,
    routeToItem,
    favoriteLocations,
    setSelectedLocation,
    isSubmitting,
    locationInfo
}


const FavoriteLocationsDropDown = (props: IFavoriteLocationsDropDown) => {
    const {
        selectedLocation,
        routeToItem,
        favoriteLocations,
        setSelectedLocation,
        isSubmitting,
        locationInfo
    } = props

    const selectedLocationOption = { value: locationInfo.selectedStore?.name, label: selectedLocation.name, id: selectedLocation.id }

    const initialFormState: ISelectedLocation = {
        selected_location: selectedLocation.id ? selectedLocationOption : null
    };

    const getSelectedLocation = (location) => {
        return favoriteLocations.find((loc) => loc.id === location?.id) ? location : null
    }

    return (
        <>
            <Formik
                initialValues={initialFormState}
                onSubmit={routeToItem}
                validationSchema={selectLocationSchema}
            >
                {({ values, errors, touched, handleSubmit, setFieldValue }) => (
                    <form
                        className="full_width_mob new_form_design w-75 my-0 mx-auto"
                        onSubmit={handleSubmit}
                    >
                        <FormField
                            isSearchable={true}
                            options={favoriteLocations}
                            labelText={"Favorite Locations"}
                            name="selected_location"
                            type={"select"}
                            placeholder={"Select Location"}
                            errors={errors}
                            touched={touched}
                            value={getSelectedLocation(values.selected_location)}
                            onChange={(location) => {
                                setFieldValue("selected_location", location);
                                setSelectedLocation(location);
                            }}
                            inputFieldClass={
                                "custom_select custom_multi_select mb-0 mt-2 mb-4  pb-md-0"
                            }
                        />
                        {
                            selectedLocation &&
                            <SelectedLocationForPickup selectedLocation={selectedLocation} />
                        }
                        <button
                            type="button"
                            className="btn btn-large py-2 lh-lg f-s16 w-100 mb-3"
                            disabled={isSubmitting}
                            onClick={(e) => {
                                e.preventDefault();
                                handleSubmit();
                            }}
                        >
                            Continue
                        </button>
                    </form>
                )}
            </Formik>
        </>
    )
}

export default FavoriteLocationsDropDown;