import React, {useState} from "react";
import { Col } from "react-bootstrap";
import {KIDS_COMBO, REACT_APP_CLOUD_FRONT, TRY_2_COMBO} from "../../../constants";
import styleClasses from "../../Header/Header.module.scss";
import {useHistory} from "react-router-dom";
import useToggleRedeemFromRewards from "../../../react-query-hooks/Cart/useToggleRedeemFromRewards";
import { useAppSelector } from "../../../redux/store/store";
import { useAuthState } from "../../../context/UserAuthentication";
import { useStoreLocation } from "../../../context/StoreLocation";
import { useQueryClient } from "react-query";
import RedeemRewardModal from "./RedeemRewardModal";
import {isIndexFound, isItemMappedWithReward} from "../../../helpers/cartHelperMethods";
import Button from "../../Buttons/Button";
import _clonedeep from 'lodash.clonedeep'
import useRedeemReward from "../../../hooks/useRedeemReward";

interface ISuggestedItemCard {
  rewardList: any;
  setMyRewards: (any) => void
  suggestedItem:any;
  isRewardList: boolean;
  closeModal:()=>void;
  addToOrder:any;
}

const SuggestedItemCard: React.FC<ISuggestedItemCard> = ({
  suggestedItem,
  isRewardList,
  closeModal,addToOrder,
  rewardList,setMyRewards,
}) => {
  const { name, price, full_calories, title,} = suggestedItem;
  const [showOrderModal, setOrderModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSuccessfullyAddedToCart, setIsSuccessfullyAddedToCart] = useState(false);
  const handleCloseOrderModal = () => {
    if (isSuccessfullyAddedToCart) {
      setIsSuccessfullyAddedToCart(false)
    }
    setOrderModal(false)};
  const history=useHistory()
  const queryClient = useQueryClient();
  const Cart = useAppSelector(state => state.cart);
  const { authInfo } = useAuthState();
  const { locationInfo } = useStoreLocation();
  const {mutate:toggleRedeemFromRewards}=useToggleRedeemFromRewards()
  let cart = Cart.items;
  const autoRedeem = Cart.auto_redeem_rewards;
  const itemsInCart = structuredClone(cart);
  const {redeemReward: redeemInCart} = useRedeemReward(rewardList, setMyRewards);
  const foundRewardIndex = () => {
    return itemsInCart.findIndex((item) => isItemMappedWithReward(item, suggestedItem))
  }
  
  const isItemInCart = () => {
    return autoRedeem ? foundRewardIndex() >= 0 : suggestedItem.is_active_in_cart;
  }
  const in_cart = isItemInCart()
  const handleCartDirectly = async() => {
    const structuredOrder = {
      item_id: suggestedItem?.item_id,
      display_price: suggestedItem.price,
      item_size: suggestedItem?.size?.toUpperCase(),
      name: suggestedItem?.name,
      modifiers: [],
      item_calories: suggestedItem?.item_calories,
      // free:true,
      reward_id: suggestedItem.id,
    };
    const added=await addToOrder(structuredOrder);
    if(added){
      setIsSuccessfullyAddedToCart(true)  
      queryClient.refetchQueries(
        ['myRewards', authInfo.userId],
        undefined,
        { throwOnError: true }
      );
    }
  };
  const handleRedirection=()=>{
    if(suggestedItem.cart_directly){
      handleCartDirectly()
    }
    else{
      if(suggestedItem.redirect_to_item && suggestedItem?.redirect_category_name?.toUpperCase() !== TRY_2_COMBO
          && suggestedItem?.redirect_category_name?.toUpperCase() !== KIDS_COMBO){
        closeModal();
       history.push({
         pathname: `/order/${suggestedItem.redirect_item_id}`,
         search: `location_id=${locationInfo.selectedStore.id}&category_id=${suggestedItem.redirect_category_id}`,
         state: { reward_size: suggestedItem?.redirect_item_size},
       });
     }else{
       closeModal();
       history.push({
         pathname: `/menu/${suggestedItem.redirect_category_name}`,
         state: {
           isReward: true,
           reward_size: suggestedItem?.redirect_item_size,
           search: `location_id=${locationInfo.selectedStore.id}&category_id=${suggestedItem.redirect_category_id}`,
         },
       });
     }
    }
    
  }
  const updateCartItemReward=async (foundIndex)=>{
    setLoading(true)
    redeemInCart({
          toggleData: toggleRedeemFromRewards,
          data: {
            id: cart[foundIndex].id,
            reward_redeemed: !suggestedItem?.is_active_in_cart,
            reward_id: !suggestedItem?.is_active_in_cart ? suggestedItem?.loyalty_id : null
          },
          iteratingIndex: foundIndex,
          item: cart[foundIndex],
          setIsTogglingReward: setLoading,
          rewardItem: suggestedItem
        }
    )
    setInCartIndex(-1)
    setOrderModal(false)
  }
  const [ inCartIndex, setInCartIndex ] = useState(-1)
  const redeemReward = async () => {
    const itemsInCart = _clonedeep(cart);
    let foundIndex = -1;
    if(suggestedItem?.is_active_in_cart){
      foundIndex = itemsInCart.findIndex((item) => isItemMappedWithReward(item, suggestedItem) && item.reward_redeemed);
    }
    else {
      foundIndex = itemsInCart.findIndex((item) => isIndexFound(item, suggestedItem) && !item?.reward_redeemed);
    }
    if (foundIndex >= 0 && suggestedItem.is_active_in_cart) {
      updateCartItemReward(foundIndex);
    } else {
      if(!itemsInCart[foundIndex]?.reward_redeemed)
      {
        setInCartIndex(foundIndex);
      }
      setOrderModal(true);
    }
  };

  return (
    <>
      <Col sm="4" xs="4" className={styleClasses.suggestion_column}>
        <div
          className={`${styleClasses.other_products_item} text-center ${
            in_cart && styleClasses.active
          }`}
        >
          <div className={styleClasses.cart_img_wrap + ' mb-3'}>
            <img
              // src={`${REACT_APP_CLOUD_FRONT}${image}`}
              src={
                suggestedItem.image_url
                  ? `${REACT_APP_CLOUD_FRONT}${suggestedItem?.image_url}`
                  : `${REACT_APP_CLOUD_FRONT}/images/Menu/${suggestedItem?.title?.toLocaleLowerCase()}.webp`
              }
              className="img-fluid"
              alt={name}
            />
          </div>
          <h5
            className={`${styleClasses.card_inner_lis_des} f-s14 f-sm-s11 lh-18 font-rale f-w5 `}
          >
            {title ? title : name}
          </h5>
          <Button
            type="button"
            className={
              styleClasses.pro_item_btn +
              `btn-large item-select-btn btn h-auto f-s12 mb-2 font-rale text-capitalize mw-auto w-100 py-1 px-2 lh-lg mx-md-2 ${
                !in_cart && 'outline'
              }`
            }
            onClick={() => {
              redeemReward()
            }}
            disabled={autoRedeem && in_cart}
          >
            {in_cart ? 'Redeemed' : 'Redeem'}
          </Button>
          {!isRewardList && (
            <h6 className="f-s12 ls-normal font-Visby-cf d-flex justify-content-between mb-0">
              <span>{`${parseInt(full_calories)} cal`}</span>{' '}
              {`\$${price ? price.toFixed(2) : 0}`}
            </h6>
          )}
        </div>
      </Col>
      <RedeemRewardModal
        suggestedItem={suggestedItem}
        showOrderModal={showOrderModal}
        handleCloseOrderModal={handleCloseOrderModal}
        isSuccessfullyAddedToCart={isSuccessfullyAddedToCart}
        addToOrder={addToOrder}
        handleRedirection={handleRedirection}
        inCartIndex={inCartIndex}
        redeemReward={updateCartItemReward}
        loading={loading}
        />
    </>
  );
};

export default SuggestedItemCard;
