import { useMutation } from "react-query";
import { customerBusinessService } from "../services";
interface vehicleInfoProps {
  make:string,
  model:string,
  color:string,
}
const updateVehicleDetails = async (vehicleInfo: vehicleInfoProps, vehicleId: number|string) => {
  const response = await customerBusinessService.updateVehicleDetails(vehicleId,vehicleInfo)
  return response.data.data;
};

export default function useUpdateVehicleDetails() {
  return useMutation((data: { vehicleInfo: vehicleInfoProps; vehicleId: number|string }) =>
    updateVehicleDetails(data.vehicleInfo, data.vehicleId)
  );
}
