import { useQuery } from "react-query";
import { customerBusinessService } from "../services";
import {GUEST_USER} from "../constants";

const getProfileStatus = async (customerId: number) => {
    const response = await customerBusinessService.getUserProfileStatus(customerId)
    return response.data.data;
};

export default function useGetProfileStatus(customerId: number, userType: string) {
    return useQuery("userStatus", () => getProfileStatus(customerId), {
        enabled: userType !== GUEST_USER
    });
}