import React from 'react';
import styles from './SkeletonLoaderForHeading.module.scss';

const SkeletonLoaderForHeadings = () => {
  return (
    <div className={`${styles.skeleton} ${styles.h4_skeleton}`}></div>
  );
};

export default SkeletonLoaderForHeadings;
