import React from "react";
import styleClasses from '../../../containers/account/accounts.module.scss';

const NoDeliveryAdressBanner = () => {
  return (
    <div>
      <div className={`${styleClasses.profile_content_wrap} text-center `}>
        <div className={`${styleClasses.inn_content_wrap} mx-auto`}>
          <img
            src={require("../../../../src/images/location-circle-icon-blue.svg")}
            className="img-fluid"
            alt="location circle icon"
          />
          <h1 className="h-24  font-Cls medium_text">
            You have no delivery
            <br /> address..Yet!
          </h1>
          <p className="f-sm-s14 mx-auto">
            Add a delivery address to your account for a<br />
            faster checkout experience!
          </p>
          <hr className="custom_hr_sty mb-4 mt-5  d-md-none" />
        </div>
      </div>
    </div>
  );
};

export default NoDeliveryAdressBanner;


