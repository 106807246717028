import { useMutation } from "react-query";
import { ItemFields } from "../../models/Menu.model";
import { orderMicroService } from "../../services";

const removeFavoriteOrder = async (item) => {
  //TODO: Replace customer id with an actual current login customer id
  const data = {
    is_favorite: 0,
    favorite_order_name: "",

  };
  const response = await orderMicroService.removedOrderItemToFavorites(item.id,data)
  return response.data.data;
};

export default function useAddFavorites() {
  return useMutation((item: ItemFields) => removeFavoriteOrder(item));
}
