import React, { useState } from "react";
import { customerBusinessService } from "../../../services";
import { logout, useAuthState } from "../../../context/UserAuthentication";
import { useDispatch } from "react-redux";
import { useAppDispatch } from "../../../redux/store/store";
import {
    resetRedeemedItemContext,
    resetRedeemedOrderContex,
} from "../../../redux/slices/itemSlice";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router";
import styleClasses from "../../../containers/account/accounts.module.scss";
import ConfirmationModal from "../../ConfirmatioModal/Confirmation";

const DeleteAccount: React.FunctionComponent = () => {
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const cartDispatch = useDispatch();
    const redeemedItemDispatch = useAppDispatch();
    const queryClient = useQueryClient();
    let history = useHistory();
    const { dispatch, authInfo } = useAuthState();
    const handleLogout = async () => {
        try {
            await customerBusinessService.deleteUser(authInfo.userId);
            redeemedItemDispatch(resetRedeemedItemContext());
            redeemedItemDispatch(resetRedeemedOrderContex());
            logout(dispatch, cartDispatch);
            queryClient.invalidateQueries();
            history.push("/");
        } catch (error) {
            console.log(error, "error");
        }
    };
    return (
        <>
            <h1
                className={`${styleClasses.delete_account_wrapper} h-24  font-Cls medium_text`}
            >
                Caution !<br />
                Are you sure you want to Delete your Account?
                <div className="d-flex justify-content-center mt-4">
                    <button
                        type="button"
                        className="btn btn-large"
                        onClick={()=>setShowConfirmationModal(true)}
                    >
                        Delete{" "}
                    </button>
                </div>
            </h1>
            <ConfirmationModal
                show={showConfirmationModal}
                title="Delete Account"
                content="Are you sure you want to delete your account?"
                btnText={"Delete Account"}
                handleClick={handleLogout}
                handleClose={() => {
                    setShowConfirmationModal(false);
                }}
            />
        </>
    );
};

export default DeleteAccount;
