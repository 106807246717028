import React from 'react';
import { Col } from 'react-bootstrap';
import styles from './SkeltonLoader.module.scss';

const SkeltonLoader = () => {
    return (
        <Col sm="4" xs="4" className='px-1'>
            <div className={styles.skeltonContainer}>
                <div>
                    <div className={`${styles.skelton} ${styles.imageSection}`}>
                    </div>
                    <div className="px-2">
                        <h2 className={`${styles.skelton} ${styles.textSection}`}>
                        </h2>
                        <h2 className={`${styles.skelton} ${styles.textSection}`}>
                        </h2>
                        <p className={`${styles.skelton} ${styles.btnSection} mb-0`}>
                        </p>
                        <div className='d-flex justify-content-end mt-2'>
                            <span className={`${styles.skelton} ${styles.price}`}></span>
                        </div>
                    </div>
                </div>
            </div>
        </Col>
    )
}

export default SkeltonLoader