import React from "react";
import styleClasses from "../../../containers/account/accounts.module.scss";
import { useAuthState } from "../../../context/UserAuthentication";
import { VehicleInfoFormFields } from "../../../models/forms.model";
import useProfile from "../../../react-query-hooks/useProfile";
import VehicleInfo from "./vehicleInfo";

interface VehicleDetailsTabProps {
  eventKey: string;
  showVehicleInfoModal: (
    title?: string,
    id?: number,
    forOneTimeOnly?: boolean
  ) => void;
  vehicleInfoForOneTime: VehicleInfoFormFields[];
  removeVehicleInfoForOneTime?: (id: number) => void;
  data: any
}

const VehicleDetails: React.FunctionComponent<VehicleDetailsTabProps> = ({
  eventKey,
  showVehicleInfoModal,
  vehicleInfoForOneTime,
  removeVehicleInfoForOneTime,
  data,
}) => {

  return <>
      <div className={styleClasses.profile_inn_wrap}>
        <div className="mb-0 ">
          <h4 className="font-Cls f-sm-s22 f-s24 text-start h-24 medium_text mb-4">
            Vehicle Info{" "}
          </h4>
        </div>
        {data?.vehicles?.length == 0 ? (
          <>
            <div className={styleClasses.profile_content_wrap + " text-center"}>
              <div className={styleClasses.inn_content_wrap + " mx-auto"}>
                <img
                  src={require("../../../../src/images/Vehicle.svg")}
                  className="img-fluid"
                  alt="vehicle icon"
                />
                <h1 className="h-24  font-Cls medium_text">
                  You have no vehicle
                  <br /> information...yet!
                </h1>
                <p className="f-sm-s14 mx-auto">
                  Add a description of your vehicle to help our servers identify
                  you when using our curbside pick-up method.
                </p>
                <hr className="custom_hr_sty mb-4 mt-5  d-md-none" />
                <div className="d-flex justify-content-center mt-4">
                  <button
                    type="button"
                    className="btn btn-large"
                    onClick={() => showVehicleInfoModal("Add Vehicle Details")}
                    // disabled={data?.vehicles?.length === 3}
                  >
                    Add Vehicle Details
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <VehicleInfo
          vehicles={data?.vehicles}
            showModel={showVehicleInfoModal}
            vehicleInfoForOneTime={vehicleInfoForOneTime}
            removeVehicleInfoForOneTime={removeVehicleInfoForOneTime}
          />
        )}
      </div>
      <hr className="custom_hr_sty m-0 d-none d-md-block" />
    </>
};

export default VehicleDetails;
