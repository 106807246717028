import { SELECTED_STORE } from "../../constants";

let selectedStore = localStorage.getItem(SELECTED_STORE)
  ? JSON.parse(localStorage.getItem(SELECTED_STORE))
  : "";

export const initialState = {
  selectedStore: "" || selectedStore,
  error: "",
};

export const LocationReducer = (initialState, action) => {
  switch (action.type) {
    case "SET_LOCATION":
      return {
        ...initialState,
        selectedStore: action.payload,
        error: "",
      };
    case "LOCATION_ERROR":
      return {
        ...initialState,
        error: action.error,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
