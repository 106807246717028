import { useMutation } from "react-query";
import {customerBusinessService, paymentService} from "../services";

const removeGiftCard = async (cardId: string) => {
    const response = await customerBusinessService.removeGiftCard(cardId)
    return response.data.data;
};

export default function useRemoveGiftCard() {
    return useMutation((data: { cardId: string}) =>
        removeGiftCard(data.cardId)
    );
}
