import React, { useState } from "react";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import PasswordIcons, {HideIcon} from "./passwordIcons";

interface PasswordFieldProps {
  labelText?: string;
  placeholder?: string;
  name: string;
  onChange: ((e: React.ChangeEvent<any>) => void) & ((values: any) => void);
  errors?: { [field: string]: string };
  touched?: { [field: string]: boolean };
  parentDivClass?: string;
  inputFieldClass?: string;
}

const PasswordField: React.FC<PasswordFieldProps> = ({
  labelText,
  name,
  onChange,
  errors,
  touched,
  parentDivClass,
  inputFieldClass,
  placeholder,
}) => {
  const [isPasswordVisible, setPasswordVisible] = useState<boolean>(false);

  return (
    <div className={`form-group ${parentDivClass}`} data-testid="container">
      <label data-testid="password-label">{labelText}</label>
      <div
        className="position-relative"
        data-testid="password-field-container"
      >
        <input
          name={name}
          type={isPasswordVisible ? "text" : "password"}
          className={`form-control pe-5 ${inputFieldClass} ${errors[name] && touched[name] ? 'input_error' : ''}`}
          onChange={onChange}
          placeholder={placeholder}
          data-testid="password-field"
        />
          <span
            className="field-icon d-none d-md-inline-block"
            onClick={() => setPasswordVisible(!isPasswordVisible)}
            data-testid="icon-container"
          >
            {isPasswordVisible ?   <PasswordIcons/> : <HideIcon />}
          </span>
          <span
            className="field-icon d-md-none"
            onClick={() => setPasswordVisible(!isPasswordVisible)}
            data-testid="icon-container"
          >
            {isPasswordVisible ?   <PasswordIcons/> : <HideIcon />}
          </span>
      </div>
      {errors && touched && errors[name] && touched[name] && (
        <span data-testid="error-span" className="clr-dark-red f-s14 font-rale mt-1 d-inline-block">
          {errors[name]}
        </span>
      )}
    </div>
  );
};

export { PasswordField };
