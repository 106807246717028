import { useMutation } from "react-query";
// import { IUpdateOrderQuantityPayload } from "../../models/order.model";
import { orderMicroService } from "../../services";

const toggleRedeemFromRewards = async (payload) => {
    const { id,customer_id,reward_id,location_id, reward_redeemed} = payload;
    const response = await orderMicroService.toggleRedeemFromRewards(id, {customer_id,reward_id,location_id, reward_redeemed});
    return response.data.data;
};

export default function useToggleRedeemFromRewards() {
    return useMutation((payload:any) => toggleRedeemFromRewards(payload));
}
