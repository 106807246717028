import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import axios from "../../axios/axios";

import * as CONSTANT from "../../constants";
import Spinner from "../../components/Spinner/Spinner";
import { Menu } from "../../models/Menu.model";
import { Container, Row, Col } from "react-bootstrap";
import ItemGrid from "../../components/ItemGrid/ItemGrid";
import styleClasses from "./menu.module.scss";
import { useLocation, useParams } from "react-router-dom";
import { useAuthState } from "../../context/UserAuthentication";
import useGetRewards from "../../react-query-hooks/useGetRewards";
import { useStoreLocation } from "../../context/StoreLocation";

interface RouteParams {
  id?: string;
}

const MenuDetailsContainer: React.FC<any> = ({ history }) => {
  // const [menus, setMenus] = useState<Menu[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [menu, setMenu] = useState<Menu>();
  const { id } = useParams<RouteParams>();
  const location = useLocation();
  const { locationInfo } = useStoreLocation();
  const { authInfo } = useAuthState();
  
  const { data: allRewards = [], isFetching: rewardFetching } = useGetRewards(authInfo.userId, locationInfo.selectedStore?.id, 1, 1, 1,authInfo?.type);
  let discountApplied = []
  if (!rewardFetching)
    discountApplied = allRewards.discountApplied

  useEffect(() => {
    getMenu();
  }, [history, id]);

  const getMenu = () => {
    setLoading(true);
    const individualMenu = axios.get(CONSTANT.MENU_CATEGORIES + "individual");
    const cateringMenu = axios.get(CONSTANT.MENU_CATEGORIES + "catering");

    const removeDuplicate = (array: any[]) => {
      const uniqueAddresses = Array.from(new Set(array.map((a) => a.id))).map(
        (id) => {
          return array.find((a) => a.id === id);
        }
      );
      return uniqueAddresses;
    };

    Promise.all([individualMenu, cateringMenu])
      .then((response) => {
        let addresses = [
          ...response[0].data.data.data,
          ...response[1].data.data.data,
        ];

        let individualCateringMenu = removeDuplicate([
          ...response[0].data.data.data,
          ...response[1].data.data.data,
        ]);
        // setMenus(individualCateringMenu);

        findMenu(individualCateringMenu);
        setLoading(false);
      })
      .catch((error) => {
        console.log(`Error from promises ${error}`);
      });
  };
  const findMenu = (menusArray: Menu[]) => {
    const item = menusArray.find((element) => element.id.toString() === id);
    setMenu(item);
  };

  return isLoading ? (
    <Spinner lightBg={true}></Spinner>
  ) : (
    <div className={styleClasses.title_wrapper}>
      {menu ? (
        <React.Fragment>
          <div className={styleClasses.title_container_wrap}>
            <Container className={styleClasses.sticky_container}>
              <Row>
                <Col
                  md={12}
                  className={styleClasses.description_container + " pt-1 pb-2"}
                >
                  <h2 className="text-uppercase mb-0">{menu.name}</h2>
                  <p className="font-Vcf text-uppercase small_text">
                    {location.pathname.substr(
                      0,
                      location.pathname.lastIndexOf("/")
                    ) === CONSTANT.ROUTE_CATERING_MENU_DETAILS
                      ? menu.catering_offers
                      : menu.offers}
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
          <ItemGrid items={menu.items} discountApplied={discountApplied} />
        </React.Fragment>
      ) : (
        ""
      )}
    </div>
  );
};

export default withRouter(MenuDetailsContainer);
