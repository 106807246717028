import { useQuery } from "react-query";
import { StoredPaymentMethod } from "../models/payment.model";
import {customerBusinessService, paymentService} from "../services";
import {AUTH_USER, GUEST_USER} from "../constants";

const getGiftCards = async () => {
    const response = await customerBusinessService.getCustomerGiftCards()
    return response?.data?.data;
};

export default function useGetGiftCards(customer_id: string, userType: string = AUTH_USER) {

    return useQuery<any[]>(
        ["giftCards", customer_id],
        () => getGiftCards(), {
            enabled: !!customer_id && userType !== GUEST_USER
        }
    );
}
