import React, { useEffect, useState } from "react";
import { substitutedAddedModifiers } from "../../../helpers/helperMethods";
import ComboItemModifiers from "./ComboItemModifiers";
import styleClasses from "../CartItems/CartItems.module.scss"
import { toFixedNumber } from "../../../priceCalculation/helper";

interface ICartComboModifiers {
  modifiers: any[];
}

const CartComboModifiers: React.FC<ICartComboModifiers> = ({ modifiers }) => {
  const [showMore, setShowMore] = useState(false);

  const handleShowMoreOrLess = () => {
    if (showMore) setShowMore(false);
    else setShowMore(true);
  };

  const extraPrice = (modifier) => {
    if(modifier?.price > 0){
      return <span className={`clr-red-dark f-s12 font-Vcf text-end `}>+${toFixedNumber(modifier.price)}</span>
    }
    return null;
  }

  let hasMore = false;
  return (
    <ul className="list-inline f-s14 font-rale clr-dark-grey text-start mt-md-2 mt-0 ps-0 position-relative">
      {modifiers.map((modifier, i: number) => {
        if( !hasMore && modifier?.modifiers?.length > 1 ) hasMore = true;
        return modifier.is_item ? (
          <li className="f-s14" key={i}>
            {modifier.modifier_name}{" "}
            {extraPrice(modifier)}
            {/* <p className={`clr-text-grey f-s14 font-Visby-cf d-flex flex-column text-center ${styleClasses.item_price_cart}`}> Extra <span className={`clr-red-dark f-s14 font-Vcf`}>$12.00</span></p> */}
            <ComboItemModifiers
              modifiers={substitutedAddedModifiers(modifier)}
              paragraphClassName={"f-s14 text-start mb-0"}
              showMore={showMore}
            />
          </li>
        ) : null;
      })}
      {hasMore && (
        <span
          className="cursor-pointer clr-sb-dark"
          onClick={handleShowMoreOrLess}
        >
          {!showMore ? "see more" : "see less"}
        </span>
      )}
    </ul>
  );
};

export default CartComboModifiers;
