import { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios'

export const requestHandler = (request: AxiosRequestConfig) => {
    let url = request.baseURL+request.url;
    if(request.method === 'get'){
        request.url = '/server.php?url='+url
    }
    else{
        request.url = '/server.php?url='+url;
        if(typeof request.data === 'undefined'){
            request.data = {url : url}
        }
        else{
            request.data["url"] = url;
        }
    }
    request.baseURL = process.env.REACT_APP_DOMAIN;

    return request;
}

export const successResponseHandler = (response: AxiosResponse) => {
    return {
        ...response,
        data: response.data,
    }
}

export const errorResponseHandler = (error: AxiosError) => {
    // window.location.assign(window.location.origin + '/error')
    return Promise.reject(error);
}
