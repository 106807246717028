
import { useQuery } from "react-query";
import axios from "axios";
import { updateTrackOrder } from "../redux/slices/checkoutSlice";
import { useAppDispatch } from "../redux/store/store";
import { GUEST_USER, ORDER_MICRO_SERIVCE } from "../constants";

interface IQueryParams {
  userId: number;
  userType: string;
}

const getCustomerPlacedOrders = async ({ userId }: IQueryParams, dispatch: any) => {
  const response = await axios.get(
    `${ORDER_MICRO_SERIVCE}/orders/track-here/${userId}?page=1&size=25`
  );
  dispatch(updateTrackOrder(response?.data?.data?.data?.length ? true : false));
  return response?.data?.data;
};

export default function useGetCustomerPlacedOrders({ userId, userType }: IQueryParams) {
  const dispatch = useAppDispatch();
  return (
    useQuery(["placedOrders", userId], () =>
      getCustomerPlacedOrders({ userId, userType }, dispatch),
      {
        enabled: userType !== GUEST_USER,
      }
    ) 
  )
}
