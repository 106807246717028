import { Formik } from "formik";
import React, { useState } from "react";
import { FormField } from "../../FormFields/FormField";
import styleClasses from "../../UserSelections/UserSelections.module.scss";
import { barCodeField } from "../../../models/forms.model";
import { useQueryClient } from "react-query";
import { Toast_Func } from "../../../helpers/toast.helper";
import { useAuthState } from "../../../context/UserAuthentication";
import useBarCodeRequest from "../../../react-query-hooks/useBarCode";
import { ROUTE_TALK_TO_US } from "../../../constants";
import { Link } from "react-router-dom";
import { barcodeSchema } from "../../../validationSchemas/barcodeSchema";
import { useAppDispatch, useAppSelector } from "../../../redux/store/store";
import { updateLoyalityPoints, updateTempLoyalityPoints } from "../../../redux/slices/itemSlice"
import { useStoreLocation } from "../../../context/StoreLocation";

interface uploadBarcodeProps {
  closeModalHandler?: any;
}
const UploadRewardsBarcode: React.FunctionComponent<uploadBarcodeProps> = ({
  closeModalHandler,
}) => {
  let barCodeLenght = 13;
  let queryClient = useQueryClient();
  let { authInfo } = useAuthState();
  // let { data } = useProfile(authInfo.userId);
  const { locationInfo } = useStoreLocation();
  let { mutateAsync: barCodeRequest } = useBarCodeRequest();
  const [errorState, setErrorState] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const redeemedItemDispatch = useAppDispatch()
  const {loyalityPoints,tempLoyalityPoints}=useAppSelector(state=>state.Items.item)

  const initialFormState: barCodeField = {
    barCode: "",
  };
  const handleFormSubmission = async (
    values: barCodeField,
    { setSubmitting }: any
  ) => {
    let modifiedValues = {
      ...values,
    };
    let mutate = barCodeRequest;
    return mutate(
      {
        bar_code: modifiedValues,
        access_token: authInfo.access_token,
      },
      {
        onSuccess: async(data, variables, context) => {
          redeemedItemDispatch(updateLoyalityPoints(loyalityPoints+data?.points_earned))
          redeemedItemDispatch(updateTempLoyalityPoints(tempLoyalityPoints + data?.points_earned))
          queryClient.invalidateQueries("BarCode");
          setSubmitting(false);
          Toast_Func({
            status: true,
            message: "barcode successfully added",
          });
          // const options={token: authInfo.authentication_token,
          //   location_id: locationInfo.selectedStore?.id,
          //   customer_id: authInfo.userId,}
          await queryClient.refetchQueries("rewardCatalog");
          closeModalHandler()
        },
        onError: (error:any, variables, context) => {
          setSubmitting(false);
          Toast_Func({ status: false, message: 'Something went wrong. Please try again' });
          setNotFound(true)
          setErrorState(true)
        },
      }
    );
  };

  const isBarCodeLengthExceed = (values:any) => {
    return values.barCode.toString().length >= barCodeLenght;
  }

  return (
    <div
      className={`${styleClasses.upload_reward_content} text-center mx-auto d-flex flex-column flex-fill h-100`}
    >
      <h6 className="ls-normal font-Cls mb-md-2">
        {notFound ? "Uh Oh..." : "Upload by Receipt"}
      </h6>
      <p className={`${notFound ? "mb-3" : "mb-1"} f-s16`}>
        {notFound
          ? "We aren’t finding that reward!"
          : "Enter your 13 digit code."}
      </p>
      <Formik initialValues={initialFormState} validationSchema={barcodeSchema()} onSubmit={handleFormSubmission}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          submitForm,
          setFieldValue,
        }) => (
          <form
            className={`${
              errorState ? "error-state" : isBarCodeLengthExceed(values) && 'valid-state'
            } new_form_design mb-3 text-start d-flex flex-column flex-fill`}
            onSubmit={handleSubmit}
          >
            <FormField
              name="barCode"
              type="text"
              onChange={handleChange}
              errors={errors}
              touched={touched}
              value={values.barCode}
              inputFieldClass="barCode_reader f-s24 shadow-none my-2 color-medium-gray fw-bold"
              maxLength={barCodeLenght}
              numbersWithRestrictedLimit={true}
            />
            <p
              className={`${
                notFound ? "mb-1" : "mb-5 lh-normal"
              } f-s14 d-md-none text-center`}
            >
              {notFound
                ? "Tried mulitple times?"
                : "Your reward will appear in “My Rewards”. This code will no longer be available after being added."}
            </p>
            {notFound &&
              <Link
                to={ROUTE_TALK_TO_US}
                onClick={closeModalHandler}
                className="btn btn-custom clr-dark-blue f-s16 font-Vcf p-0 mb-5 d-md-none"
              >
                Contact Us
              </Link>
            }
            <button
              type="button"
              className={`${
                notFound ? "mb-3" : ""
              } ${isBarCodeLengthExceed(values) ? '' : 'dark-grey'} btn btn-large text-uppercase mw-auto w-100 lh-lg`}
              onClick={submitForm}
              disabled={isSubmitting || values.barCode.length<=0}
            >
              {notFound ? "Try Again" : "UPLOAD REWARD"}
            </button>
          </form>
        )}
      </Formik>
      <p className={`${notFound ? "mb-1" : "mb-3"} f-s14 d-none d-md-block`}>
        {notFound
          ? "Tried mulitple times?"
          : "Enter the barcode number found at the bottom of your receipt."}
      </p>
      <div className="d-none d-md-block">
        {notFound ? (
          <Link
            to={ROUTE_TALK_TO_US}
            onClick={closeModalHandler}
            className="btn btn-custom f-s16 clr-dark-blue font-Vcf p-0"
          >
            Contact Us
          </Link>
        ) : (
          <button
            type="button"
            className="btn btn-custom f-s16 font-Vcf p-0 text-decoration-underline"
            onClick={closeModalHandler}
          >
            CANCEL
          </button>
        )}
      </div>
    </div>
  );
};

export default UploadRewardsBarcode;
