import React from "react";
import { useHistory } from "react-router-dom";
import styleClasses from "./AccountStatus.module.scss";
import * as CONSTANT from "../../constants";
import { UserCircleIcon } from "../../images/svgIcons/svg";


const SignUpButton = () => {
  const history = useHistory();
  const goToRoute = (route: string) => history.push(route);

  const routeToIndividualSignIn = () => {
    goToRoute(CONSTANT.ROUTE_LOGIN);
  };

  const routeToIndividualSignUp = () => {
    goToRoute(CONSTANT.ROUTE_CREATE_ACCOUNT);
  };

  return (
    <div className={`d-none d-md-block ${styleClasses.signInWrapper}`}>
      <div className="d-flex align-items-center flex-nowrap">
       <UserCircleIcon/>
        <div className="d-flex flex-column align-items-start">
          <a onClick={routeToIndividualSignIn} className="clr-dark-grey f-w6 f-s16 text-decoration-none lh-15"> SIGN-IN </a>
          <a onClick={routeToIndividualSignUp} className="clr-dark-grey f-w6 f-s10 text-decoration-none"> OR CREATE AN ACCOUNT </a>{" "}
        </div>
      </div>
    </div>
  );
};

export default SignUpButton;
