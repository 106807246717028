import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { login, useAuthState } from "../../../../context/UserAuthentication";
import { LoginFormFields } from "../../../../models/forms.model";
import { IAccountStatus } from "../../../../models/misc";
import { loginSchema } from "../../../../validationSchemas/loginSchema";
import { FormField } from "../../../FormFields/FormField";
import { PasswordField } from "../../../FormFields/PasswordField";
import styleClasses from "../../../../../src/containers/account/accounts.module.scss";
import Button from "../../../Buttons/Button";
import { ROUTE_CHECKOUT } from "../../../../constants";
import { useHistory } from "react-router";
import { setShowCart } from "../../../../redux/slices/cartSlice";
import { useAppDispatch } from "../../../../redux/store/store";

interface IEmailLogin {
  handleForgotPassword: (boolean) => void,
  closeModal: () => void
}
const EmailLogin : React.FC<IEmailLogin> = ({handleForgotPassword, closeModal }) => {
  const { authInfo, dispatch } = useAuthState();
  const history  = useHistory();
  const cartDispatch = useDispatch();
  const initialFormState: LoginFormFields = {
    email: "",
    password: "",
  };
  const handleFormSubmission = async (
    values: LoginFormFields,
    { setSubmitting }: any
  ) => {
    setSubmitting(true);
    await login(dispatch, values, cartDispatch, updateAccountStatusModal, true).finally(
      () => {
        setSubmitting(false);
        closeModal();
        history.push(ROUTE_CHECKOUT);
        cartDispatch(setShowCart(false));
      }
    );
  };
  const [accountStatusModal, setAccountStatusModal] = useState<IAccountStatus>({
    code: null,
    buttonText: null,
    isError: null,
    header: null,
    text: null,
    email: null,
    show: false,
  });
  const updateAccountStatusModal = (
    header: string,
    text: string,
    code: number,
    buttonText: string,
    email: string,
    isError: boolean
  ) => {
    setAccountStatusModal({
      code,
      buttonText,
      isError,
      header,
      text,
      email,
      show: true,
    });
  };
  return (
    <>
      <div
        className={`${styleClasses.account_content_wrapper} new_form_design pt-4 pb-0`}
      >
        <Formik
          initialValues={initialFormState}
          validationSchema={loginSchema}
          onSubmit={handleFormSubmission}
        >
          {({ errors, touched, handleChange, isSubmitting }) => (
            <Form>
              <FormField
                labelText={"EMAIL ADDRESS"}
                name="email"
                type="email"
                onChange={handleChange}
                errors={errors}
                touched={touched}
              />
              <PasswordField
                name="password"
                labelText={"PASSWORD"}
                onChange={handleChange}
                errors={errors}
                touched={touched}
              />
              <a
                onClick={handleForgotPassword}
                className="text-end clr-dark-grey font-Visby-cf f-s14 f-w8 d-block"
              >
                Forgot Password?
              </a>
              <div className="pt-4">
                <Button
                  type="submit"
                  id="sign-in-button"
                  className="d-block mb-3 w-100"
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  // onClick={() => onContinueHandle()}
                >
                  Log in
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default EmailLogin;
