import React from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

import { ROUTE_LOCATIONS } from "../../constants";
import { useStoreLocation } from "../../context/StoreLocation";

interface ModalProps {
  showModal: boolean;
  closeModal: () => void;
}

export const LocationInfoModal: React.FunctionComponent<ModalProps> = ({
  closeModal,
  showModal,
}) => {
  const { locationInfo } = useStoreLocation();
  return (
    <Modal
      show={showModal}
      centered
      className="theme-modal-style app_container_wrap"
    >
      <Modal.Header className="px-5 flex-column">
        <button
          type="button"
          className="btn modal-close-button pe-0"
          onClick={closeModal}
        />
        <h6 className="f-s11 clr-dark-blue mb-0 lh-normal pt-5 text-uppercase">
          YOU’RE ORDERING FROM
        </h6>
        <h5 className="h-48 text-start w-100 font-Cls lh-normal">
          {locationInfo.selectedStore.name}
        </h5>
      </Modal.Header>
      <Modal.Body className="pt-0 px-5">
        <h6 className="f-s11 clr-dark-blue mb-0 lh-normal">ADDRESS</h6>
        <p className="f-s18 f-sm-s16 text_underline">
          {locationInfo.selectedStore.address}
        </p>

        <h6 className="f-s11 clr-dark-blue mb-0 lh-normal">TODAY’S HOURS</h6>
        {/*TODO: Make opening hours dynamic when provided in API response.*/}
        <p className="f-s18 f-sm-s16 mb-4">Open 10:30am to 10pm</p>
        <div className="text-center mb-3">
          <Link
            to={ROUTE_LOCATIONS}
            type="button"
            className="btn btn-large"
            onClick={closeModal}
          >
            CHANGE LOCATION
          </Link>
        </div>
      </Modal.Body>
    </Modal>
  );
};
