import React from "react";
import { Modal } from "react-bootstrap";
import { REACT_APP_CLOUD_FRONT } from "../../../constants";
import { capitalizeFirstLetter } from "../../../helpers/helperMethods";
import Button from "../../Buttons/Button";
import styleClasses from "../../UserSelections/UserSelections.module.scss";

interface IMyRewardsModalProps {
  showOrderModal: boolean;
  loading: boolean;
  handleCloseOrderModal: () => void;
  suggestedItem: any;
  isSuccessfullyAddedToCart: Boolean;
  addToOrder: any;
  handleRedirection: () => void;
  redeemReward: (index: number) => void;
  inCartIndex: number;
}

const RedeemRewardModal: React.FunctionComponent<IMyRewardsModalProps> = ({
  showOrderModal,
  handleCloseOrderModal,
  suggestedItem,
  isSuccessfullyAddedToCart,
  handleRedirection,
  inCartIndex,
  redeemReward,
  loading,
}) => {
  return (
    <Modal
      show={showOrderModal}
      centered
      className="theme-modal-style app_container_wrap sm-modal border-radius-15 "
    >
      <Modal.Header className="x-4">
        <button
          type="button"
          className="btn modal-close-button pe-0"
          onClick={handleCloseOrderModal}
        >
          Close
        </button>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <h3 className="f-s22 text-center font-Cls mb-0">
          {inCartIndex === -1 ? "Redeem a Reward" : "Available Reward"}
        </h3>
        <div className={styleClasses.reward_order_image + " "}>
          <img
            src={
              suggestedItem.image_url
                ? `${REACT_APP_CLOUD_FRONT}${suggestedItem.image_url}`
                : `${REACT_APP_CLOUD_FRONT}/images/Menu/${suggestedItem?.name?.toLocaleLowerCase()}.webp`
            }
            className="img-fluid"
            alt="radeem reward"
          />
        </div>
        {isSuccessfullyAddedToCart ? (
          <>
            <h5 className="clr-dark-blue p-18 f-sm-s16 lh-normal ls-normal text-center">
              SUCCESS!
            </h5>
            <p className="clr-dark-blue f-s14 ls-normal lh-normal mb-4 text-center">
              {`Your ${suggestedItem.name} has been successfully added to your Rewards.`}
            </p>
          </>
        ) : inCartIndex === -1 ? (
          <>
            <div className="d-none d-md-block">
              <p className="p-18 font-rale lh-normal ls-normal text-center mb-0">{`Oops! You don’t have a ${
                suggestedItem?.title
                  ? suggestedItem?.title
                  : suggestedItem.name
              }  in your order?
               Would you like to add one?`}</p>
              <h5 className="f-s16 font-rale lh-normal ls-normal mb-3 text-center">
                {/* <span className="clr-dark-blue">{suggestedItem.points}</span> {`${suggestedItem.points <= 1 ? 'point ' : 'points '}`}
              | <span className=" font-Visby-cf">{getExpiryDate()}</span> */}
              </h5>
            </div>
            <div className="d-flex justify-content-center">
              <button
                // disabled={suggestedItem.is_active_in_cart}
                type="button"
                className="btn btn-large py-2 my-3 f-s16  text-uppercase"
                onClick={() => handleRedirection()}
              >
                {"Add To order"}
              </button>
            </div>
            <div>
              <button
                type="button"
                onClick={handleCloseOrderModal}
                className="btn btn-custom p-0 f-w6 p-16 color-sky-blue-light text-center w-100 mb-4 font-Vcf"
              >
                No Thanks
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="d-none d-md-block">
              <p className="p-18 font-rale lh-normal ls-normal text-center mb-0">
                Would you like to redeem this reward?
              </p>
              <h5 className="f-s16 font-rale lh-normal ls-normal mb-3 text-center"></h5>
            </div>
            <div className="d-flex justify-content-center">
              <Button
                disabled={suggestedItem.in_cart || loading}
                loading={loading}
                type="button"
                className="btn btn-large py-2 my-3 f-s16  text-uppercase"
                onClick={() => redeemReward(inCartIndex)}
              >
                Redeem Reward
              </Button>
            </div>
            <div>
              <button
                type="button"
                onClick={handleCloseOrderModal}
                className="btn btn-custom p-0 f-w6 p-16 color-sky-blue-light text-center w-100 mb-4 font-Vcf"
              >
                No Thanks
              </button>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};
export default RedeemRewardModal;
