import { Formik } from "formik";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import styleClasses from "../../../../containers/account/accounts.module.scss";
import { personalInfoSchema } from "../../../../validationSchemas/personalInfoSchema";
import { FormField } from "../../../FormFields/FormField";
import { IGiftCard } from "../../../../models/giftCard.model";
interface IStoredGiftCardProps {
  record: IGiftCard;
  handleEdit: (record: IGiftCard) => void;
  defaultPayment: IGiftCard;
  paymentCount?: number;
  setConfirmDefaultPaymentMethodSelection: (boolean) => void;
  handleCardRemove: (id) => void;
}

const handleFormSubmission = async () => {
  console.log("🚀 ~ Submit");
  //TODO: API Integration
};

const StoredCardDesktopView: React.FunctionComponent<IStoredGiftCardProps> = ({
  record,
  handleEdit,
  defaultPayment,
  paymentCount,
  setConfirmDefaultPaymentMethodSelection,
  handleCardRemove,
}) => {
  const initialFormState = {
    name: record.name || "",
    number: record.gift_card_no || "",
  };
  return (
    <>
      <div className="d-none d-md-block">
        <div className="d-flex gap-3 pl-2">
          {paymentCount > 1 && (
            <div className="position-relative">
              <span className={styleClasses.select_radio_button}>
                <input
                  type="radio"
                  id={record.id?.toString()}
                  name={record.id + "desktopRadio"}
                  value={record.id}
                  checked={record.id == defaultPayment?.id}
                  onChange={() => {}}
                  onClick={() => {
                    setConfirmDefaultPaymentMethodSelection(true);
                  }}
                />
              </span>
            </div>
          )}
          <label htmlFor={` ${record.id?.toString()} giftCard`} className="w-100">
            <Formik
              initialValues={initialFormState}
              validationSchema={personalInfoSchema}
              onSubmit={handleFormSubmission}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                isSubmitting,
                submitForm,
              }) => (
                <form
                  className="new_form_design profile-info-read-only-field"
                  onSubmit={handleSubmit}
                >
                  <Row>
                    <Col md={5} sm={12}>
                      <FormField
                        labelText={"Gift Card name"}
                        name="name"
                        type="text"
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        value={values.name}
                        readonly
                      />
                    </Col>

                    <Col md={5} sm={12}>
                      <FormField
                        labelText={"Card number"}
                        name="number"
                        type="text"
                        onChange={() => {}}
                        errors={errors}
                        touched={touched}
                        value={values.number}
                        readonly
                      />
                    </Col>
                    <Col
                      md={2}
                      sm={12}
                      className="d-flex flex-column align-items-start justify-content-center"
                    >
                      {!record.is_shared && !record.is_cz_dollar_gift_card ? (
                        <div
                          className={`${styleClasses.edit_remove_btn} d-flex flex-column justify-content-center`}
                        >
                          <button
                            onClick={() => handleCardRemove(record.id)}
                            className="btn btn-custom f-s14 font-Visby-cf color-sky-blue-light ms-3 p-0"
                          >
                            <img
                              src={require("../../../../../src/images//Remove.svg")}
                              alt="remove icon"
                            />{" "}
                            Remove
                          </button>
                          <button
                            onClick={() => handleEdit(record)}
                            className="btn btn-custom f-s14 font-Visby-cf color-sky-blue-light ms-3 p-0 mt-2"
                          >
                            <img
                              src={require("../../../../../src/images/Edit.svg")}
                            />{" "}
                            Edit
                          </button>
                        </div>
                      ) : null}
                    </Col>
                  </Row>
                </form>
              )}
            </Formik>
          </label>
        </div>
      </div>
    </>
  );
};

export default StoredCardDesktopView;
