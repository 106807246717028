import React, { useState } from "react";
import { setLocation, useStoreLocation } from "../../../context/StoreLocation";
import { IItemPathToRoute } from "../../../models/item.model";
import { useAuthState } from "../../../context/UserAuthentication";
import { useAppDispatch } from "../../../redux/store/store";
import {
  setShowCart,
  updateIsOrderTypeSelected,
  updateOrderDetails,
  updateOrderType
} from "../../../redux/slices/cartSlice";
import { GUEST_USER, ROUTE_LOCATIONS, ROUTE_MENU, ROUTE_ORDER } from "../../../constants";
import { useQueryClient } from "react-query";
import { useHistory, useLocation } from "react-router";
import ConfirmLocationChangeModal from "./ConfirmLocationChangeModal";
import * as CONSTANT from "../../../constants";
import { Spinner } from "react-bootstrap";
import useGetFavoriteLocations from "../../../react-query-hooks/useGetNearbyLocations";
import SelectedLocationForPickup from "./SelectedLocationForPickup";
import FavoriteLocationsDropDown from "./FavoriteLocationsDropDown";

interface IOrderPickupTab {
  itemPath: IItemPathToRoute,
  handleOrderTypeModal: () => void,
  showItemPickupDateTime?: boolean,
  handleShowPickupMethodModal?: (boolean) => void,
  saveSelectedOrderType?: string,
  handleSavedOrderType?: () => void,
  isItMobile: boolean,
  selectedOrderType?: string,
}
function OrderPickupTab(props: IOrderPickupTab) {
  const {
    itemPath,
    handleOrderTypeModal,
    showItemPickupDateTime,
    handleShowPickupMethodModal,
    saveSelectedOrderType,
    selectedOrderType
  } = props
  const location = useLocation();
  const { locationInfo, dispatch: locationDispatch } = useStoreLocation();
  const queryClient = useQueryClient();
  const location_id = locationInfo.selectedStore?.id;
  const [selectedLocation, setSelectedLocation] = useState(locationInfo.selectedStore);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { authInfo } = useAuthState();
  const previousOrderType = selectedOrderType;

  const { data, isFetching: isFetchingFavoriteLocations } =
    useGetFavoriteLocations(authInfo.userId, authInfo.type === GUEST_USER);

  const favoriteLocations = data?.map((market) => ({
    value: market.name,
    label: market.name,
    id: market.id,
    ...market,
  }));

  const history = useHistory();
  const orderTypeDispatch = useAppDispatch();

  const handleLocationSearch = () => {
    history.push({
      pathname: ROUTE_LOCATIONS,
      state: { itemPath: itemPath }
    })
    orderTypeDispatch(setShowCart(false))
    handleOrderTypeModal()
  }

  const handleContinueButton = () => {
    if (!selectedLocation) {
      history.push({
        pathname: ROUTE_LOCATIONS,
        state: { itemPath: itemPath }
      })
      orderTypeDispatch(setShowCart(false));
    }
    else {
      const shouldShowConfirmation = handleConfirmationModal();
      if ( shouldShowConfirmation ) {
        setShowConfirmationModal(true);
        return;
      }
      orderTypeDispatch(updateIsOrderTypeSelected(true));
      orderTypeDispatch(updateOrderType(CONSTANT.PICK_UP_ORDER_TYPE));
      orderTypeDispatch(updateOrderDetails(null));
    }
    handleOrderTypeModal();
  }

  const handleSaveOrderType = (orderType: string) => {
    orderTypeDispatch(updateOrderType(orderType));
  };

  const handleConfirmationModal = () => {
    return (selectedLocation.id !== location_id || previousOrderType !== saveSelectedOrderType)
      && location.pathname.includes(CONSTANT.ROUTE_ORDER)     
  }

  const routeToItem = () => {
    setIsSubmitting(true);
    //this if condition checks  user is changing location while being on item customization page.
    const shouldShowConfirmation = handleConfirmationModal();
    if ( shouldShowConfirmation) {
      setShowConfirmationModal(true);
    } else {
      confirmLocationChange();
    }
  }

  const confirmLocationChange = () => {
      orderTypeDispatch(updateIsOrderTypeSelected(true));
      setLocation(locationDispatch, selectedLocation);
      queryClient.refetchQueries(["get-cart", selectedLocation.id]);
      orderTypeDispatch(updateOrderDetails(null));
      handleOrderTypeModal();
      if (saveSelectedOrderType) { handleSaveOrderType(saveSelectedOrderType) }
      if (showItemPickupDateTime) { handleShowPickupMethodModal(true) }
      if (showConfirmationModal) {
        setShowConfirmationModal(false);
        history.push(ROUTE_MENU);
      } else if (itemPath) {
        history.push(itemPath)
      }
  }

  const onCloseConfirmationMoal = () => {
    setShowConfirmationModal(false);
    setIsSubmitting(false)
  }

  if (isFetchingFavoriteLocations)
    return (
      <div className="position-relative mt-5 py-5">
        <Spinner
          animation="border"
          className="position-absolute bottom-50 end-50"
        />
      </div>
    );

  return (
    <div className="mt-5">
      {
        favoriteLocations?.length ?
          <FavoriteLocationsDropDown
            selectedLocation={selectedLocation}
            routeToItem={routeToItem}
            favoriteLocations={favoriteLocations}
            setSelectedLocation={setSelectedLocation}
            isSubmitting={isSubmitting}
            locationInfo={locationInfo}
          />
          :
          <div className="mx-md-5 px-2">
            {
              selectedLocation &&
              <SelectedLocationForPickup selectedLocation={selectedLocation} />
            }
            <button
              type="button"
              className="btn btn-large py-2 lh-lg f-s16 w-100 mb-3"
              onClick={handleContinueButton}
            >
              Continue
            </button>
          </div>
      }
      <div className="w-75 mx-auto">
        <button
          type="button"
          className={`btn btn-custom p-0 f-w6 p-16 color-sky-blue-light text-center w-100 mb-5 font-Vcf`}
          onClick={handleLocationSearch}
        >
          {selectedLocation ? "Change Location" : "Search Locations"}
        </button>
      </div>
      <ConfirmLocationChangeModal
        showModal={showConfirmationModal}
        handleClose={() => onCloseConfirmationMoal()}
        confirmChange={confirmLocationChange}
        loading={false}
      />
    </div>
  );
}
export default OrderPickupTab;
