import React from 'react';
import { Col } from 'react-bootstrap';
import {
  REACT_APP_CLOUD_FRONT,
  ROUTE_ORDER,
  ROUTE_MENU,
} from '../../../constants';
import styleClasses from '../../Header/Header.module.scss';
import useAddToOrder from '../../../hooks/useAddToOrder';
import { useHistory } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../../redux/store/store';
import { setShowCart } from '../../../redux/slices/cartSlice';
import { useStoreLocation } from '../../../context/StoreLocation';
import useCheckMobileScreen from '../../../hooks/useCheckMobileScreen';
import { brazeLogEventOnUpsellClick } from '../../../helpers/brazeHelper';

interface ISuggestedItemCard {
  upSellItem?: any;
  btnText?: string;
  setshowModal?: any;
}

const UpsellItemCard: React.FC<ISuggestedItemCard> = ({
  upSellItem,
  btnText,
  setshowModal,
}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { name, in_cart } = upSellItem;
  const { locationInfo } = useStoreLocation();
  const cart = useAppSelector((state) => state.cart);

  const order = useAppSelector((state: any) => state.itemCustomization);

  const isItMobile = useCheckMobileScreen();

  const onAddClickHandler = async () => {
    const currentAddedItem = cart.items.reduce((acc, item) => {
      // If the current item's id matches, replace the accumulator
      return item.item_id === order.item_id ? item : acc;
    }, null);
    const payloadForBrazeEvent = {
      storeId: locationInfo.selectedStore.id,
      storeName: locationInfo.selectedStore.name,
      orderType: cart.orderType,
      orderPlatform: isItMobile ? 'MobileWeb' : 'Web',

      addedItemId: !cart.showCart ? currentAddedItem?.item_id : "",
      addedItemName: !cart.showCart ? currentAddedItem?.name : "",
      addedItemSize: !cart.showCart ? currentAddedItem?.item_size : "",
      addedItemCategory: !cart.showCart ? currentAddedItem?.category_name : "",
      cartId: cart?.orderId ?? "",

      upsellName: upSellItem.name,
      redirectToItemId: upSellItem.redirect_to_item ? upSellItem.redirected_item_id : "",
      redirectToItemName: upSellItem.redirect_to_item ? upSellItem.name : "",

      redirectToCategoryId: upSellItem.redirect_to_category ? upSellItem.redirected_category_id : "",
      redirectToCategoryName: upSellItem.redirect_to_category ? upSellItem.category_name : "",

      order: upSellItem.order,

      ClickedFrom: cart.showCart ? 'Cart' : 'Add To Order Modal',
    };
    brazeLogEventOnUpsellClick(payloadForBrazeEvent);
    dispatch(setShowCart(false));
    setshowModal && setshowModal(false);
    if (upSellItem?.redirect_to_item) {
      history.push({
        pathname: `${ROUTE_ORDER}/${upSellItem.redirected_item_id}`,
        state: {
          OpenOtherModalAsItComingFromUpsell: false,
          is_recommended: true,
          category_id: upSellItem?.redirected_category_id,
        },
        search: `category_id=${upSellItem?.redirected_category_id}`,
      });
    } else if (upSellItem?.redirect_to_category) {
      history.push({
        pathname: `${ROUTE_MENU}/${upSellItem.category_name}`,
        state: {
          OpenOtherModalAsItComingFromUpsell: false,
          is_recommended: true,
        },
      });
    }
  };

  return (
    <>
      <Col sm="4" xs="4" className={styleClasses.suggestion_column}>
        <div
          className={`${styleClasses.other_products_item} px-1 text-center ${
            btnText === 'Added' && styleClasses.active
          }`}
        >
          <div className={styleClasses.cart_img_wrap + ' mb-3'}>
            <img
              // src={`${REACT_APP_CLOUD_FRONT}${image}`}
              src={`${REACT_APP_CLOUD_FRONT}${upSellItem.image_url}`}
              className="img-fluid"
              alt={name}
            />
          </div>
          <h5
            className={`${styleClasses.card_inner_lis_des} f-s14 f-sm-s11 lh-18 font-rale f-w5`}
          >
            {name}
          </h5>
          <button
            type="button"
            className={
              styleClasses.pro_item_btn +
              `btn-large item-select-btn lh-lg mx-md-2 btn h-auto f-s12 mb-2 font-rale text-capitalize mw-auto w-100 py-1 px-2 ${
                !in_cart && 'outline'
              }`
            }
            onClick={onAddClickHandler}
          >
            {btnText}
          </button>
        </div>
      </Col>
    </>
  );
};

export default UpsellItemCard;
