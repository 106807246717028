import { useMutation } from "react-query";
import { IRedeemPointsByOrderId } from "../models/order.model";
import { orderMicroService } from "../services";

const redeemPointsByOrderId = async (payload: IRedeemPointsByOrderId) => {
    const response = await orderMicroService.redeemPointsByOrderId(payload);
    return response.data;
};

export default function useRedeemPointsByOrderId() {
    return useMutation((payload: IRedeemPointsByOrderId) => redeemPointsByOrderId(payload));
}
