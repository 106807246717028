import { useQuery } from "react-query";
import { locationMenuService } from "../services";

const getLocations = async () => {
  const response = await locationMenuService.getAllLocations()
  return response.data.data;
};

export default function useMarketLocations() {
  return useQuery("locations", () => getLocations(), {
    retry: false,
    staleTime: Infinity,
  });
}