import { useMutation } from "react-query";
import { IUpdateOrderQuantityPayload } from "../../models/order.model";
import { orderMicroService } from "../../services";

const updateItemQuantity = async (payload: IUpdateOrderQuantityPayload) => {
    const { item_id, ...rest} = payload;
    const response = await orderMicroService.updateItemQuantity(item_id, rest);
    return response.data.data;
};

export default function useUpdateItemQuantity() {
    return useMutation((payload: IUpdateOrderQuantityPayload) => updateItemQuantity(payload));
}
