import { useMutation } from "react-query";
// import { IUpdateOrderQuantityPayload } from "../../models/order.model";
import { orderMicroService } from "../../services";

const toggleRedeemForPoints = async (payload) => {
    const { id,points} = payload;
    const response = await orderMicroService.toggleRedeemForPoints(id, payload);
    return response.data.data;
};

export default function useToggleRedeemForPoints() {
    return useMutation((payload:any) => toggleRedeemForPoints(payload));
}
