import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useQueryClient } from "react-query";
import useProfile from "../../../react-query-hooks/useProfile";
import { useAuthState } from "../../../context/UserAuthentication";
import styleClasses from "../../../containers/account/accounts.module.scss";
import useRemoveAddress from "../../../react-query-hooks/useRemoveAddress";
import NoDeliveryAdressBanner from "./NoDeliveryAdressBanner";
import DeliveryAdressListing from "./DeliveryAdressListing";
interface DeliveryAddressesTabProps {
  eventKey: string;
  showModal: (title?: string, id?: number) => void;
  isCheckout?: boolean;
  data: any
}

const DeliveryAddressesTab: React.FunctionComponent<
  DeliveryAddressesTabProps
> = ({ eventKey, showModal, data: userProfileData }) => {

  return <>
      <div className={` ${styleClasses.profile_inn_wrap} ps-0`}>
        <div className="mb-0 ">
          <h4 className="font-Cls f-s24 f-sm-s22 text-start h-24 medium_text mb-4">
            Delivery Address
          </h4>
        </div>
        {userProfileData?.addresses?.length === 0 ? (
          <>
            <NoDeliveryAdressBanner />
            <div className="d-flex justify-content-center mt-4">
              <button
                type="button"
                className="btn btn-large"
                onClick={() => showModal("New Delivery Address")}
              >
                ADD A DELIVERY ADDRESS
              </button>
            </div>
          </>
        ) : (
          <Row>
            <Col>
              <div
                className={`${styleClasses.user_info_wrap} text-start ${styleClasses.listing_style}`}
              >
                <div className={styleClasses.user_address_info}>
                  <ul className="list-inline text-start mb-3 ps-0 pe-3 pe-sm-0">
                    <DeliveryAdressListing showModal={showModal} />
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </div>
      <hr className="custom_hr_sty  d-none d-md-block" />
    </>
};

export default DeliveryAddressesTab;
