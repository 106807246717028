import React, { useState, useEffect } from "react";
import styleClasses from "./ItemGrid.module.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Item } from "../../models/Menu.model";
import { Container, Row, } from "react-bootstrap";
import { useLocation, useHistory } from "react-router-dom";
import * as CONSTANT from "../../constants";
import { Modal } from "react-bootstrap";
import { useAuthState } from "../../context/UserAuthentication/context";
import { useStoreLocation } from "../../context/StoreLocation";
import { ItemDetailsModal } from "./ItemDetialsModal";
import IMenuItems from "./Types";
import { useDispatch } from "react-redux";
import { resetContext } from "../../redux/slices/itemCustomizationSlice";
import OrderType from "./OrderType";
import { useAppSelector } from "../../redux/store/store";
import useCheckMobileScreen from "../../hooks/useCheckMobileScreen";
import ItemDescriptions from "./ItemListing/ItemDescriptions";
import { brazeLogCustomEventViewPage } from "../../helpers/brazeHelper";
import { isItCreateYourOwnItem } from "../../helpers/helperMethods";
interface ItemsProps {
  items: Item[];
  catering_menus?: string;
  searchText?: string;
  location_id?: number;
  category_id?: number;
  favorites?: { id: number; item_id: number; isSelected: boolean }[];
  discountApplied?: any;
  routeState?: {
    free?: boolean;
    toClaimCategory?: boolean;
    size?: string;
    name?: string;
    isReward?: boolean;
    reward_size?: string;
    reward_id?: number;
  };
  selected_category?: string;
  name?: string;
  userId?: string;
  favoriteButtonHandler?: (e: React.MouseEvent<HTMLButtonElement>, item) => void;
  addToOrder?: (structuredOrder: any) => void,
  categoryMenu?: {id:number,name:string}[]
}

const ItemGrid: React.FunctionComponent<ItemsProps> = ({
  items,
  catering_menus,
  searchText = "",
  location_id,
  category_id,
  favorites = [],
  discountApplied,
  routeState,
  selected_category,
  name,
  userId,
  favoriteButtonHandler, addToOrder,
  categoryMenu
}) => {
  const isItMobile = useCheckMobileScreen();
  const [openOrderModal, setOpenOrderModal] = useState(false);
  const [itemToOrder, setItemToOrder] = useState(null);
  const customizationDispatch = useDispatch();
  const { authInfo } = useAuthState();
  const [favourites, setFavourites] = useState(favorites);
  const [showMore, setShowMore] = useState(false);
  const [filteredIitems, setFilteredIitems] = useState([]);
  const [itemDetails, setItemDetails] = useState<IMenuItems>({} as IMenuItems);
  const location = useLocation();
  const history = useHistory();
  const { locationInfo } = useStoreLocation();
  const isOrderTypeSelected = useAppSelector(
    (state) => state.cart.isOrderTypeSelected
  );
  const savedOrderType = useAppSelector((state) => state.cart.orderType);
  const orderType = isOrderTypeSelected ? savedOrderType : "";
  useEffect(() => {
    !location.pathname.includes("catering") && setFavourites(favorites);
  }, [favorites]);
  const getClories = (item) => {

    if (item.calories === 0 && isItCreateYourOwnItem(item?.is_create_your_own)) {
      return `Calories Vary`
    } else if (item.calories === 0) {
      return ''
    }

    let calories = [];
    if (item.calories) {
      calories.push(
        `${item.calories_label === null ? "" : item.calories_label} ${item.calories
        } cal`
      );
    }
    if (item.full_calories) {
      calories.push(
        `${item.full_calories_label === null ? "" : item.full_calories_label} ${item.full_calories
        } cal`
      );
    }
    if (item.protein) {
      calories.push(item.protein + " Protein");
    }

    return calories.join(" /");
  };

  useEffect(() => {
    if (location.pathname.includes("catering")) {
      setFilteredIitems([]);
    } else {
      let array = [];
      array = items
      setFilteredIitems([...array]);
    }
  }, [searchText, items]);

  const closeOpenOrderModal = () => {
    setOpenOrderModal(false);
  };

  const showDetailsHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item
  ) => {
    setShowMore(true);
    setItemDetails(item);
    e.stopPropagation();
  };

  const closeDetailsModal = () => {
    setShowMore(false);
  };


  const handleCartDirectly = (item) => {
    let rewardSize = routeState?.reward_size?.toUpperCase();
    let itemSize = item.half_brink_item_id ? CONSTANT.HALF_SIZE.upper_case : CONSTANT.FULL_SIZE.upper_case;
    const structuredOrder = {
      // brink_id:12345,
      item_id: item?.id,
      display_price: item.price,
      item_size: rewardSize || itemSize,
      name: item?.name,
      modifiers: [],
      item_calories: item?.calories,
      // free:true,
      reward_id: routeState?.reward_id,
      category_name: item?.category_name,
      make_time_minutes: item?.make_time_minutes,
      id: item.item_id,
      category_id: item?.category_id
    };
    addToOrder(structuredOrder);
  }
  const orderClickHandler = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    item
  ) => {
    category_id = item?.category_id;
    if (isOrderTypeSelected && locationInfo.selectedStore) {
      if (item.cart_directly) {
        handleCartDirectly(item)
      }
      else {
        customizationDispatch(resetContext());
        history.push(
          {
            pathname: `${CONSTANT.ROUTE_ORDER}/${item.id}`,
            state: routeState?.isReward ? { reward_size: routeState.reward_size, routeState: routeState, category_id, categoryMenu } : { free: routeState?.free, size: routeState?.size, routeState: routeState, category_id, categoryMenu },
            search: `category_id=${item?.category_id}`
          })
        brazeLogCustomEventViewPage(item.name, isItMobile);
      }
    } else {
      const pathToRoute = {
        pathname: `${CONSTANT.ROUTE_ORDER}/${item.id}`,
        state: routeState?.isReward
          ? { reward_size: routeState.reward_size, routeState: routeState,category_id,categoryMenu }
          : {
            free: routeState?.free,
            size: routeState?.size,
            routeState: routeState,
            category_id,
            categoryMenu
          },
        search: `category_id=${category_id}`,
      };
      if (item.cart_directly) setItemToOrder(null)
      else setItemToOrder(pathToRoute);
      setOpenOrderModal(true);
    }
  };


  return (
    <>
      {
        <Container fluid className="p-0">
          <Row
            className={`${styleClasses.item_details_row_wrap} pt-2 gap-4 gap-md-4 mx-0`}
          >
            {filteredIitems.length !== 0 ? (
              filteredIitems.map((item) => (
             <ItemDescriptions
             key={item.id}
             item={item}
             favourites={favourites}
             favoriteButtonHandler={favoriteButtonHandler}
             showDetailsHandler={showDetailsHandler}
             locationInfo={locationInfo}
             orderType={orderType}
             catering_menus={catering_menus}
             filteredIitems={filteredIitems}
             discountApplied={discountApplied}
             getClories={getClories}
             orderClickHandler={orderClickHandler}
             />
              ))
            ) : (
              <div className={`${styleClasses.no_item__wrap} mx-auto`}>
                <h5 className="font-Cls f-s30 f-sm-s24 mb-3">
                  0 results found
                </h5>
                <p className="f-s16 mb-0">
                  Sorry! We couldn’t find what you were looking for. Might we
                  suggest some Cafe Zupas fan favorites...
                </p>
              </div>
            )}
          </Row>
          <Modal
            show={openOrderModal}
            centered
            className="theme-modal-style app_container_wrap  border-radius-15 px-5 order_type_modal"
            onHide={closeOpenOrderModal}
            size="sm"
          >
            <Modal.Header className="x-4 justify-content-center">
              <button
                type="button"
                className="btn modal-close-button pe-0 sm-blue-icon"
                onClick={closeOpenOrderModal}
              >
                Close
              </button>
            </Modal.Header>
            <Modal.Body>
              <OrderType
                itemPath={itemToOrder}
                handleOrderTypeModal={closeOpenOrderModal}
              />
            </Modal.Body>
          </Modal>

          <ItemDetailsModal
            showModal={showMore}
            closeModal={closeDetailsModal}
            itemDetials={itemDetails}
            getClories={getClories}
            location={locationInfo}
          />
        </Container>
      }
    </>
  );
};

export default ItemGrid;
