import React, { Dispatch, SetStateAction, useState } from "react";
import { cartItem, IOfferItem } from "../../../models/cart.model";
import CartItemCard from "./CartItemCard";
import CartComboItemCard from "./CartComboItemCard";
import { SINGLE_ITEM } from "../../../constants";
import { updateItemKey} from "../../../redux/slices/cartSlice";
import {updateTempLoyalityPoints} from "../../../redux/slices/itemSlice";
import useToggleRedeemForPoints from "../../../react-query-hooks/Cart/useToggleRedeemItemForPoints";
import useToggleRedeemFromRewards from "../../../react-query-hooks/Cart/useToggleRedeemFromRewards";
import {useAppDispatch, useAppSelector} from "../../../redux/store/store";
import useRedeemReward from "../../../hooks/useRedeemReward";

interface ICartItem {
  handleItemRemove: (
    item_id: string | number,
    iteratingIndex: number,
    closeModal: Function
  ) => void;
  handleQuantityChange: (
    item_id: string | number,
    iteratingIndex: number,
    itemQuantity: number
  ) => void;
  setIsRedeeming: Dispatch<SetStateAction<boolean>>;
  selectedOfferItem: IOfferItem;
  rewardsDiscountedSubTotal: number;
  isRedeeming: boolean;
  isAutoRedeeming: boolean;
  item: cartItem;
  iteratingIndex: number;
  myReward: any;
  redeemable: any;
  isActive: boolean;
  loading: boolean;
  setMyRewards: (any) => void;
  myRewards: any;
}

const CartItem = ({
  isRedeeming,
  isAutoRedeeming,
  selectedOfferItem,
  rewardsDiscountedSubTotal,
  setIsRedeeming,
  item,
  iteratingIndex,
  myReward,
  redeemable,
  isActive,
  loading,
  handleItemRemove,
  handleQuantityChange,
  setMyRewards,
  myRewards,
}: ICartItem) => {
  const dispatch = useAppDispatch();
  const {tempLoyalityPoints}=useAppSelector(state=>state.Items.item)
  const {mutate:toggleRedeemForPoints,isLoading:togglingPoints}=useToggleRedeemForPoints()
  const {mutate:toggleRedeemFromRewards,isLoading:togglingReward}=useToggleRedeemFromRewards()
  const [isTogglingReward, setIsTogglingReward] = useState<boolean>(false);
  const {redeemReward} = useRedeemReward(myRewards, setMyRewards);
  const redeemfromMyRewards= async ()=>{
    redeemReward({
          toggleData: toggleRedeemFromRewards,
          data: {
            id: item.id,
            reward_redeemed: !item.reward_redeemed,
            reward_id: !item.reward_redeemed ? item.reward_id : null
          },
      iteratingIndex: iteratingIndex,
      item: item,
      setIsTogglingReward: setIsTogglingReward,
      rewardItem: myReward
    }
    )
  }
  const redeemForPoints = async() => {
    dispatch(updateItemKey({ index: iteratingIndex, value: !item.redemption_by_points, key: "redemption_by_points" }));
    if(item.redemption_by_points)
    {
      dispatch(updateTempLoyalityPoints(tempLoyalityPoints + parseInt(item?.redeem_points)))
    }
    else {
      dispatch(updateTempLoyalityPoints(tempLoyalityPoints - parseInt(item?.redeem_points)))
    }
    toggleRedeemForPoints({id: item.id, redemption_by_points: Number(!item.redemption_by_points)},{})
  };
  return (
    <>
      {item.item_type === SINGLE_ITEM ? (
        <CartItemCard
          isRedeeming={isRedeeming}
          isAutoRedeeming={isAutoRedeeming}
          selectedOfferItem={selectedOfferItem}
          rewardsDiscountedSubTotal={rewardsDiscountedSubTotal}
          setIsRedeeming={setIsRedeeming}
          item={item}
          iteratingIndex={iteratingIndex}
          redeemable={redeemable}
          handleItemRemove={handleItemRemove}
          handleQuantityChange={handleQuantityChange}
          isActive={isActive}
          loading={loading}
          redeemForPoints={redeemForPoints}
          redeemfromMyRewards={redeemfromMyRewards}
          togglingPoints={togglingPoints}
          togglingReward={togglingReward || isTogglingReward}
        />
      ) : (
        <CartComboItemCard
          isRedeeming={isRedeeming}
          isAutoRedeeming={isAutoRedeeming}
          selectedOfferItem={selectedOfferItem}
          rewardsDiscountedSubTotal={rewardsDiscountedSubTotal}
          setIsRedeeming={setIsRedeeming}
          item={item}
          itemId={Number(item.item_id)}
          iteratingIndex={iteratingIndex}
          redeemable={redeemable}
          handleItemRemove={handleItemRemove}
          handleQuantityChange={handleQuantityChange}
          isActive={isActive}
          loading={loading}
          redeemForPoints={redeemForPoints}
          redeemfromMyRewards={redeemfromMyRewards}
          togglingPoints={togglingPoints}
          togglingReward={togglingReward}
        />
      )}
    </>
  );
};

export default CartItem;
