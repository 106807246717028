import Bugsnag, { BreadcrumbType } from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import React from "react";
import { getUser } from "./helperMethods";
import { APP_VERSION } from "../constants";

const BugSnagTypes = { STATE: "state", ERROR: "error" };

export const bugSnagInititalize = () => {
  const isEnabledReleaseStage = Boolean(
    process.env.REACT_APP_BUG_SNAG_IS_ENABLED_RELEASE_STAGE
  );
  const enabledReleaseStages = isEnabledReleaseStage
    ? [process.env.REACT_APP_ENV]
    : [];

  Bugsnag.start({
    apiKey: isEnabledReleaseStage
      ? process.env.REACT_APP_BUG_SNAG_API_KEY
      : "noAPIKeyNeeded",
    plugins: [new BugsnagPluginReact()],
    onError: (event) => {
      const user = getUser();
      event.setUser(user.id, user.email, user.full_name);
      const errorClass = event?.errors[0]?.errorClass;
      const errorMessage = event?.errors[0]?.errorMessage;
      // Check for the chunk error class or message
      if (
        errorClass === 'ChunkLoadError' || 
        errorMessage.includes('Loading CSS chunk') ||
        errorMessage?.includes("Failed to load Stripe.js") ||
        errorMessage?.includes("Network Error") ||
        errorMessage?.includes("Could not load \"util\"") ||
        errorMessage?.includes("Unable to preload CSS") ||
        errorMessage?.includes("ResizeObserver loop") || 
        errorMessage?.includes("Unexpected token") ||
        errorMessage?.includes("Can't find variable: HTMLDialogElement")
      )
      {
        return false; // Prevent Bugsnag from reporting this error
      }
      if (
        errorMessage.includes("googleapis.com/maps/api/js") ||
        errorMessage.includes("undefined is not an object (evaluating 'p.YG')") 

      ) {
        console.warn("Google Maps API error:", errorMessage);
        return false; // Prevent Bugsnag from reporting Google Maps API errors
      }
      if (
        errorMessage?.includes("Failed to fetch dynamically imported") || 
        errorMessage?.includes("unhandledrejection handler") 
      )
      {
        Bugsnag.notify(new Error("Non-error promise rejection"), (bugsnagEvent) => {
          bugsnagEvent.addMetadata("promiseRejection", {
            reason: JSON.stringify(event),
            type: typeof event
          });
        });
        return false;
      }
    },
    enabledReleaseStages,
    releaseStage: process.env.REACT_APP_ENV,
    appVersion: APP_VERSION,
  });
  return Bugsnag.getPlugin("react").createErrorBoundary(React);
};

export const bugSnagNotifyAPIError = (
  apiName: string,
  requestPayload: Object,
  responseErrorMessage: string
) => {
  Bugsnag.leaveBreadcrumb(
    `Failed API: ${apiName}`,
    requestPayload,
    BugSnagTypes.ERROR as BreadcrumbType
  );
  Bugsnag.notify(responseErrorMessage);
};
