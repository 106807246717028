import { SELECTED_STORE } from "../../constants";

export async function setLocation(dispatch, locationPayload) {
  try {
    dispatch({ type: "SET_LOCATION", payload: locationPayload });
    localStorage.setItem(SELECTED_STORE, JSON.stringify(locationPayload));
  } catch (error) {
    dispatch({
      type: "LOCATION_ERROR",
      error: error,
    });
  }
}
