import React, { createContext, useReducer, useContext } from "react";
import { AuthReducer, initialState } from "./reducer";

const AuthStateContext = createContext(undefined);

export const AuthProvider = ({ children }) => {
  const [authInfo, dispatch] = useReducer(AuthReducer, initialState);
  return (
    <AuthStateContext.Provider value={{ authInfo, dispatch }}>
      {children}
    </AuthStateContext.Provider>
  );
};

export const useAuthState = () => {
  const context = useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error("useAuthState must be used within a AuthProvider");
  }
  return context;
};
