import React from "react";
import {useAppDispatch, useAppSelector} from "../redux/store/store";
import {updateItemKey} from "../redux/slices/cartSlice";
import {customerBusinessService} from "../services";
import {cartItem} from "../models/cart.model";
import {IRewardItem} from "../models/rewards.model";

interface IRedeemReward {
    toggleData: any,
    data: any,
    iteratingIndex: Number,
    item: cartItem,
    setRewardState?: (any) => void,
    setIsTogglingReward?: (boolean) => void,
    rewardItem?: IRewardItem
}

const useRedeemReward = (myRewards: any, setMyRewards: any) => {
    const dispatch = useAppDispatch();
    let cartItems = useAppSelector(
        (state) => state.cart.items
    );
    const redeemReward = ({toggleData, data, iteratingIndex, item, setRewardState = null,
                              setIsTogglingReward, rewardItem = null} : IRedeemReward) => {
        const tempData = structuredClone(item)
        dispatch(updateItemKey({ index: iteratingIndex, value: !item?.reward_redeemed, key: "reward_redeemed" }));
        dispatch(updateItemKey({ index: iteratingIndex, value: rewardItem?.loyalty_id, key: "reward_id" } ));
        updateRewardAgainstItem(rewardItem)
        customerBusinessService.updateReward(rewardItem?.loyalty_id, {is_active_in_cart: Number(!item?.reward_redeemed)})
        if(setRewardState) setRewardState(!rewardItem?.is_active_in_cart)
        toggleData(
            data,
            {
                onSuccess: async () => {
                    setIsTogglingReward(true);
                    setIsTogglingReward(false);
                    // This code is to unlink the reward with other item in cart if found
                    if(!item.reward_redeemed)
                    {
                        const tempIndex = cartItems.findIndex((cartItem) => cartItem?.reward_id == rewardItem?.loyalty_id &&
                            cartItem?.id != item?.id
                        )
                        if(tempIndex >=0 ) dispatch(updateItemKey({ index: tempIndex, value: item?.reward_id, key: "reward_id" } ));
                    }
                },
                onError: (error) =>{
                    if(setRewardState) setRewardState(tempData?.reward_redeemed)
                    revertRewardsOnFailure()
                    dispatch(updateItemKey({ index: iteratingIndex, value: tempData?.reward_redeemed, key: "reward_redeemed" }));
                    dispatch(updateItemKey({ index: iteratingIndex, value: tempData?.reward_id, key: "reward_id" } ));
                }
            }
        )
    }
    const updateRewardAgainstItem = (rewardItem) => {
        const rewards = structuredClone(myRewards)
        const index = rewards?.findIndex(reward => reward?.loyalty_id === rewardItem?.loyalty_id)
        if(index >= 0)rewards[index].is_active_in_cart = !rewardItem?.is_active_in_cart
        setMyRewards(rewards)
    }
    const revertRewardsOnFailure = () => {
        setMyRewards(myRewards)
    }
    return { redeemReward, updateRewardAgainstItem, revertRewardsOnFailure }
}

export default useRedeemReward;
