import { useMutation } from "react-query";
import {customerBusinessService, paymentService} from "../services";

const updateGiftCard = async (data: any, recordID: string) => {
    const response = await customerBusinessService.updateGiftCard(recordID, data)
    return response.data.data;
};

export default function useUpdateGiftCard() {
    return useMutation((data: { data: any; recordID: string }) =>
        updateGiftCard(data.data, data.recordID)
    );
}
