import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { NavLink } from "react-router-dom";
import * as CONSTANT from "../../constants";
import TeamJobsMobile from "../../containers/career/teamJobsMobile";
import styles from "./NavbarComponent.module.scss";
import { ArrowRightIcon } from "../../images/svgIcons/svg";

const careerLinks = [
  {
    text: "career overview",
    link: CONSTANT.ROUTE_CAREERS,
  },
  {
    text: "career values",
    link: CONSTANT.ROUTE_CAREERS_VALUES,
  },
  {
    text: "travel abroad",
    link: CONSTANT.ROUTE_TRAVEL_ABROAD,
  },
  {
    text: "positions",
    link: CONSTANT.ROUTE_HOSPITALITY_TEAM,
  },
];

const CareerNavComponent = () => {
  return (
    <div className={styles.careerAccordion}>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Careers</Accordion.Header>
          <Accordion.Body>
            {careerLinks.map((data) => {
              return (
                <div className="d-flex justify-content-between align-items-center border-top" key={data.link}>
                  <a
                    href={data.link}
                    className="d-flex justify-content-between align-items-center w-100">
                    {data.text}
                    <div className={styles.iconDiv}>
                      <ArrowRightIcon />
                    </div>
                  </a>
                </div>
              );
            })}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default CareerNavComponent;
