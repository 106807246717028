import * as Yup from "yup";

export const personalInfoSchema = (isSmsDubsscription = false) => Yup.object().shape({
  first_name: Yup.string()
    .required("First name is required")
    .matches(
      /^[a-zA-Z ]*$/,
      "First name should not contain special characters or numbers"
    )
    .max(50, "First name should not contain more than 50 characters"),
  last_name: Yup.string()
    .required("Last name is required")
    .matches(
      /^[a-zA-Z ]*$/,
      "Last name should not contain special characters or numbers"
    ),
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email address is required"),
  phone: isSmsDubsscription ? 
  Yup.string()
    .required("Phone number is required")
    .transform((value) => value.replace(/[^\d]/g, ""))
    .length(10, "Phone number must be 10 digits long") :
    Yup.string()
    .nullable()
    .transform((value) => value.replace(/[^\d]/g, ""))
    .length(10, "Phone number must be 10 digits long")  ,
  favorite_location: Yup.array().nullable(),
  birthday: Yup.string()
    .optional()
    .test("valid-date", "Please enter a valid Birthdate (MM/DD)", (date) =>
      isValidDateInput(date)
    ),
});

const isValidDateInput = (value: string) => {
  if (value?.length > 0) {
    const today = new Date();
    const currentYear = today.getFullYear();
    const [month, day] = value?.split("/").map((s) => parseInt(s, 10));
    const isMonthValid = month >= 1 && month <= 12;
    const isDayValid =
      day >= 1 && day <= new Date(currentYear, month, 0).getDate();
    return isMonthValid && isDayValid;
  }
  return true;
};

