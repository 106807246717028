import React, { useState, useEffect } from "react";
import styleClasses from "./NewFooter.module.scss";
import { Container, Row, Col } from "react-bootstrap";
import { getUser } from "../../helpers/helperMethods";
import * as CONSTANT from "../../constants";
import useKeypress from "../../hooks/useKeypress";
import PopUpModel from "../../components/Footer/PopUpModel";
import { NavlinkModel } from "../../models/navlink.model";
import { Link } from "react-router-dom";
import GlutenSensitiveModal from "./GlutenSensitiveModal";
import { InstagramIcon, MailIcon, TiktokIcon } from "../../images/svgIcons/svg";
import useCheckMobileScreen from "../../hooks/useCheckMobileScreen";
import { useAuthState } from "../../context/UserAuthentication";
interface footerProps {
  bgColor?: string;
  textColor?: string;
  mt?: boolean;
  footer_img_controller?: string;
  sectionController?: string;
  hidemurals?: string;
  stickybtn?: string;
}
interface UserFormData {
  first_name: string;
  last_name: string;
  email: string;
  location: string;
}

const NewFooter: React.FunctionComponent<footerProps> = ({
  sectionController,
}) => {
  useKeypress("Escape", () => {
    setShow(false);
  });
  const isItMobile = useCheckMobileScreen();
  const { authInfo } = useAuthState();
  const [isDeviceIos, setIsDeviceIos] = React.useState(false);
  const [show, setShow] = useState(false);
  const [openGlutenModal, setOpenGlutenModal] = useState(false);
  const user = getUser();
  const userType = user.type ?? "";
  const authUserId = userType !== CONSTANT.GUEST_USER ? authInfo?.userId : null;

  const footerLinks: NavlinkModel[] = [
    {
      link: CONSTANT.ROUTE_MENU,
      text: "MENU",
      alternateURL: { exist: false, link: null },
    },
    {
      text: "REWARDS",
      link: CONSTANT.ROUTE_FAQ,
      alternateURL: { exist: true, link: CONSTANT.ROUTE_REWARDS_REDEEM },
    },
    {
      link: CONSTANT.CATERING_PRINT_MENU,
      text: "CATERING MENU",
      alternateURL: { exist: false, link: null },
    },
    {
      link: CONSTANT.CATERING_LOYALTY_PAGE,
      text: "CATERING  Rewards",
      alternateURL: { exist: false, link: null },
    },
    {
      link: CONSTANT.ROUTE_NUTRITION_MENU,
      text: "NUTRITIONAL INFO ",
      alternateURL: { exist: false, link: null },
    },
    {
      link: CONSTANT.ROUTE_LOCATIONS,
      text: "FIND A RESTAURANT ",
      alternateURL: { exist: false, link: null },
    },
    {
      link: CONSTANT.ROUTE_CAREERS,
      text: "CAREERS",
      alternateURL: { exist: false, link: null },
    },
    {
      link: CONSTANT.ROUTE_TALK_TO_US,
      text: "CONTACT US",
      alternateURL: { exist: false, link: null },
    },
    {
      link: CONSTANT.PRIVACY_POLICY_ROUTE,
      text: "PRIVACY POLICY",
      alternateURL: { exist: false, link: null },
    },
    {
      link: CONSTANT.TERMS_ROUTE,
      text: "TERMS & CONDITIONS",
      alternateURL: { exist: false, link: null },
    },
  ];

  const socialIconsArray = [
    {
      link: CONSTANT.CAFEZUPAS_TIKTOK_URL,
      icon: <TiktokIcon />,
      socail_alt_text: `tiktok`,
    },
    {
      link: `${CONSTANT.CAFEZUPAS_INSTAGRAM_URL}cafezupas/`,
      icon: <InstagramIcon />,
      socail_alt_text: `instargram`,
    },
  ];
  const [moboImages, setmoboImages] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleClick = () => setShow(true);

  const loyaltyRedirectionLink = (item) => {
    return userType === CONSTANT.GUEST_USER || user?.logged_out
      ? item.link
      : item.alternateURL.exist
      ? item.alternateURL.link
      : item.link;
  };

  useEffect(() => {
    let isMounted = true;
    var userAgent = navigator.userAgent || navigator.vendor;
    var x = window.matchMedia("(min-width: 768px)");
    if (x.matches) {
      setmoboImages(true);
    } else {
      setmoboImages(false);
    }
    if (/iPad|iPhone|iPod|Mac/.test(userAgent) && !window.MSStream) {
      setIsDeviceIos(true);
    } else {
      setIsDeviceIos(false);
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className={sectionController}>
      <div className={`${styleClasses.footerWrapper}`}>
        <Container fluid className="cz-px-30">
          <Row className="align-items-start">
            {!isItMobile ? (
              <div>
                <h1 className={styleClasses.footerTitle}>
                  find the good life everywhere{" "}
                </h1>
                <ul className={styleClasses.socialIcons}>
                  <li>
                    <a onClick={handleClick}>
                      {" "}
                      <MailIcon />{" "}
                    </a>
                  </li>
                  {socialIconsArray.map((icon) => {
                    return (
                      <li>
                        <a href={icon.link} target="_blanl">
                          {" "}
                          {icon.icon}{" "}
                        </a>
                      </li>
                    );
                  })}
                </ul>

                <ul className={`${styleClasses.footerLinks} ps-0`}>
                  {footerLinks.slice(0, 5).map((link) => {
                    return (
                      <li key={link.text}>
                        {" "}
                        {link.link === CONSTANT.ROUTE_NUTRITION_MENU ||
                        link.link === CONSTANT.CATERING_PRINT_MENU ? (
                          <a
                            href={link.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {link.text}
                          </a>
                        ) : link.link === CONSTANT.CATERING_LOYALTY_PAGE ? (
                          <a href={link.link}>{link.text}</a>
                        ) : (
                          <Link to={"" + loyaltyRedirectionLink(link)}>
                            {link.text}
                          </Link>
                        )}
                      </li>
                    );
                  })}
                </ul>

                <ul className={`${styleClasses.footerLinks} ps-0`}>
                  {footerLinks.slice(5).map((link) => {
                    return (
                      <li>
                        <Link to={link.link}>{link.text}</Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            ) : (
              <div>
                <ul className={`${styleClasses.socialIcons} ps-0`}>
                  <li>
                    <a onClick={handleClick}>
                      {" "}
                      <MailIcon />{" "}
                    </a>
                  </li>
                  {socialIconsArray.map((icon) => {
                    return (
                      <li>
                        <a href={icon.link}> {icon.icon} </a>
                      </li>
                    );
                  })}
                </ul>

                <h1 className={styleClasses.footerTitle}>
                  find the good life everywhere{" "}
                </h1>
                <div className="cz-py-30">
                  <h3 className="font-trade-gothic-next-condensed f-s20 f-w8 color-white">
                    DOWNLOAD THE APP{" "}
                  </h3>
                  <div className="d-flex align-items-center  gap-2 justify-content-center mt-3">
                    <a
                      href={
                        "https://apps.apple.com/pk/app/caf%C3%A9-zupas-loyalty/id6448428217"
                      }
                      target={"_blank"}
                      rel="noopener noreferrer"
                    >
                      <img
                        src={require("../../images/appStore.png")}
                        width={150}
                        alt="app store icon"
                      />
                    </a>
                    <a
                      href={
                        "https://play.google.com/store/apps/details?id=com.cafezupasres.co&hl=en"
                      }
                      target={"_blank"}
                      rel="noopener noreferrer"
                    >
                      <img
                        src={require("../../images/playStore.png")}
                        width={150}
                        alt="play store icon"
                      />
                    </a>
                  </div>
                </div>

                <ul className={`${styleClasses.footerLinks} row ps-0 cz-pt-24`}>
                  {footerLinks.map((link) => {
                    return (
                      <li key={link.text} className="col-6">
                        {" "}
                        {link.link === CONSTANT.ROUTE_NUTRITION_MENU ||
                        link.link === CONSTANT.CATERING_PRINT_MENU ? (
                          <a
                            href={link.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {link.text}
                          </a>
                        ) : link.link === CONSTANT.CATERING_LOYALTY_PAGE ? (
                          <a href={link.link}>{link.text}</a>
                        ) : (
                          <Link to={"" + loyaltyRedirectionLink(link)}>
                            {link.text}
                          </Link>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </Row>
        </Container>
      </div>

      <Row>
        <Col xs="12" sm="12" md="6">
          <PopUpModel showPopup={show} closePopup={handleClose} />
        </Col>
        <GlutenSensitiveModal
          openGlutenModal={openGlutenModal}
          setOpenGlutenModal={setOpenGlutenModal}
        />
      </Row>
    </div>
  );
};

export default NewFooter;
