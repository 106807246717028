import React from "react";
import { useHistory } from "react-router";
import { ROUTE_MENU } from "../../constants";
import styleClasses from "../../containers/account/accounts.module.scss";

const NoRecentOrder = ({ isFavorite }) => {
  const history = useHistory();
  const goToRoute = (route: string) => history.push(route);

  return (
    <div className={`${styleClasses.inn_content_wrap} mx-auto`}>
      <img
        src={require("../../images/recent-orders.svg")}
        className="img-fluid"
        alt="recent order"
      />
      {isFavorite ? (
        <h1 className="h-24  font-Cls medium_text">
          You have no favorite
          <br /> orders...yet!
        </h1>
      ) : (
        <h1 className="h-24  font-Cls medium_text">
          You have no recent
          <br /> orders...yet!
        </h1>
      )}
      {!isFavorite ? (
        <p className="f-sm-s14 mx-auto">
          Your most recent orders will appear here and on the home screen once
          you have placed on order.
        </p>
      ) : (
        <p className="f-sm-s14 mx-auto">
          Your favorite orders will appear here once you mark an order as
          favorite
        </p>
      )}
      <hr className="custom_hr_sty mt-5  d-md-none" />
      <div className="d-flex justify-content-center mt-4 ">
        <button
          type="button"
          className="btn btn-large mb-4"
          onClick={(event: any) => {
            event.preventDefault();
            goToRoute(ROUTE_MENU);
          }}
        >
          Start an order
        </button>
      </div>
    </div>
  );
};

export default NoRecentOrder;
