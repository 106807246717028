import { REACT_APP_CLOUD_FRONT } from "../../../constants";

export const symbolDetails = {
    "vegetarian": {
      icon: `${REACT_APP_CLOUD_FRONT}/images/Menu/symbol-icons/Veg-icon.svg`,
      className: "tooltip-veg",
    },
    "keto": {
      icon: `${REACT_APP_CLOUD_FRONT}/images/Menu/symbol-icons/Keto-icon.svg`,
      className: "tooltip-keto",
    },
    "low calories": {
      icon: `${REACT_APP_CLOUD_FRONT}/images/Menu/symbol-icons/Low-cal-icon.svg`,
      className: "tooltip-low",
    },
    "high protein": {
      icon: `${REACT_APP_CLOUD_FRONT}/images/Menu/symbol-icons/High-pro-icon.svg`,
      className: "tooltip-high",
    },
    "dairy-free option": {
      icon: `${REACT_APP_CLOUD_FRONT}/images/Menu/symbol-icons/Dairy-free-icon.svg`,
      className: "tooltip-dairy",
    },
    "gluten free": {
      icon: `${REACT_APP_CLOUD_FRONT}/images/Menu/symbol-icons/Gluten-free-icon.svg`,
      className: "tooltip-dairy",
    },
  }