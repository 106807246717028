export const COMPLIMENTARY_MODIFIERS = "complementary_modifiers";
export const ADDITIONALPRICE = "additionalPrice";
export const ORIGINALPRICE = "originalPrice";
export const REQUIRED_MODIFIERS = "required_modifiers";
export const CORE_MODIFIERS = "core_modifiers";
export const ADD_ONS = "add_ons";
export const BY_DEFAULT_ADDED = "byDefaultAdded";
export const BY_DEFAULT_ADDED_V2 = "byDefaultAddedV2";
export const ADDITIONAL_ADDED = "additionalAdded";
export const ADDITIONAL_REMOVED = "additionalRemoved";
export const MODIFIERS = "modifiers";
export const CORE_RELATED = "core_related";