import React from "react";

const DeliveryAddress: React.FC<{
  customerDetails: {
    first_name: string;
    street_1: string;
    city: string;
    state: string;
    zipcode: string;
    street_2?:string
  };
}> = ({ customerDetails: {first_name, street_1, city, state, zipcode, street_2} }) => {
  return (
    <>
      <h3 className="f-s16 f-sm-only-s20 clr-dark-gray fw-normal mb-0 pt-2">
        Delivery Details
      </h3>
      <p className="f-s14 clr-dark-gray mb-0 text-uppercase font-Visby-cf ls-1 fw-bold pb-2">
        {street_1} {street_2} {city}, {state} {zipcode}
      </p>
    </>
  );
};

export default DeliveryAddress;
