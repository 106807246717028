import { useMutation } from "react-query";
import { customerBusinessService } from "../services";

const removeAddress = async (addressId: number, access_token: string) => {
  const response = await customerBusinessService.removeAddress(addressId)
  return response.data.data;
};

export default function useRemoveAddress() {
  return useMutation((data: { id: number, access_token: string }) =>
    removeAddress(data.id, data.access_token)
  );
}
