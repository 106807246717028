import React, { useCallback, useEffect, useState } from "react";
import styleClasses from "../../../containers/account/accounts.module.scss";
import { useHistory } from "react-router-dom";
import * as CONSTANT from "../../../../src/constants";
import { OrderList } from "./OrderList";
import NoRecentOrder from "../../RecentOrder/NoRecentOrder";
import { useAuthState } from "../../../context/UserAuthentication";
import useOrderHistory from "../../../react-query-hooks/useOrderHistory";
import {
  AddToFavoriteModalConfirmation,
  LabelFavoriteOrder,
} from "../../../containers/account/profileDetails";

import useRemoveFavoriteOrder from "../../../react-query-hooks/Favorites/useRemoveFavoriteOrder";
import _clonedeep from "lodash.clonedeep";
import _debounce from "lodash/debounce";
import useAddFavoritesOrder from "../../../react-query-hooks/Favorites/useAddFavoriteOrder";
import useCheckMobileScreen from "../../../hooks/useCheckMobileScreen";
import BasicSpinner from "../../Spinner/BasicSpinner";
import { useQueryClient } from "react-query";
import { brazeLogCustomEventFavoriteOrder } from "../../../helpers/brazeHelper";
import { Toast_Func } from "../../../helpers/toast.helper";

const FavoriteTabKey = "seventh";
export const RecentOrder = ({
  order: data,
  is_favorite_order,
  setActiveTabkey,
  scrollToFavOrders,
  refetchFavoriteAndOrderHistoryData: refetchFavoriteAndOrderHistoryData,
  isFavoriteLoading,
}:any) => {
  const history = useHistory();
  const goToRoute = (route: string, state?: any) =>
    state ? history.push(route, state) : history.push(route);
  const { authInfo } = useAuthState();
  const [favoriteModal, setFavoriteModal] = useState(false);
  const isItMobile = useCheckMobileScreen();

  // if (isFetching) {
  //   return <Spinner />;
  // }

  let { mutateAsync: removeFavorite } = useRemoveFavoriteOrder();
  let { mutateAsync: addFavorite } = useAddFavoritesOrder();
  const queryClient = useQueryClient();

  const [labelOrderModal, setLabelOrderModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<{id:number}>();

  const scrollToOrChangeToFavoriteSection = () => {
    // if (isItMobile) {
    //   setActiveTabkey(FavoriteTabKey);
    // } else {
      // window.scrollTo({
      //   top: scrollToFavOrders.current.offsetTop - 150,
      //   behavior: "smooth",
      // });
    // }
  };

  const onErrorAddFavoriteOrder = () => {
    queryClient?.setQueryData(
      ["orderHistory", { page: 1, is_favorite: 1 }],
      (data:any) => {
          data?.pages[0]?.data?.shift();
        
        return data;
      }
    );
    queryClient?.setQueryData(["orderHistory", { page: 1 }], (data:any) => {
      const updatedData = data?.pages[0]?.data?.map((orderItem) => {
        if (orderItem.id == selectedItem?.id) return selectedItem;
        return orderItem;
      });
      data.pages[0].data = updatedData;
      return data;
    });
  };
  const onRemoveAddFavoriteOrder = (prevItem) => {
    queryClient?.setQueryData(
      ["orderHistory", { page: 1, is_favorite: 1 }],
      (data:any) => {
        data.pages[0].data = prevItem;
        return data;
      }
    );
    queryClient?.setQueryData(["orderHistory", { page: 1 }], (data:any) => {
      const updatedData = data?.pages[0]?.data?.map((orderItem) => {
        if (orderItem.id == selectedItem.id) return selectedItem;
        return orderItem;
      });
      data.pages[0].data = updatedData;
      return data;
    });
  };

  const removeFavoriteItem = (item: any, prevItem: any) => {
    removeFavorite(
      { ...item, customer_id: authInfo.userId },
      {
        onSuccess: () => {
          refetchFavoriteAndOrderHistoryData?.map((cb) => cb());
        },
        onError: () => onRemoveAddFavoriteOrder(prevItem),
      }
    );
  };

  const addFavoriteItem = (item: any) => {
    addFavorite(
      { ...item, customer_id: authInfo.userId },
      {
        onSuccess: () => {
          brazeLogCustomEventFavoriteOrder(item,isItMobile)
          Toast_Func({status:true, message:"You have successfully marked this order as a favorite for easy re-ordering"})
          refetchFavoriteAndOrderHistoryData?.map((cb) => cb());
        },
        onError: onErrorAddFavoriteOrder,
      }
    );
  };

  const debouncedAddFavourite = useCallback(_debounce(addFavoriteItem, 500), [
    data,
    selectedItem,
  ]);
  const debouncedRemoveFavourite = useCallback(
    _debounce(removeFavoriteItem, 500),
    [data, selectedItem]
  );

  const favoriteButtonHandler = (
    // e: React.MouseEvent<HTMLButtonElement>,
    item,
    order_name
  ) => {
    if (item.is_favorite) {
      const favListCopy = _clonedeep(data?.pages[0].data);
      queryClient?.setQueryData(
        ["orderHistory", { page: 1, is_favorite: 1 }],
        (data:any) => {
          const updatedData = data?.pages[0]?.data?.filter(
            (orderItem) => orderItem.id != item.id
          );

          data.pages[0].data = updatedData;
          return data;
        }
      );
      queryClient?.setQueryData(["orderHistory", { page: 1 }], (data:any) => {
        const updatedData = data?.pages[0]?.data?.map((orderItem) => {
          if (orderItem.id == item.id)
            return {
              ...orderItem,
              favorite_order_name: "",
              is_favorite: false,
            };

          return orderItem;
        });
        data.pages[0].data = updatedData;
        return data;
      });
      debouncedRemoveFavourite(item, favListCopy);
    } else {
      queryClient?.setQueryData(
        ["orderHistory", { page: 1, is_favorite: 1 }],
        (data:any) => {
          data?.pages[0]?.data?.unshift({
            ...item,
            favorite_order_name: order_name,
            is_favorite: true,
          });
          return data;
        }
      );
      queryClient?.setQueryData(["orderHistory", { page: 1 }], (data:any) => {
        const updatedData = data?.pages[0]?.data?.map((orderItem) => {
          if (orderItem.id == item.id)
            return {
              ...orderItem,
              favorite_order_name: order_name,
              is_favorite: true,
            };

          return orderItem;
        });
        data.pages[0].data = updatedData;
        return data;
      });

      scrollToOrChangeToFavoriteSection();

      debouncedAddFavourite({ ...item, order_name: order_name });
    }
  };

  // const {
  //   data: favorites = [],
  //   isFetching: isFavFetching,
  //   refetch,
  // } = useFavorites(authInfo.userId, authInfo.type);

  const hideFavoriteModal = () => {
    setFavoriteModal(false);
  };

  const showFavoriteModal = () => {
    setFavoriteModal(true);
  };

  const showOrderLabelModal = () => {
    setLabelOrderModal(true);
  };

  const decideShowLabelOrDelectionModal = (item) => {
    setSelectedItem(item);

    if (item.is_favorite) {
      showFavoriteModal();
    } else {
      showOrderLabelModal();
    }
  };

  const onAddLabelOrderHandler = (values) => {
    closeLabelOrderModal();
    favoriteButtonHandler(selectedItem, values.orderFavouriteLabel);
  };

  const closeLabelOrderModal = () => {
    setLabelOrderModal(false);
  };

  const checkIsFavourite = () => {
    return (
      data?.pages?.length > 0 &&
      data?.pages[0]?.data?.length &&
      data?.pages[0]?.data?.filter((item) => item.is_favorite)
    )?.length;
  };

  return (
    <>
      <div className={styleClasses.profile_inn_wrap}>
        <div className="mb-0 ">
          <h4 className="font-Cls f-sm-s22 f-s24 text-start h-24 medium_text mb-0">
            {is_favorite_order ? "Favorite Orders " : "Recent Orders"}
          </h4>
        </div>
        <div className={`${styleClasses.profile_content_wrap} `}>
          {data?.pages?.length > 0 && data?.pages[0]?.data?.length > 0 ? (
            <>
              <OrderList
                recentOrders={data?.pages}
                showFavoriteModal={decideShowLabelOrDelectionModal}
                is_favorite_order={is_favorite_order}
              />

              {data?.pages[0].data?.length > 3 && (
                <div className="d-flex justify-content-md-start justify-content-center py-2">
                  <button
                    className="btn btn-custom text_underline font-Visby-cf f-w6 f-s14 clr-dark-blue p-0 ls-normal"
                    onClick={(event: any) => {
                      event.preventDefault();
                      is_favorite_order
                        ? goToRoute(CONSTANT.VIEW_MORE_RECENT_FAVORITE_ORDER, {
                            is_favorite_order: true,
                          })
                        : goToRoute(CONSTANT.VIEW_MORE_RECENT_ORDER);
                    }}
                  >
                    {is_favorite_order ? (
                      checkIsFavourite() ? (
                        "View More Favorite Orders..."
                      ) : (
                        <NoRecentOrder isFavorite={is_favorite_order} />
                      )
                    ) : (
                      "View More Recent Orders..."
                    )}
                  </button>
                </div>
              )}
            </>
          ) : (
            <NoRecentOrder isFavorite={is_favorite_order} />
          )}
        </div>
      </div>
      <hr className="custom_hr_sty  d-none d-md-block" />

      <AddToFavoriteModalConfirmation
        showAddAndRemoveModal={favoriteModal}
        hideAddAndRemoveModal={hideFavoriteModal}
        id={"favoutie-modal"}
        name={"Modal"}
        onRemoveClickHandler={() => {
          hideFavoriteModal();
          favoriteButtonHandler(selectedItem, "");
        }}
      />
      <LabelFavoriteOrder
        showAddLabelModal={labelOrderModal}
        hideLabelModal={closeLabelOrderModal}
        onAddLabelOrderHandler={onAddLabelOrderHandler}
      />
    </>
  );
};
