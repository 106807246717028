import _clonedeep from 'lodash.clonedeep'

export const appliedPaymentMethods = (paymentCards: any[], giftCards: any[]) => {
    return paymentCards.flat().concat(giftCards.flat())
}

export const splitAmount = (checkout: any, totalAmount: number) => {
    const cards = appliedPaymentMethods(checkout.paymentMethods, checkout.giftCard)
    return cards.length >= 1 ? totalAmount/2 : totalAmount
 }

export const removePaymentCard = (addedPaymentCards: any[], cardId: string | number) => {
    const removedPaymentIndex = addedPaymentCards.findIndex((payments) => payments.id == cardId)
    let clonedPayments = _clonedeep(addedPaymentCards)
    clonedPayments.splice(removedPaymentIndex, 1)
    return clonedPayments
}

export const removeGiftCard = (addedGiftCards : any[], cardId: string | number) => {
    const removedCardIndex = addedGiftCards.findIndex((card) => card.id === cardId)
    let clonedCardPayments = _clonedeep(addedGiftCards)
    clonedCardPayments.splice(removedCardIndex, 1)
    return clonedCardPayments
}

export const updatePaymentCardAmounts = (checkout: any, totalAmount: number) => {
    const paymentCards = _clonedeep(checkout.paymentMethods)
    paymentCards.forEach((payment) => payment.amount = splitAmount(checkout, totalAmount))
    return paymentCards
}

export const updateGiftCardAmounts = (checkout: any, totalAmount: number) => {
    const giftCards = _clonedeep(checkout.giftCard)
    giftCards.forEach((payment) => payment.amount = splitAmount(checkout, totalAmount))
    return giftCards
}

export const getSuccessMessage = (isEdit: boolean) => {
    return isEdit ? "Payment Method successfully updated" : "Payment Method successfully added"
}