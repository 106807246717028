import React, { createContext, useReducer, useContext } from "react";
import { LocationReducer, initialState } from "./reducer";

const StoreLocationContext = createContext(undefined);

export const LocationProvider = ({ children }) => {
  const [locationInfo, dispatch] = useReducer(LocationReducer, initialState);
  return (
    <StoreLocationContext.Provider value={{ locationInfo, dispatch }}>
      {children}
    </StoreLocationContext.Provider>
  );
};

export const useStoreLocation = () => {
  const context = useContext(StoreLocationContext);
  if (context === undefined) {
    throw new Error("useStoreLocation must be used within a LocationProvider");
  }
  return context;
};
