import { Field, Formik } from "formik";
import React from "react";
import {CURRENT_USER} from "../../constants";
import { getUser, getVisitorId } from "../../helpers/helperMethods";
import { ContactInfoFormFields } from "../../models/forms.model";
import { contactInfoFormSchema } from "../../validationSchemas/contactInfoFormSchema";
import { FormField } from "../FormFields/FormField";
import { PHONE_NUMBER_MASK } from "../Tabs/PersonalInfo";
import MaskedInput from 'react-text-mask'
import { guestContactInfoFormSchema } from "../../validationSchemas/guestContactInfoFormSchema";


interface IContactInfoForm {
  closeModal?: (boolean) => void;
  userId?: number;
  currentGuestUserInfo?: any;
  isGuest?: boolean
  handleSubmitAsGuest?: () => void;
}

const ContactInfoForm: React.FC<IContactInfoForm> = ({
  closeModal,
  userId,
  currentGuestUserInfo: {first_name, last_name, email, phone},
  isGuest,
  handleSubmitAsGuest
}) => {

  const initialFormState: ContactInfoFormFields = {
    first_name,
    last_name,
    email,
    phone,
  };

  const handleFormSubmission = async (
    values: ContactInfoFormFields,
    { setSubmitting }: any
  ) => {
    let user = getUser()
    user.full_name = values.first_name.concat(' ',values.last_name)
    localStorage.setItem(
          CURRENT_USER,
          JSON.stringify({ ...user, visitor_id: getVisitorId(), isInfo: true, ...values  })
        );
    closeModal(true)
    isGuest && handleSubmitAsGuest()
  };
  return (
    <Formik
      initialValues={initialFormState}
      validationSchema={phone ? contactInfoFormSchema : guestContactInfoFormSchema}
      onSubmit={handleFormSubmission}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        submitForm,
      }) => (
        <form className="new_form_design  w-75 my-0 mx-auto delivery_location_select full_width_mob" onSubmit={handleSubmit}>
          <FormField
            labelText={"First Name"}
            type={"text"}
            name={"first_name"}
            errors={errors}
            touched={touched}
            onChange={handleChange}
            value={values.first_name}
          />
          <FormField
            labelText={"Last Name"}
            type={"text"}
            name={"last_name"}
            errors={errors}
            touched={touched}
            onChange={handleChange}
            value={values.last_name}
          />
          <FormField
            // readonly = {readonly}
            labelText={"Email Address"}
            type={"email"}
            name={"email"}
            errors={errors}
            touched={touched}
            onChange={handleChange}
            value={values.email}
          />
          {
            phone &&
            <div>
              <label htmlFor="phone">
                Phone Number
              </label>
              <Field
                  name="phone"
                  render={({ field }) => (
                      <MaskedInput
                          {...field}
                          mask={PHONE_NUMBER_MASK}
                          id="phone"
                          placeholder="(223) 123-1234"
                          type="text"
                          value={values.phone}
                          onChange={(e) => setFieldValue('phone', e.target.value.replace(/[() -]/g,''))}
                          //setFieldValue('phone',value.replace(/[() -]/g,''))}
                          guide={true}
                          className={`
                          ${errors.phone && touched.phone
                              ? "input_error"
                              : "text-input"}
                            form-control`}
                      />
                  )}
              />
              {errors.phone && touched.phone && (
                  <div className="input-feedback clr-dark-red f-s14 font-rale mt-1 d-inline-block">{errors.phone}</div>
              )}
            </div>
          }
          {/* <FormField
            labelText={"Phone Number"}
            type={"text"}
            name={"phone"}
            errors={errors}
            touched={touched}
            onChange={handleChange}
            value={values.phone}
            placeholder={"e.g. (223) 123-1234"}
          /> */}
          <div className="d-flex justify-content-center">
            <button
              type="submit"
              className="btn btn-large py-2 f-s16 mb-4 mt-3 text-uppercase"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              { isGuest ? "PROCEED AS GUEST" : userId ? "CONFIRM CHANGES" : "CONFIRM CONTACT INFO"}
            </button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default ContactInfoForm;
