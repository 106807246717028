import { useMutation } from "react-query";
import { IDeleteOrderItemPayload } from "../../models/order.model";
import { orderMicroService } from "../../services";

const deleteItem = async (payload: IDeleteOrderItemPayload) => {
    const { item_id, ...rest } = payload
    const response = await orderMicroService.deleteItem(item_id, rest);
    return response.data.data;
};

export default function useDeleteItemFromCart() {
    return useMutation((payload: IDeleteOrderItemPayload) => deleteItem(payload));
}
