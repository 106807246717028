import { number } from "card-validator";

export interface IOrder {
  size: string;
  complimentary_modifier?: any[];
  modifiers: {
    mod_id: number;
    quantity: number;
    sub_with_id?: number;
    section?:number;
    item?:string;
  }[];
  dressing: {
    id: number;
    quantity: number;
  };
}
export interface IReward{
  brink_id: number;
  category_id: number;
  created_at: string;
  customer_id: number;
  deleted_at: string;
  expiry_date: string;
  id: number;
  is_redeemed: number;
  item_id: number;
  name: string;
  order_id: number;
  past_order: any;
  points: number;
  loyalty_id: string;
  size: string;
  updated_at: string;
  used_on: any;
  in_cart?:number;
  is_expired?: boolean;
}
export const orderInitialState: IOrder = {
  size: null,
  modifiers: [],
  dressing: {
    id: 0,
    quantity: 0,
  },
};

export interface Ingredient {
  id: number;
  brink_modifier_id: number;
  title: string;
  price: number;
  image: string;
  calories: number;
  max: number;
  min: number;
  modifier_location: {
    status: string | null;
  }[];
}

export interface OrderTypeOptions {
  value: string;
  label: string;
}

export interface OrderTimeAndDate {
  date: Date;
  slot: string;
}


interface Modifier {
  brink_id: number;
  brink_modifier_group_id: number;
  code: string;
  core: true
  display_price: number;
  modifier_calories: string;
  complimentary_modifier?: boolean;
  modifier_group_id: string;
  modifier_group_min: number;
  modifier_group_max: number;
  modifier_id: number;
  modifier_name: string;
  quantity: number;
  size: string;
  item_size: string;
  modifier_size: string;
  is_item: boolean;
  modifiers?: Modifier[];
  treat_as?: string;
  modifier_type?: string;
}

interface Item {
  brink_id: number;
  category_id: string;
  display_price: number;
  id: number;
  index: number;
  item_id: number;
  item_size: string;
  modifiers: Modifier[];
  name: string;
  price: number;
  quantity: number;
  set_id: number;
  size: string;
  reward_points?: number
}
export interface Payload{
  customer_id?: number;
  visitor_id?: string;
  destination_id: string;
  place_date: Date;
  source: string;
  status: string;
  store_id: string;
  sub_total: number;
  total_price: number;
  wanted_date: Date;
  business_date?: Date;
  location_id: number;
  items?: Item[];
}

export enum ETry2ComboItemsName {
  SANDWICHES = "Sandwiches",
  SALADS = "Salads",
  SOUPS = "Soups",
  BOWLS = "Bowls"
}


export interface IAddToOrderPayload{
  customer_id?: number;
  visitor_id?: string;
  status: string;
  source: string;
  business_date: string;
  total_price: number;
  sub_total: number;
  location_id: number;
  items?: Item[];
}

export interface IUpdateOrderItemPayload{
  customer_id?: number;
  visitor_id?: string;
  location_id: number;
  modifiers?: Modifier[];
}

export interface IUpdateOrderQuantityPayload{
  customer_id?: string;
  visitor_id?: string;
  quantity: number;
  item_id: string;
}

export interface IDeleteOrderItemPayload{
  customer_id?: string;
  visitor_id?: string;
  item_id: string;
}
export interface IUseCoupon{
  promo_code:string,
  location_id?:number,
  order_type?:number
}

export interface IRedeemPointsByOrderId {
  order_id: number;
}

export interface IApplyOfferOnCart {
  user_offer_id: string;
  root_offer_id: string; 
  eligible_item_offer_id?: string; 
}

export interface IGetCartPayload{
  customer_id?: string
  visitor_id?: string
  location_id?: string
  order_type?: number
  persist_offer?: boolean
}

export interface ICartParamsPayload{
  customer_id?: string;
  visitor_id?: string;
}

export interface IGetItemFromCartPayload{
  customer_id?: string
  visitor_id?: string
  id: string;
  location_id: string;
  order_type?: number
}

export interface IUpdateOrderPayload{
  item: IUpdateOrderItemPayload;
  item_id: string;
}

export interface IOrderStatus{
  orderId: string | number;
  trackingType: string;
}

export interface IUpsellClickPayloadForBraze {
  storeId: number, 
  storeName: string, 
  orderType: string, 
  orderPlatform: string, 

  addedItemId: number, 
  addedItemName: string, 
  addedItemSize: string, 
  addedItemCategory: string, 
  cartId: number, 

  upsellName: string, 
  redirectToItemId : number, 
  redirectToItemName : string, 

  redirectToCategoryId : number, 
  redirectToCategoryName : string, 

  order: number ,

  ClickedFrom : string
}