import React from "react";
import {
  CATERING_CAFE_ZUPAS_DEV_URL,
  CATERING_CAFE_ZUPAS_URL,
  DELIVERY_ORDER_TYPE,
} from "../../constants";
import { useAppSelector } from "../../redux/store/store";
import styles from "./HeaderTop.module.scss";

interface HeaderTopProps {
  locationLabel?: any;
  handleLocationButton?: () => void;
  userType?: any;
  handleShowLiveTracker?: () => void;
  shouldShowLiveTracker?: boolean;
}
const HeaderTop: React.FC<HeaderTopProps> = ({
  locationLabel,
  handleLocationButton,
}) => {
  const Cart = useAppSelector((state) => state.cart);

  const getBaseUrl = () => {
    return process.env.REACT_APP_ENV === "production"
      ? CATERING_CAFE_ZUPAS_URL
      : CATERING_CAFE_ZUPAS_DEV_URL;
  };

  return (
    <div className={`${styles.topHeaderWrapper}`}>
      <div className="w-100">
        <div className="d-flex align-items-center mt-1 mt-md-0 justify-content-md-start justify-content-between w-100">
          <div className={styles.switcher}>
            <a href={getBaseUrl()} className={styles.switcherLink}>
              {" "}
              CATERING SITE
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="32"
                viewBox="0 0 33 32"
                fill="none"
              >
                <g clipPath="url(#clip0_7565_158)">
                  <path
                    d="M7.33331 16H26"
                    stroke={"#198000"}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M20.6666 21.3333L26 16"
                    stroke={"#198000"}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M20.6666 10.6667L26 16.0001"
                    stroke={"#198000"}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_7565_158">
                    <rect
                      width="32"
                      height="32"
                      fill="white"
                      transform="translate(0.666626)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </a>
          </div>
         
           <div className="d-md-none ">
              <button
                type="button"
                className={`${
                  styles.locationNavSection +
                  " " +
                  (Cart.orderType === DELIVERY_ORDER_TYPE
                    ? styles.online_location_icon
                    : "")
                } d-flex p-0 justify-content-between ms-auto align-items-center`}
                onClick={handleLocationButton}>
                {locationLabel()}
              </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderTop;
