import * as Yup from 'yup';

export const addContactUsFormValidationSchema = Yup.object().shape({
        first_name: Yup.string().required('Required'),
        last_name : Yup.string().required('Required'),
        your_notes: Yup.string().required('Required'),
        location_id : Yup.string().required('Required'),
        email: Yup.string()
              // .email("Enter a valid email")
              .required("Email/Phone Number is required")
              .test('test-name', 'Enter Valid Phone/Email',
                function(value) {
                  const emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

                  const phoneRegex = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/; // Change this regex based on requirement
                  // const phoneRegex = /^(\+91-|\+91|0)?\d{10}$/; // Change this regex based on requirement
                  let isValidEmail = emailRegex.test(value);
                  let isValidPhone = phoneRegex.test(value);
                  if (!isValidEmail && !isValidPhone ){
                    return false;
                  }
                  return true;
              }),
              radio : Yup.string().required('Required'),
});

export const emailSubsFormValidationSchema = Yup.object().shape({
        email: Yup.string().email().required('Email is required')
});

export const complimentAndConcernFormValidationSchema = Yup.object().shape({
        feedback: Yup.string().required('Required'),
        location_id: Yup.string().required('Required'),
        team_member: Yup.string().required('Required')
});
