import React, { createContext, useState, useEffect, ReactNode } from 'react';
import CampaignTrigger from '../hooks/useCampaignTrigger';

interface CampaignTriggerContextType {
  campaignTriggerData: any;
  campaignTriggerLoading: boolean;
}

export const CampaignTriggerContext = createContext<CampaignTriggerContextType | undefined>(undefined);

interface CampaignTriggerProviderProps {
  children: ReactNode;
}

export const CampaignTriggerProvider = ({ children }: CampaignTriggerProviderProps) => {
  const [campaignTriggerLoading, setCampaignTriggerLoading] = useState(true);
  const { data: campaignTriggerData, loading } = CampaignTrigger();

  useEffect(() => {
    setCampaignTriggerLoading(loading);
  }, [loading]);

  return (
    <CampaignTriggerContext.Provider value={{ campaignTriggerData, campaignTriggerLoading }}>
      {children}
    </CampaignTriggerContext.Provider>
  );
};
