import { useMutation } from "react-query";
import { orderMicroService } from "../services";

const reorder = async (orderId: number) => {
  const response = await orderMicroService.reOrder(orderId)
  return response.data.data;
};

export default function useReorder() {
  return useMutation((orderId: number) =>
  reorder(orderId)
  );
}
