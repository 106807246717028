import { Formik } from 'formik';
import React ,{useState} from 'react'
import { Col, Row } from 'react-bootstrap';
import { EDIT_ADDRESS_MODAL_TITLE } from '../../../constants';
import styleClasses from "../../../containers/account/accounts.module.scss";
import { Toast_Func } from '../../../helpers/toast.helper';
import { personalInfoSchema } from '../../../validationSchemas/personalInfoSchema';
import { FormField } from '../../FormFields/FormField';
import DefaultMethodConfirmationModal from '../DefaultMethodConfirmation/DefaultMethodConfirmation';
const DeliveryAdressDesktop = ({address,defaultAddress,handleChangeDefault,setRemoveDelivery,setRemoveAddressId,showModal, addressCount}) => {
  const [confirmDefaultMethodSelection, setConfirmDefaultMethodSelection] = useState(false);
    const initialFormState = {
        address_name:address.address_name,
        full_address: address.full_address,
        is_default: address.is_default,
        optional_address_details: address.apartment_no || ""
      };
    const handleFormSubmission = async () => {
        console.log("🚀 ~ Submit");
        //TODO: API Integration
      };
  return (
    <div>
          <div className="d-none d-md-block">
            <div className="d-flex gap-3 pl-2">
                {
                    addressCount > 1 &&
                    <div className="position-relative">
                <span className={`${styleClasses.delivery_select_radio_button} ${styleClasses.select_radio_button}`}
                >
                  <input
                      type="radio"
                      id={address.id}
                      name={address.id + 'desktopRadio'}
                      value={address.id}
                      checked={address.id === defaultAddress?.id}
                      onChange={()=> {}}
                      onClick={()=>{setConfirmDefaultMethodSelection(true)}}
                  />
                </span>
                    </div>
                }
              <div id={address.id} className="w-100">
                <Formik
                  initialValues={initialFormState}
                  validationSchema={personalInfoSchema}
                  onSubmit={handleFormSubmission}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    submitForm,
                  }) => (
                    <form className="new_form_design profile-info-read-only-field" onSubmit={handleSubmit}>
                      <Row>
                        <Col md={5} sm={12}>
                          <FormField
                            labelText={"Name Your Saved Address"}
                            name="address_name"
                            type="text"
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            value={values.address_name}
                            readonly
                          />
                        </Col>

                        <Col md={5} sm={12}>
                          <FormField
                            labelText={"Address"}
                            name="full_address"
                            type="text"
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            value={values.full_address}
                            readonly
                          />
                        </Col>
                        <Col
                          md={2}
                          sm={12}
                          className="d-flex flex-column align-items-start justify-content-center"
                        >
                          <div className={`${styleClasses.edit_remove_btn} d-flex flex-column justify-content-center`}>
                            <button
                              onClick={() => {
                                if(!address.is_default){
                                  setRemoveDelivery(true);
                                  setRemoveAddressId(address.id);
                                } else{
                                  Toast_Func({
                                    status:false,
                                    message:'Default cannot be deleted'
                                  })
                                }
                              }}
                              className="btn btn-custom f-s14 font-Visby-cf color-sky-blue-light ms-lg-3 p-0"
                            >
                              <img
                                src={require("../../../../src/images/Remove.svg")}
                                alt="Remove icon"
                              />{" "}
                              Remove
                            </button>
                            <button
                               onClick={() =>
                                 showModal(
                                  EDIT_ADDRESS_MODAL_TITLE,
                                   address.id
                                 )
                               }
                              className="btn btn-custom f-s14 font-Visby-cf color-sky-blue-light ms-3 p-0 mt-2"
                            >
                              <img
                                src={require("../../../../src/images/Edit.svg")}
                                alt="Edit icon"
                              />{" "}
                              Edit
                            </button>
                          </div>
                        </Col>
                          <Col md={5} sm={12}>
                              <FormField
                                  labelText={"APT, FLOOR, SUITE (OPTIONAL)"}
                                  name="optional_address_details"
                                  value={values.optional_address_details}
                                  type="text"
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  placeholder="Apt, Floor, Suite, Building, Company"
                                  readonly
                                  maxLength={100}
                              />
                          </Col>
                      </Row>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>

          <DefaultMethodConfirmationModal
        show={confirmDefaultMethodSelection}
        title="Change Default Delivery Address"
        content="You’re requesting to change the default delivery address. Would you like to continue?"
        btnText={"Change"}
        handleClick={(e) => {
          handleChangeDefault(address.id)
        }}
        handleClose={() => {
          setConfirmDefaultMethodSelection(false);
        }}
      />
    </div>
  )
}

export default DeliveryAdressDesktop