import React, { useEffect, useState } from "react";
import { CustomModal } from "../../Modal/CustomModal";
import { Toast_Func } from "../../../helpers/toast.helper";
import StoredGiftCardsListing from "./StoredGiftCardsListing";
import { useQueryClient } from "react-query";
import GIftCardForm from "../../Forms/GIftCardForm";
import useUpdateGiftCard from "../../../react-query-hooks/useUpdateGiftCard";
import { IGiftCard } from "../../../models/giftCard.model";
import GiftCardLookUpForm from "../../Forms/GiftCardLookupForm";
import { Modal } from "react-bootstrap";
import DefaultMethodConfirmationModal from "../DefaultMethodConfirmation/DefaultMethodConfirmation";
import AddGiftCardLookUpConfirmationModal from "./AddGiftCardAfterLookup/addGiftCardAfterLookupModal";
import useAddGiftCard from "../../../react-query-hooks/useAddGiftCard";

interface StoredPaymentsProps {
  giftCardRecords: IGiftCard[];
}

const StoredGiftCards: React.FunctionComponent<StoredPaymentsProps> = ({
  giftCardRecords,
}) => {
  const { mutateAsync: updateGiftCard } = useUpdateGiftCard();
  const [openPaymentModal, setOpenPaymentModal] = useState<boolean>(false);
  const [openLookUpModal, setOpenLookUpModal] = useState<boolean>(false);
  const [openAddLookUpGiftCard, setOpenAddLookUpGiftCard] = useState(false);
  const [giftCardNo, setGiftCardNo] = useState("");
  const [editRecord, setEditRecord] = useState<IGiftCard>(null);
  let queryClient = useQueryClient();
  const [defaultPayment, setDefaultPayment] = useState<IGiftCard>(null);
  const handleEdit = (record: IGiftCard) => {
    setEditRecord(record);
    setOpenPaymentModal(true);
  };
  const closeModal = () => {
    setOpenPaymentModal(false);
  };
  const [closeConfirmationModal, setCloseConfirmationModal] = useState(false);
  const [balance, setBalance] = useState("");
  useEffect(() => {
    if (!defaultPayment) {
      setDefaultPayment(giftCardRecords.find((rec) => rec.is_default));
    }
  }, [giftCardRecords]);
  useEffect(() => {
    !openPaymentModal && editRecord && setEditRecord(null);
  }, [openPaymentModal]);
  const handleChangeDefault = async (id: string) => {
    const changedRecord = giftCardRecords.find((rec) => rec.id === id);
    setDefaultPayment(changedRecord);
    return updateGiftCard(
      {
        data: { is_default: true },
        recordID: id,
      },
      {
        onSuccess: async (data) => {
          setDefaultPayment({ ...changedRecord, is_default: true });
          queryClient.invalidateQueries(["giftCards"]);
          queryClient.invalidateQueries(["profile"]);
          setCloseConfirmationModal(true);
          Toast_Func({ status: true, message: "Default updated successfully" });
        },
        onError: (error) => {
          Toast_Func({ status: false, message: error });
          setDefaultPayment(giftCardRecords.find((rec) => rec.is_default));
        },
      }
    );
  };

  let { mutateAsync: addGiftCard, isLoading } = useAddGiftCard();

  const handleSuccess = async (data: any) => {
    queryClient.refetchQueries("giftCards");
    queryClient.invalidateQueries(["profile"]);
    setGiftCardNo("");
    setOpenAddLookUpGiftCard(false);
  };

  const handleAddGiftCard = async () => {
    const updatedValues: any = {
      gift_card_no: giftCardNo.trim(),
      is_default: false,
      is_saved: true,
      custom_name: true,
    };
    await addGiftCard(updatedValues, {
      onSuccess: (data) => {
        handleSuccess(data);
        Toast_Func({ status: true, message: "Gift Card added Successfully." });
      },
      onError: (error: any) => {
        Toast_Func({ status: false, message: error?.response?.data?.message });
      },
    });
  };
  useEffect(() => {
    if (!openAddLookUpGiftCard && openLookUpModal) setGiftCardNo("");
  }, [openAddLookUpGiftCard, openLookUpModal]);
  return (
    <>
      <StoredGiftCardsListing
        closeConfirmationModal={closeConfirmationModal}
        giftCardRecords={giftCardRecords}
        defaultPayment={defaultPayment}
        handleChangeDefault={handleChangeDefault}
        handleEdit={handleEdit}
        setOpenPaymentModal={setOpenPaymentModal}
        setOpenLookUpModal={setOpenLookUpModal}
      />
      <CustomModal
        showModal={openPaymentModal}
        closeModal={() => setOpenPaymentModal(false)}
        title={`Gift Card`}
      >
        <GIftCardForm
          isCheckout={false}
          handleCloseGiftCard={() => setOpenPaymentModal(false)}
          editGiftCard={editRecord}
        />
      </CustomModal>
      <Modal
        show={openLookUpModal}
        centered
        className="theme-modal-style app_container_wrap max_width_567 border-radius-15 px-5 "
        onHide={() => setOpenLookUpModal(false)}
        size="lg"
      >
        <Modal.Header className="x-4">
          <button
            type="button"
            className="btn modal-close-button pe-0"
            onClick={() => setOpenLookUpModal(false)}
          >
            Close
          </button>
          {/* <h5 className="f-s22 font-Cls text-start ps-4 pt-4 mt-2 mb-0 text-capitalize w-75 full_width_mob">
            GIFT CARD LOOKUP
          </h5> */}
        </Modal.Header>
        <Modal.Body className={`px-lg-5 pt-0`}>
          <GiftCardLookUpForm
            handleCloseGiftCard={() => setOpenLookUpModal(false)}
            setOpenAddLookUpGiftCard={setOpenAddLookUpGiftCard}
            setBalance={setBalance}
            setGiftCardNo={setGiftCardNo}
            giftCardNo={giftCardNo}
          />
        </Modal.Body>
      </Modal>
      <AddGiftCardLookUpConfirmationModal
        show={openAddLookUpGiftCard}
        title={`$${balance}`}
        content="Available Balance"
        btnText={"ADD GIFT CARD"}
        handleClick={handleAddGiftCard}
        handleClose={() => {
          setGiftCardNo("");
          setOpenAddLookUpGiftCard(false);
        }}
        shouldDisableButton={isLoading}
      />
    </>
  );
};

export default StoredGiftCards;
