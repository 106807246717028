import React from "react";
import { REACT_APP_CLOUD_FRONT } from "../../constants";
import styleClasses from "./liveTracking.module.scss";

const StoreAddress: React.FC<{
  storeDetails: {
    image: string;
    name: string;
    address: string;
    city: string;
    state: string;
    zip: string;
  };
}> = ({ storeDetails: { image, name, address, city, state, zip } }) => {
  const googleMapsLink = `https://maps.google.com/maps?q=Cafe+Zupas,+${state},+${city},+${address}`;

  return (
    <>
      <div>
        <h2 className="font-Cls f-s16 f-sm-only-s20 cz-dark-gray fw-normal mb-0 text-start">
          Pickup Details
        </h2>
        <h3 className="f-s16 f-sm-only-s18 clr-grey fw-normal mb-0 text-start">{name}</h3>
      </div>
      <div className="d-flex align-items-center mt-3">
        <img
          src={`${REACT_APP_CLOUD_FRONT}${image}`}
          className={`${styleClasses.pickup_location_img}`}
          alt="pickup location"
        />
        <p
          className={`${styleClasses.location_address} ps-2 text-decoration-underline mb-0 text-style2 text-start`}
        >
          <a
            href={googleMapsLink}
            target="_blank"
            className={` text-style2 text_underline`}
          >
            {address} {city}, {state} {zip}
          </a>
        </p>
      </div>
    </>
  );
};

export default StoreAddress;
