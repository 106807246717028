import { useQuery } from "react-query";
import { SEASONAL } from "../constants";
import { locationMenuService } from "../services";

const removeSeasonal = (menu) => {
    const menuData = menu;
    const seasonalIndex = menuData.findIndex(item => item.name.toLowerCase() === SEASONAL.toLowerCase())
    if( seasonalIndex >= 0 ) menuData.splice(seasonalIndex,1)
    return menuData
}

const getMenuCategories = async () => {
    const response = await locationMenuService.getMenuCategories()
    let menuData = response.data.data;
    return process.env.REACT_APP_SHOW_SEASONAL_IN_HOME_PAGE ? menuData : removeSeasonal(menuData)
};

export default function useGetMenuCategories() {

    return useQuery<any>("menu-categories",
        () => getMenuCategories(),
        {
            staleTime: 1000 * 60 * 60,
        }
    );
}