import { useQuery } from "react-query";
import axios from "axios";
import { GET_ORDER_DETAILS_REFETCH_INTERVAL, ORDER_MICRO_SERIVCE } from "../constants";

interface IQueryParams {
  orderId: number;
  isFetchingOrderHistory?: boolean; 
}

const getOrderDetails = async ({
  orderId,
}: IQueryParams) => {
  const response = await axios.get(
    `${ORDER_MICRO_SERIVCE}/orders/${orderId}?details=payment,store,pickup,tracking,am_here`
  );
  return response?.data?.data
};

export default function useOrderDetails({ orderId, isFetchingOrderHistory }: IQueryParams) {
  return useQuery(["orderdetails", orderId], () =>
  getOrderDetails({ orderId }), {
      enabled: !isFetchingOrderHistory && !!orderId,
      refetchInterval: Number(GET_ORDER_DETAILS_REFETCH_INTERVAL)
    }
  );
}
