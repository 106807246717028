import {
  GUEST_USER,
  KIOSK_LOGIN_FAILED_MESSAGE,
  KIOSK_LOGIN_SUCCESS_MESSAGE,
  ROUTE_LOGIN,
  ROUTE_MENU,
} from '../constants';
import { Toast_Func } from '../helpers/toast.helper';
import { useHistory } from 'react-router';
import { customerBusinessService } from '../services';
import { getUser } from '../helpers/helperMethods';

export default function useKioskLogin() {
  const history = useHistory();

  const kioskLogin = async ({ kiosk_id, screen_timeout }) => {
    const authUser = getUser();
    const deep_link = await getDeepLink({kiosk_id});
    if (authUser.type === GUEST_USER) {
      handleGuestUserKioskLogin({kiosk_id, screen_timeout, deep_link});
      return;
    }else{
      try {
        const response = await login({link:deep_link});
      if (response.data.status) {
        history.push({
          pathname: ROUTE_MENU,
          state: {
            isKioskLoginFlow: true,
            status: true,
            message: KIOSK_LOGIN_SUCCESS_MESSAGE
          },
        })
      }else{
        history.push({
          pathname: ROUTE_MENU,
          state: {
            isKioskLoginFlow: true,
            status: false,
            message: KIOSK_LOGIN_FAILED_MESSAGE
          },
        })
      }
      } catch (error) {
        history.push({
          pathname: ROUTE_MENU,
          state: {
            isKioskLoginFlow: true,
            status: false,
            message: KIOSK_LOGIN_FAILED_MESSAGE
          },
        })
      }
    }
  };

  const login = async ({link}) => {
    const authUser = getUser();
    const useKioskLogin = {
        kiosk_deep_link: link,
        loyalty_user_id: authUser.loyalty_user_id,
        is_guest: 0,
      };
      try {
        const response = await customerBusinessService.kioskLogin(useKioskLogin);
        return response;
      } catch (error) {
        return error
      }
  }

  const getDeepLink = async ({kiosk_id}) => {
    try {
      const response = await customerBusinessService.kioskSetting(kiosk_id);
      if (response?.data?.status) {
      return response?.data?.data.deep_link;
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleGuestUserKioskLogin = ({kiosk_id, screen_timeout, deep_link}) => { 
    Toast_Func({ status: false, message: 'You need to login first' });
    history.push(ROUTE_LOGIN);
    const kioskLoginSession = {
      date: new Date(),
      link: deep_link,
      kiosk_id,
      timeout: screen_timeout
    };
    sessionStorage.setItem('kl_session', JSON.stringify(kioskLoginSession));
  };

  const removeSession = () => {
    sessionStorage.removeItem('kl_session')
  }

  return { kioskLogin, kioskLoginAuthenticatedUser: login, removeKioskLoginSession:removeSession  };
}
