import { useQuery } from "react-query";
import { GET_ORDER_STATUS_REFETCH_INTERVAL } from "../constants";
import { IOrderStatus } from "../models/order.model";
import { orderMicroService } from "../services";

const getOrderStatus = async ({
    trackingType, orderId
}: IOrderStatus) => {
  const response = await orderMicroService.getOrderStatus( {orderId, trackingType } );
  return response?.data?.data
};

export default function useGetOrderStatus({ orderId, trackingType }: IOrderStatus) {
  return useQuery(["orderStatus", trackingType], () =>
  getOrderStatus({ trackingType, orderId }), {
      enabled: !!trackingType,
      refetchInterval: Number(GET_ORDER_STATUS_REFETCH_INTERVAL)
    }
  );
}
