import React, { useState } from "react";
import { EDIT_VEHICLE_MODAL_TITLE } from "../../../constants";
import styleClasses from "../../../containers/account/accounts.module.scss";
import { Toast_Func } from "../../../helpers/toast.helper";
import DefaultMethodConfirmationModal from "../DefaultMethodConfirmation/DefaultMethodConfirmation";

export default function VehicleCardMobile({handleVehicleRemove ,vehicle,handleFormSubmission,showModal,setRemoveVehicle,setRemoveVehicleId,handleChangeDefault,defaultVehicle, vehicleCount}) {
  const [confirmDefaultMethodSelection, setConfirmDefaultMethodSelection] = useState(false);
  return (
    <div className="d-md-none">
      <div className="d-flex gap-3 pl-2">
        {
          vehicleCount > 1 &&
            <div className="position-relative">
          <span className={styleClasses.select_radio_button}>
          <input
              type="radio"
              id={vehicle.id}
              name={vehicle.id + 'mobileRadio'}
              value={vehicle.id}
              checked={vehicle.id === defaultVehicle?.id}
              onClick={() => {
                setConfirmDefaultMethodSelection(true);
              }}

          />
          </span>
            </div>
        }
        <label htmlFor={`${vehicle.id} vehicleInfo`} className="w-100">
          <div className="d-flex align-items-start justify-content-between mb-4 d-md-none w-100">
            <span className="d-inline-block">
              <span className={`${styleClasses.info_label} d-block mb-1`}>
                STORED VEHICLE {vehicle.is_default ? "(Default)" : null}
              </span>
              <div className={styleClasses.info_text}>{vehicle.make}</div>
              <div className={styleClasses.info_text}>{vehicle.model}</div>
              <div className={styleClasses.info_text}>{vehicle.color}</div>
            </span>
              <div>
                <button
                  type="button"
                  className="btn btn-custom f-s16 text_underline clr-text-grey ms-3 p-0"
                  onClick={() => {
                    if(!vehicle.is_default){
                      handleVehicleRemove(vehicle?.id);
                      setRemoveVehicleId(vehicle.id);
                    } else{
                      Toast_Func({
                        status:false,
                        message:'Default cannot be deleted'
                      })
                    }
                    
                  }}
                >
                  <img src={require("../../../../src/images/Remove.svg")} alt="remove" />{" "}
                </button>
                <button
                  type="button"
                  className="btn btn-custom f-s16 text_underline clr-text-grey ms-3 p-0"
                  onClick={() => showModal(EDIT_VEHICLE_MODAL_TITLE, vehicle.id)}
                >
                  <img src={require("../../../../src/images/Edit.svg")} alt="edit"/>{" "}
                </button>
              </div>
          </div>
        </label>
      </div>
      <DefaultMethodConfirmationModal
        show={confirmDefaultMethodSelection}
        title="Change Default Vehicle info"
        content="You’re requesting to change the default vehicle info. Would you like to continue?"
        btnText={"Change"}
        handleClick={(e) => {
          handleChangeDefault(vehicle.id)
        }}
        handleClose={() => {
          setConfirmDefaultMethodSelection(false);
        }}
      />
    </div>
  );
}
