import React, { useState } from "react";
import Button from "../Buttons/Button";
import { Toast_Func } from "../../helpers/toast.helper";
import useLookUpGiftCard from "../../react-query-hooks/useLookUpGiftCard";

interface IGiftCardForm {
  handleCloseGiftCard?: () => void;
  setOpenAddLookUpGiftCard?: (val: boolean) => void;
  setBalance?: (val: string) => void;
  setGiftCardNo: (val: string) => void;
  giftCardNo: string;
}

const GiftCardLookUpForm = (props: IGiftCardForm) => {
  const {
    handleCloseGiftCard,
    setOpenAddLookUpGiftCard,
    setBalance,
    setGiftCardNo,
    giftCardNo,
  } = props;
  const { isFetching, refetch: lookUpGiftCard } = useLookUpGiftCard(
    giftCardNo?.trim()
  );

  const checkGiftCard = async () => {
    const giftCardData = await lookUpGiftCard();
    if (giftCardData.data.status) {
      setOpenAddLookUpGiftCard(true);
      setBalance?.(giftCardData.data?.data?.balance || "0");
      handleCloseGiftCard?.();
      return;
    }
    if (!giftCardData?.data?.status) {
      Toast_Func({ status: false, message: giftCardData?.data?.message });
      return;
    }
  };
  return (
    <>
      <div
        className={`new_form_design my-0 mx-auto delivery_location_select full_width_mob form-group`}
      >
        <label>GIFT CARD NUMBER</label>

        <input
          name="gift_card_no"
          type="text"
          className={"form-control"}
          onChange={(e) => setGiftCardNo(e.target.value)}
          value={giftCardNo}
        />
      </div>
      <div className="d-flex justify-content-center my-5">
        <Button
          type="button"
          className="btn btn-large py-2 f-s16 w-100"
          id={"add-gift-card-button"}
          onClick={() => checkGiftCard()}
          loading={isFetching}
          disabled={!giftCardNo.length || isFetching}
        >
          CHECK BALANCE
        </Button>
      </div>
    </>
  );
};
export default GiftCardLookUpForm;
