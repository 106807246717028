import React from "react";
import styleClasses from "./Fundraising.module.scss";
import { Container, Row, Col } from "react-bootstrap";
import { forgotPasswordSchema } from "../../validationSchemas/forgotPasswordSchema";
import { FormField } from "../FormFields/FormField";
import { Formik } from "formik";
import { ForgotPasswordFormFields } from "../../models/forms.model";

interface HandleSubmission {
  handleFormSubmission: (
    values: ForgotPasswordFormFields,
    { setSubmitting }
  ) => void;
  invalidEmailMessage: string;
  handleInvalidEmailMessage: () => void;
}

const ForgotPasswordForm = (props: HandleSubmission) => {
  const initialFormState: ForgotPasswordFormFields = {
    email: "",
  };

  const { invalidEmailMessage, handleInvalidEmailMessage} = props;
  return (
    <Formik
      onSubmit={props.handleFormSubmission}
      initialValues={initialFormState}
      validationSchema={forgotPasswordSchema}
    >
      {({
        isSubmitting,
        errors,
        touched,
        handleChange,
        handleSubmit,
        submitForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <FormField
            labelText={"EMAIL ADDRESS"}
            name="email"
            type="email"
            onChange={(e) => {
              handleInvalidEmailMessage();
              handleChange(e);
            }}
            errors={errors}
            touched={touched}
            inputFieldClass={invalidEmailMessage ? "input_error" : ""}
          />
          <span className="clr-dark-red f-s16 font-rale">
            {invalidEmailMessage}
          </span>
          <hr className="my-4 d-none d-md-block" />
          <div className="max-content mx-auto pt-4">
            <button
              type="submit"
              id="reset-password-button"
              className={`btn btn-large d-block ${
                invalidEmailMessage || isSubmitting ? "dark-grey" : ""
              }`}
              disabled={isSubmitting || Boolean(invalidEmailMessage)}
              onClick={ (event: any) => {
                event.preventDefault()
                submitForm()
              }}
            >
              reset password
            </button>
          </div>
        </form>
      )}
    </Formik>
  );
};
export default ForgotPasswordForm;
