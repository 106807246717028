import { CURBSIDE, DELIVERY, GOOD_LIFE_LANE, IN_STORE } from "../../../constants";


export const handleOrderType = (type: string) => {
  switch (type) {
    case CURBSIDE:
      return {
        image: require("../../../images/OrderTracking/orderTypeCurbside.svg"),
        text: 'Available for',
      };

    case IN_STORE:
      return {
        image: require("../../../images/OrderTracking/orderTypeInStore.svg"),
        text: 'Available for',
      };

    case GOOD_LIFE_LANE:
      return {
        image: require("../../../images/OrderTracking/orderTypeGoodLifeLane.svg"),
        text: 'Available at the',
      };

    case DELIVERY:
      return {
        image: require("../../../images/OrderTracking/orderTypeDelivery.svg"),
        text: 'Scheduled for',
      };
  }
};



export const getOrderDateTime = (inputDate: any, isAvailableModule = true) => {
  if (inputDate) {
    const dateTime = inputDate.split(' ');
    let dateAndTime = '';

    const date = new Date(inputDate);
    const weekday = date.toLocaleDateString('default', {
      weekday: 'long',
    })
    dateAndTime =
      dateShortFormat(dateTime[0]) + `${isAvailableModule ? ' AT ' : ' @ '}` + formatTime(dateTime[1]);

    if (!isAvailableModule) dateAndTime = weekday + " " + dateAndTime

    if (dateTime[0] === isToday())
      dateAndTime = 'Today, ' + dateAndTime;

    return dateAndTime;
  } else {
    return '';
  }
};

export const formatTime = (timeString: any) => {
  const [hourString, minute] = timeString.split(':');
  const hour = +hourString % 24;
  return (hour % 12 || 12) + ':' + minute + (hour < 12 ? ' AM' : ' PM');
};

const isToday = () => {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  const yyyy = today.getFullYear();

  return yyyy + '-' + mm + '-' + dd;
  
};

export const dateShortFormat = (date: string): string => {
  const months = [
    'Jan',
    'Feb',
    'March',
    'April',
    'May',
    'June',
    'July',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  let dateSplit = date ? date.split('-') : ['2023', '01', '01']
  let selectedDate = new Date(Number(dateSplit[0]), Number(dateSplit[1]) - 1, Number(dateSplit[2]));
  let newDate = new Date(selectedDate),
    month = months[newDate.getMonth()],
    day = '' + newDate.getDate();
  return [month, day].join(' ');
};