import React from "react";
import { Row, Col } from "react-bootstrap";
import styleClasses from "./thankyou.module.scss";
import {
  ACTIVE,
  ASSEMBLED,
  COMPLETE,
  DELIVERY,
  PREPARED,
} from "../../constants";

interface iStepperProps {
  orderTypeImage?: string;
  pickUpMethod?: string;
  orderStatus: string;
  StepperCircle?: any;
  TitleHide?: any;
  Bottomspace?: any;
  imgSize?: string;
  stepper_line?: string;
  isOrderDelivered: boolean;
  isRenderedInLiveTracker: boolean;
  isPublic?: boolean;
}

const Steppers: React.FunctionComponent<iStepperProps> = ({
  StepperCircle,
  TitleHide,
  orderTypeImage,
  pickUpMethod,
  orderStatus,
  Bottomspace,
  imgSize,
  stepper_line,
  isOrderDelivered,
  isRenderedInLiveTracker,
  isPublic = false,
}) => {
  const orderPreparedStatus =
    orderStatus === ACTIVE ||
    orderStatus === PREPARED ||
    orderStatus === ASSEMBLED ||
    orderStatus === COMPLETE
      ? true
      : false;

  const orderPackagedStatus =
    orderStatus === ASSEMBLED || orderStatus === COMPLETE ? true : false;

  const orderCompletionStatus = orderStatus === COMPLETE;

  let orderStatusMessage = (
    <p className={`${TitleHide} f-sm-s14`}>
      {" "}
      Your Order is Ready <br />
      to Pick-Up
    </p>
  );
  if (pickUpMethod === DELIVERY)
    orderStatusMessage = (
      <p className={`${TitleHide} f-sm-s14`}>
        Your Order is On <br />
        The Way
      </p>
    );

  return (
    <Row>
      <Col>
        {isOrderDelivered ? (
          <div className={`${styleClasses.steppers} pb-1 pt-2`}>
            <ul
              className={`d-flex ${
                isRenderedInLiveTracker
                  ? "justify-content-between"
                  : "justify-content-center"
              } ps-0 pe-3 mb-0`}
            >
              <li className={isRenderedInLiveTracker ? "d-flex align-items-center gap-2" : ''}>
                <div>
                <div
                  className={`bg-clr-dark-blue ${StepperCircle} ${isPublic ? styleClasses.border_line_public_tracking :styleClasses.border_line} ${stepper_line} rounded-circle mx-auto mb-2`}
                >
                  <img
                    src={require("../../images/OrderTracking/Made.svg")}
                    alt="Made"
                    className={`${styleClasses.steppers_img_size}`}
                  />
                </div>
                </div>
                
                {isRenderedInLiveTracker && (
                    <p className="f-s14 mb-0 font-rale">
                      Your order has been delivered.
                    </p>
                  )}
              </li>
            </ul>
          </div>
        ) : (
          <div className={`${styleClasses.steppers} ${Bottomspace} pt-4`}>
            <ul className="d-flex justify-content-between ps-0 pe-3">
              <li>
                <div
                  className={` ${
                    orderPreparedStatus
                      ? "bg-clr-dark-blue"
                      : styleClasses.list_bg_color
                  } ${StepperCircle} ${
                    isPublic ? styleClasses.border_line_public_tracking :
                    styleClasses.border_line
                  } ${stepper_line} rounded-circle mx-auto mb-2`}
                >
                  <img
                    src={require("../../images/OrderTracking/Preparing.svg")}
                    alt="Preparing"
                    className={`${imgSize}`}
                  />
                </div>
                <p className={`${TitleHide} f-sm-s14`}>
                  Your Order is Being <br /> Prepared
                </p>
              </li>
              <li>
                <div
                  className={` ${
                    orderPackagedStatus
                      ? "bg-clr-dark-blue"
                      : styleClasses.list_bg_color
                  } ${StepperCircle} ${
                    isPublic ? styleClasses.border_line_public_tracking :
                    styleClasses.border_line
                  } ${stepper_line} rounded-circle mx-auto mb-2`}
                >
                  <img
                    src={require("../../images/OrderTracking/Made.svg")}
                    alt="Made"
                    className={`${imgSize}`}
                  />
                </div>
                <p className={`${TitleHide} f-sm-s14`}>
                  Your Order is Being <br /> Packaged
                </p>
              </li>

              <li>
                <div
                  className={`${
                    orderCompletionStatus
                      ? "bg-clr-dark-blue"
                      : styleClasses.list_bg_color
                  } ${StepperCircle} ${
                    isPublic ? styleClasses.border_line_public_tracking :
                    styleClasses.border_line
                  } ${stepper_line} rounded-circle mx-auto mb-2`}
                >
                  <img
                    src={
                      orderTypeImage ||
                      require("../../images/OrderTracking/packaged.svg")
                    }
                    className={`${imgSize}`}
                    alt="packaged"
                  />
                </div>
                {orderStatusMessage}
              </li>
            </ul>
          </div>
        )}
      </Col>
    </Row>
  );
};

export default Steppers;
