import React, { useState } from "react";
import { useHistory, useLocation } from "react-router";
import {
  ROUTE_PROFILE_DETAILS,
  SCROLL_TO_PROFILE_FAV_ITEMS,
} from "../../constants";
import {
  scrollToElementByRef,
  shouldRelease,
} from "../../helpers/helperMethods";
import { Link } from "react-router-dom";
import ScanModal from "./ScanModal";
import {
  FabCloseIcon,
  FabIcon,
  FavoriteIcon,
  LocationPinIcon,
  RecentOrdersIcon,
} from "../../images/svgIcons/svg";
interface Props {
  isRewardCatalogLoading?: any;
  isRewardCatalogSuccess?: any;
  isRewardCatalogError?: any;
  rewardCatalogError?: any;
  rewardCatalogData?: any;
  scrollToDivRef?: any;
  showMakeItTryToCombo?: any;
  isMakeitBtnShowing?: any;
  isBtnStyles?: boolean;
}

export default function UserSelections(props: Props) {
  const [actionButton, setActionButton] = useState<boolean>(false);
  const [showScanModal, setShowScanModal] = useState<boolean>(false);

  const history = useHistory();
  const location = useLocation();

  const rewardsHandle = () => {
    if (actionButton === true) {
      setActionButton(false);
    }
    history.push("/rewards");
  };
  const favItemsHandle = () => {
    if (actionButton === true) {
      setActionButton(false);
    }
    if (location.pathname !== ROUTE_PROFILE_DETAILS)
      history.push({
        pathname: "/profileDetails",
        state: { scrollTo: SCROLL_TO_PROFILE_FAV_ITEMS },
      });
    else {
      scrollToElementByRef(props.scrollToDivRef);
    }
  };
  const recentModalHandle = () => {
    if (actionButton === true) {
      setActionButton(false);
    }
    history.push("/profileDetails/more-recent-orders");
  };

  const favoriteModalHandle = () => {
    if (actionButton === true) {
      setActionButton(false);
    }
    history.push("/profileDetails/more-favorite-orders", {
      is_favorite_order: true,
    });
  };

  const closeScanModalOnSuccess = () => {
    setShowScanModal(false);
    setActionButton(false);
  };
  const fabButtonStyleClass = () => {
    if (!props.isBtnStyles) return "";
    if (props.isMakeitBtnShowing) return "available";
    return "notAvailable";
  };

  return (
    <div>
      {/* Sticky Links Wrap */}
      <div className={`${fabButtonStyleClass()} sticky-links-wrap fab-wrapper`}>
        <input
          id="fabCheckbox"
          type="checkbox"
          className="fab-checkbox"
          onChange={(e) => setActionButton(e.target.checked)}
          checked={actionButton}
        />
        <label className="fab" htmlFor="fabCheckbox">
          <div className="fab-menu-btn">
            <FabIcon />
          </div>
          <div className="fab-close-btn">
            <FabCloseIcon />
          </div>
        </label>
        <div className="fab-wheel">
          <button
            type="button"
            className={`${
              !shouldRelease("Features", "redeem")
                ? "fab-action-1-should-release-position"
                : " fab-action-1"
            } sticky-links recents-order fab-action `}
            onClick={() => setShowScanModal(true)}
          >
            <img
              src={require("../../images/fab-qrcode-icon.svg")}
              alt="fab qrcode icon"
              className="img-fluid"
            />
            Scan
          </button>
          <button
            type="button"
            className={`${
              !shouldRelease("Features", "redeem")
                ? "fab-action-2-should-release-position"
                : " fab-action-2"
            } sticky-links recents-order fab-action `}
            onClick={recentModalHandle}
          >
            <div className="me-2">
              <RecentOrdersIcon />
            </div>
            Recents
          </button>
          <button
            type="button"
            className={`${
              !shouldRelease("Features", "redeem")
                ? "fab-action-3-should-release-position"
                : " fab-action-3"
            } sticky-links favorites-order fab-action `}
            onClick={favoriteModalHandle}
          >
            <div className="me-2">
              <FavoriteIcon className={undefined} />
            </div>
            FAVORITE ORDERS
          </button>
          <Link to={"/locations"}>
            <button
              type="button"
              className={`${
                !shouldRelease("Features", "redeem")
                  ? "fab-action-4-should-release-position"
                  : "fab-action-4"
              } sticky-links favorites-order fab-action `}
            >
              <div className="me-2">
                <LocationPinIcon />
              </div>
              Store Locator
            </button>
          </Link>
          {shouldRelease("Features", "redeem") && (
            <button
              type="button"
              className={` ${
                !shouldRelease("Features", "redeem")
                  ? "fab-action-5-should-release-position"
                  : "fab-action-5"
              } sticky-links rewards fab-action`}
              onClick={rewardsHandle}
            >
              <img
                src={require("../../images/fab-reward-icon.svg")}
                alt="fab reward"
                className="img-fluid"
              />
              Rewards
            </button>
          )}
        </div>
        <div className="fab-overlay"></div>
      </div>
      <ScanModal
        showModal={showScanModal}
        handleBackModal={() => setShowScanModal(false)}
        closeModalOnSuccess={closeScanModalOnSuccess}
      />
    </div>
  );
}
