import { useMutation } from "react-query";
import { orderMicroService } from "../../services";


const toggleAutoRedeem=async(payload:any)=>{
const response=await orderMicroService.toggleAutoRedeem(payload)
return response
}
export default function useToggleAutoRedeem (){
    return useMutation ((payload:any)=>toggleAutoRedeem(payload))

}