import { useMutation } from "react-query";
import { customerBusinessService } from "../services";

const removeVehicle = async (vehicleId: number) => {
  const response = await customerBusinessService.removeVehicle(vehicleId)
  return response.data.data;
};

export default function useRemoveVehicle() {
  return useMutation((data: { id: number }) =>
    removeVehicle(data.id)
  );
}
