import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import ContactMethod from "../../containers/order/ContactMethod/ContactMethod";
import OrderDetails from "../../containers/order/OrderDetails/OrderDetails";
import OrderDetailsPickupMethod from "../../containers/order/OrderDetailsPickupMethod/OrderDetailsPickupMethod";
import PaymentMethod from "../../containers/order/PaymentMethod/PaymentMethod";
import Billing from "../Cart/Billing/Billing";
import styleClasses from "./thankyou.module.scss";
import TrackingVehicleDetails from "./TrackingVehicleDetails";
import {
  CURBSIDE,
  CURRENT_USER,
  HISTORY_STATE_FROM_PLACED_ORDER,
  ROUTE_MENU,
  SCHEDULED,
} from "../../constants";
import { useHistory } from "react-router";
import CancelOrderModal from "../LiveTracking/CancelOrderPrompt";
import { orderMicroService } from "../../services";
import { Toast_Func } from "../../helpers/toast.helper";
import Button from "../Buttons/Button";
import { brazeLogCustomEventFavoriteOrder, brazeLogCustomEventOrderCancelled } from "../../helpers/brazeHelper";
import useCheckMobileScreen from "../../hooks/useCheckMobileScreen";
import { getUserId } from "../../helpers/helperMethods";
import { useQueryClient } from "react-query";
import * as CONSTANT from "../../constants";
import {
  AddToFavoriteModalConfirmation,
  LabelFavoriteOrder,
} from "../../containers/account/profileDetails";
import FavoriteButton from "../../containers/try2/Customization/FavoriteButton";
import { useAppDispatch, useAppSelector } from "../../redux/store/store";
import {
  addOrderToFavorite,
  removeOrderFromFavorite,
  updateTrackOrder,
} from "../../redux/slices/checkoutSlice";
import useRemoveFavoriteOrder from "../../react-query-hooks/Favorites/useRemoveFavoriteOrder";

import useAddFavoritesOrder from "../../react-query-hooks/Favorites/useAddFavoriteOrder";
import { useAuthState } from "../../context/UserAuthentication";

interface IOrderTrackingDetailsProps {
  orderDetails: any;
  isOrderTypeSelected: boolean;
  pickUpSlotDetails?: any;
  convertedDate: string;
  orderType: string;
  pickUpMethod: string;
  refetch: (data: any) => void;
  doorDashStatus: string;
  isOrderCancelledOrFailed: boolean;
}
interface ICustomerVehicle {
  car_make?: string;
  car_model?: string;
  car_color?: string;
}

const OrderTrackingDetails: React.FC<IOrderTrackingDetailsProps> = ({
  orderDetails,
  isOrderTypeSelected,
  pickUpSlotDetails,
  orderType,
  convertedDate,
  pickUpMethod,
  refetch,
  doorDashStatus,
  isOrderCancelledOrFailed,
}) => {
  const isItMobile = useCheckMobileScreen();
  const history = useHistory();
  const cartItems = orderDetails?.items;

  const {
    customer: { car_make, car_model, car_color } = {} as ICustomerVehicle,
  } = orderDetails;
  let canCancelOrder =
    !orderDetails.brink_order_id && !isOrderCancelledOrFailed;
  if (doorDashStatus && doorDashStatus !== SCHEDULED) canCancelOrder = false;
  // const { card: {last4, brand, card_name}} = orderDetails?.payment_details?.[0]
  const authUser = localStorage.getItem(CURRENT_USER)
    ? JSON.parse(localStorage.getItem(CURRENT_USER))
    : {};
  const queryClient = useQueryClient();
  const refetchOrderDetails = () => refetch({ orderId: orderDetails?.id });
  const [showCancelOrder, setShowCancelOrderModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const redirectToFavoriteHandler=()=>{
    history.push({
      pathname:CONSTANT.VIEW_MORE_RECENT_FAVORITE_ORDER,
      state:{is_favorite_order:true}
    })

  }
  const {
    decideShowLabelOrDelectionModal,
    labelOrderModal,
    closeLabelOrderModal,
    onAddLabelOrderHandler,
    favoriteModal,
    hideFavoriteModal,
    removeOrderFromFavoriteHandler,
    labelFavoriteItemName,
    isFavoriteItem,
    isAddLoading,
    isRemoveLoading
  } = useFavoriteLikeOrDisLike({
    orderDetails,
    refetchOrderDetails,
    redirectToFavorite: redirectToFavoriteHandler
  });




  const dispatch = useAppDispatch();

  const cancelOrder = async () => {
    try {
      setLoading(true);
      const response = await orderMicroService.cancelOrder(orderDetails.id);
      if (response.status) {
        Toast_Func({
          status: true,
          message: response.data.data,
        });
        setShowCancelOrderModal(false);
        brazeLogCustomEventOrderCancelled(orderDetails, isItMobile);
        dispatch(updateTrackOrder(false));
        history.push(ROUTE_MENU);
      }
      setLoading(false);
      queryClient.refetchQueries(["myRewards", getUserId()], null, {
        throwOnError: true,
      });
    } catch (error) {
      Toast_Func({
        status: false,
        message:
          error?.response?.data?.message ?? "Order could not be cancelled!",
      });
      setShowCancelOrderModal(false);
      refetch({});
      setLoading(false);
    }
  };

  return (
    <Row>
      <Col className="px-4 px-md-0">
        <OrderDetails
          Titlehide=""
          Imagehide="d-md-block d-none"
          Items={cartItems}
          orderDetails={orderDetails}
          isOrdered={true}
          refetchOrderDetails={refetchOrderDetails}
          labelFavoriteItemName={labelFavoriteItemName}
          decideShowLabelOrDelectionModal={decideShowLabelOrDelectionModal}
          isFavoriteItem={isFavoriteItem}
          title={'Order Details'}
        />
        <OrderDetailsPickupMethod
          isOrdered={true}
          pickUpSlotDetails={pickUpSlotDetails}
          orderType={orderType}
          convertedDate={convertedDate}
          pickUpMethod={pickUpMethod}
        />
        {pickUpMethod === CURBSIDE && (
          <TrackingVehicleDetails
            make={car_make}
            model={car_model}
            color={car_color}
            titleStyle={"font-Cls fw-normal f-s20 f-sm-s16 text-uppercase "}
          />
        )}
        <ContactMethod isOrdered={true} orderDetails={orderDetails} />
        {!orderDetails?.is_free && (
          <PaymentMethod
            titleStyle={"font-Cls fw-normal text-uppercase"}
            paymentDetails={orderDetails?.payment_details ?? []}
          />
        )}
        <Billing
          StyleBtn={`${styleClasses.btnColor}`}
          StyleCheckout={`${styleClasses.checkoutwrapper}`}
          cartItems={cartItems}
          isOrderTypeSelected={isOrderTypeSelected}
          orderType={orderType}
          isOrdered={true}
          titleStyle={"f-s20"}
          orderTippingAmount={orderDetails?.tip}
          tax={orderDetails?.tax}
          order={orderDetails}
        />
        {/* <button
          type="button"
          className={`btn btn-large w-100 text-uppercase  mb-3 `}
          onClick={() => history.push(ROUTE_MENU, {from: HISTORY_STATE_FROM_PLACED_ORDER})}
        >
          Continue
        </button> */}
        {!orderDetails?.is_favorite && (
          <Button
            type="button"
            disabled={  isAddLoading ||  isRemoveLoading}
            className=" w-100 text-uppercase  mb-3 "
            onClick={decideShowLabelOrDelectionModal }
          >
            Mark This Order Favorite
          </Button>
        )}
        {canCancelOrder && (
          <Button
            type="button"
            className=" w-100 text-uppercase  mb-3 outline"
            onClick={() => setShowCancelOrderModal(true)}
          >
            Cancel Order
          </Button>
        )}
        <LabelFavoriteOrder
          showAddLabelModal={labelOrderModal}
          hideLabelModal={closeLabelOrderModal}
          onAddLabelOrderHandler={onAddLabelOrderHandler}
        />
        <AddToFavoriteModalConfirmation
          showAddAndRemoveModal={favoriteModal}
          hideAddAndRemoveModal={hideFavoriteModal}
          id={"favoutie-modal"}
          name={"Modal"}
          onRemoveClickHandler={() => {
            hideFavoriteModal();
            removeOrderFromFavoriteHandler();
          }}
        />

        <CancelOrderModal
          showModal={showCancelOrder}
          loading={loading}
          handleClose={() => setShowCancelOrderModal(false)}
          cancelOrder={cancelOrder}
          order={orderDetails}
        />
        <p className="f-s14 mb-0">A copy of this receipt will be sent to:</p>
        <p>
          <a
            href="mailto:julia.dankberg@gmail.com"
            className="f-s14 clr-dark-grey text-decoration-none pe-none"
          >
            {authUser?.email}
          </a>
        </p>
      </Col>
    </Row>
  );
};

export default OrderTrackingDetails;

export const useFavoriteLikeOrDisLike = ({
  orderDetails,
  refetchOrderDetails,
  isOrdered = true,
  redirectToFavorite,
}:any) => {
  const [labelOrderModal, setLabelOrderModal] = useState(false);
  const [favoriteModal, setFavoriteModal] = useState(false);
  const checkoutDispatch = useAppDispatch();
  const { authInfo } = useAuthState();

  let { mutateAsync: removeFavorite ,isLoading:isRemoveLoading} = useRemoveFavoriteOrder();
  let { mutateAsync: addFavorite,isLoading:isAddLoading } = useAddFavoritesOrder();

  const isItMobile = useCheckMobileScreen()

  const [localOrderDetails, setLocalOrderDetails] = useState(orderDetails);

  useEffect(() => {
    setLocalOrderDetails(orderDetails);
  }, [orderDetails]);

  const isFavoriteItem = useAppSelector(
    (state) => localOrderDetails?.is_favorite || state.checkout.isFavorite || ""
  );
  const labelFavoriteItemName = useAppSelector(
    (state) =>
      localOrderDetails?.favorite_order_name ||
      state.checkout.labelFavoriteOrderName ||
      ""
  );

  const onErrorAddFavoriteOrder = () => {
    setLocalOrderDetails(orderDetails);
  };

  const removeFavoriteItem = (item: any) => {
    removeFavorite(
      { ...item, customer_id: authInfo.userId },
      {
        onSuccess: () => {
          refetchOrderDetails();
        },
        onError: onErrorAddFavoriteOrder,
      }
    );
  };

  const addFavoriteItem = (item: any) => {
    addFavorite(
      { ...item, customer_id: authInfo.userId },
      {
        onSuccess: () => {
         if(localOrderDetails)
          {
            brazeLogCustomEventFavoriteOrder({...orderDetails,...item},isItMobile)
          }
          refetchOrderDetails();
          redirectToFavorite?.()
        },
        onError: onErrorAddFavoriteOrder,
      }
    );
  };

  const removeOrderFromFavoriteHandler = () => {
    if (isOrdered) {
      setLocalOrderDetails((prevState) => ({
        ...prevState,
        favorite_order_name: "",
        is_favorite: false,
      }));
      removeFavoriteItem({ id: orderDetails.id });
    } else {
      checkoutDispatch(removeOrderFromFavorite());
    }
  };

  const closeLabelOrderModal = () => {
    setLabelOrderModal(false);
  };

  const onAddLabelOrderHandler = (values) => {
    closeLabelOrderModal();

    if (isOrdered) {
      setLocalOrderDetails((prevState) => ({
        ...prevState,
        favorite_order_name: values?.orderFavouriteLabel,
        is_favorite: true,
      }));
      addFavoriteItem({
        id: orderDetails.id,
        order_name: values.orderFavouriteLabel,
      });
    } else {
      checkoutDispatch(
        addOrderToFavorite({
          labelFavoriteOrderName: values.orderFavouriteLabel,
        })
      );
    }
  };

  const showOrderLabelModal = () => {
    setLabelOrderModal(true);
  };

  const hideFavoriteModal = () => {
    setFavoriteModal(false);
  };

  const showFavoriteModal = () => {
    setFavoriteModal(true);
  };

  const decideShowLabelOrDelectionModal = () => {
    if (isFavoriteItem) {
      showFavoriteModal();
    } else {
      showOrderLabelModal();
    }
  };

  return {
    decideShowLabelOrDelectionModal,
    labelOrderModal,
    closeLabelOrderModal,
    onAddLabelOrderHandler,
    favoriteModal,
    hideFavoriteModal,
    removeOrderFromFavoriteHandler,
    labelFavoriteItemName,
    isFavoriteItem,
    isRemoveLoading,
    isAddLoading
  };
};
