import React from "react";
import styles from "./HeaderTop.module.scss";
import useCheckMobileScreen from "../../hooks/useCheckMobileScreen";

interface HeaderTopProps {
  handleShowLiveTracker: () => void;
}

const OrderTrackBar: React.FC<HeaderTopProps> = ({ handleShowLiveTracker }) => {
  const isItMobile = useCheckMobileScreen();
  return (
    <div>
      <div className={`${styles.orderTrackBar}`}>
        {!isItMobile ? (
          <>
            <p className="pe-1 ps-2 f-w3 f-s16 mb-0  font-trade-gothic-next f-w3 ls-1  clr-dark-grey">
              We’re whipping up your order...
            </p>
            <button
              className="f-s16  font-trade-gothic-next f-w3 border-0 text-decoration-underline px-0 bg-transparent f-w3 lh-lg clr-dark-grey"
              onClick={handleShowLiveTracker}
            >
              Click here to track it.
            </button>
          </>
        ) : (
          <button
            className="f-s14  font-trade-gothic-next f-w3 border-0 text-decoration-underline px-0 bg-transparent  lh-lg clr-dark-grey"
            onClick={handleShowLiveTracker}
          >
            Click here to track your order 

          </button>
        )}
      </div>
    </div>
  );
};

export default OrderTrackBar;
