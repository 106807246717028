import React from "react";
import { QRCodeCanvas } from "qrcode.react";

interface IGenerateQRCode {
  valueCode: string;
  size?: number;
}
const ScanQRCode: React.FC<IGenerateQRCode> = ({ valueCode, size = 250 }) => {
  return (
    <div className="text-center">
      <h5 className="f-s22 font-Cls text-center fw-normal pb-1 pt-md-4 pt-2 text-capitalize">
        Scan
      </h5>
      <p className="pb-md-5 pb-3 f-s16">
        Scan this code before paying to earn points and redeem rewards.
      </p>
      <div className="my-3 pb-md-5 pb-2">
        {valueCode ? (
          <QRCodeCanvas value={valueCode} size={size} />
        ) : (
          <p>You are not registered for loyalty</p>
        )}
      </div>
    </div>
  );
};

export default ScanQRCode;
