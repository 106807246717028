
import { IAddToOrderPayload, Payload } from "../../models/order.model";
import { getUTCFullDate, getVisitorId } from "../helperMethods";

export const getPayloadToSync = (authInfo) => {
    const payload: Payload = {
        store_id: '20',
        source: "web_app",
        status: "open",
        place_date: new Date(),
        wanted_date: new Date(),
        destination_id: "12",
        total_price: 123,
        sub_total: 123,
        location_id:JSON.parse(localStorage.getItem('SelectedLocation'))?.id
    }
    if(authInfo.userId){
        payload.customer_id= (authInfo.userId).toString()
    } else {
        if (!authInfo.visitor_id) authInfo.visitor_id = getVisitorId()
        payload.visitor_id = authInfo.visitor_id
    }
    return payload;
}


export const getAddToOrderPayloadToSync = (authInfo) => {
    const payload: IAddToOrderPayload = {
        source: "web_app",
        status: "open",
        business_date: getUTCFullDate(new Date()),
        total_price: 123,
        sub_total: 123,
        location_id:JSON.parse(localStorage.getItem('SelectedLocation'))?.id
    }
    if(authInfo.userId){
        payload.customer_id= (authInfo.userId).toString()
    } else {
        if (!authInfo.visitor_id) authInfo.visitor_id = getVisitorId()
        payload.visitor_id = authInfo.visitor_id
    }
    return payload;
}

export const getUpdateOrderPayloadToSync = (authInfo) => {
    const payload: any = {
        location_id:JSON.parse(localStorage.getItem('SelectedLocation'))?.id
    }
    if(authInfo.userId){
        payload.customer_id= (authInfo.userId).toString()
    } else {
        if (!authInfo.visitor_id) authInfo.visitor_id = getVisitorId()
        payload.visitor_id = authInfo.visitor_id
    }
    return payload;
}