import React, { useEffect, Fragment } from "react";
import { withRouter, RouteComponentProps } from "react-router";

interface IScrollToTop extends RouteComponentProps {
  children?: React.ReactNode;
}

const ScrollToTop: React.FC<IScrollToTop> = ({ history, children }) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return <Fragment>{children}</Fragment>;
};

export default withRouter(ScrollToTop);
