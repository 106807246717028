import * as Yup from "yup";

export const barcodeSchema = () => {
    return Yup.object().shape({
        barCode: Yup.string()
            .min(13, "Barcode should not contain less than 13 digits")
            .max(13, "Barcode should not contain more than 13 digits")
            .required("Barcode is required")
            .matches(/^[\d]*$/, "Barcode should contain digits only"),
    });
};
