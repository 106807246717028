import {
  ADD_ONS,
  CORE_MODIFIERS,
  DECREASE,
  INCREASE,
  NO,
  SELECTED
} from "../constants";
import {
  itemBuckets
} from "./buckets";
import {
  ADDITIONALPRICE,
  BY_DEFAULT_ADDED_V2,
  ORIGINALPRICE
} from "./constants";
import {
  assignTypeToSelectedModifier,
  lastIndexOf,
  toFixedNumber
} from "./helper";
import {
  ICurrentModifier
} from "./priceCalculation";

export function AddAddiotionalPriceToCoreAndItsRelated(
  name,
  modifier,
  fromItem,
  type
) {
  const bucket = itemBuckets.getSingleBucket({name, fromItem, isFromItemComingAsAnIndex:true});
  if(!bucket.memoryChip[modifier?.modifier_group_id]?.changes){
    const itemsModifierGroups = itemBuckets.getSingleItem({itemType:'any', itemNo:fromItem, isItemNoComingAsAnIndex: true})?.items_modifier_groups
    const currentModifierGroup = findDefaultAdjustmentPrice(modifier?.modifier_group_id, itemsModifierGroups)
    addAdjustmentStorages(bucket, modifier?.modifier_group_id, currentModifierGroup);
  }
  const memoryChip = bucket.memoryChip;
  modifier.type === SELECTED && assignTypeToSelectedModifier(modifier);
  const treatCoreModifier = treatCoreModifierAs(bucket, modifier?.modifier_id, modifier?.modifier_type, modifier?.type);
  switch (treatCoreModifier) {
      case 'ADD_CORE_MODIFIER':
          updateASpecificKeyInAdjustmentStorage(bucket, modifier?.modifier_group_id, modifier?.type, 'quantity');
          recalculateAdjustment(bucket.memoryChip[modifier?.modifier_group_id].changes, modifier, true);
          // remove from zero core.
          const zeroCore = memoryChip[modifier?.modifier_group_id].changes.zeroCore;
          const index = zeroCore.findIndex(currentModifier => currentModifier.modifier_id === modifier.modifier_id);
          if (index !== -1) {
              zeroCore.splice(index, 1)
          }
          applyAdjustment(memoryChip[modifier?.modifier_group_id].changes)
          mapWithSelectedModifiers(bucket, modifier, fromItem)
          break;
      case 'REMOVED_CORE_MODIFIER':
          updateASpecificKeyInAdjustmentStorage(bucket, modifier?.modifier_group_id, modifier?.type, 'quantity');
          // update adjustments
          recalculateAdjustment(bucket.memoryChip[modifier?.modifier_group_id].changes, modifier, false);
          modifier.code = NO;
          memoryChip[modifier?.modifier_group_id].changes.zeroCore.push(modifier);
          applyAdjustment(memoryChip[modifier?.modifier_group_id].changes)
          mapWithSelectedModifiers(bucket, modifier, fromItem)
          break;
      case 'ADD_ONS':
          updateASpecificKeyInAdjustmentStorage(bucket, modifier?.modifier_group_id, modifier?.type, 'quantity');
          addAddOns(bucket, modifier, fromItem)
          break;
      default:
          break;
  }
}

/**
* Update Adjustment Price when core set 1 to 0 and vice versa
*/
const recalculateAdjustment = (bucket, currentModifier, isDeduction: boolean) => {
  if (isDeduction) {
      bucket.prevAdjustmentQuantity = bucket.adjustmentQuantity;
      bucket.adjustmentQuantity = bucket.adjustmentQuantity - 1;
      bucket.prevAdjustmentPrice = bucket.adjustmentPrice;
      bucket.adjustmentPrice = toFixedNumber(bucket.adjustmentPrice - currentModifier.display_price);
  } else {
      bucket.prevAdjustmentQuantity = bucket.adjustmentQuantity;
      bucket.adjustmentQuantity = bucket.adjustmentQuantity + 1;
      bucket.prevAdjustmentPrice = bucket.adjustmentPrice;
      bucket.adjustmentPrice = toFixedNumber(bucket.adjustmentPrice + currentModifier.display_price);
  }
};

const substitution = (modifier) => {
  if (modifier.display_price > modifier.basePrice) {
      return {
          ...modifier,
          changes: {
              ...modifier.changes,
              additionalPrice: toFixedNumber(modifier.display_price - modifier.basePrice)
          },
      };
  } else {
      return {
          ...modifier,
          changes: {
              ...modifier.changes,
              additionalPrice: 0
          },
      };
  }
}

const addAddOns = (bucket, modifier, fromItem) => {
  const memoryChip = bucket['memoryChip'];
  const extraCore =
      memoryChip[modifier?.modifier_group_id].changes.extraCore;
  if (modifier.extendableLimitValue === 1 && modifier.modifier_group_min === 1) {
      const index = extraCore.findIndex((mod: ICurrentModifier) => mod.modifier_group_id === modifier.modifier_group_id);
      if (index >= 0) {
          extraCore.splice(index, 1)
      }
      modifier.additionalPrice = toFixedNumber(
          modifier.display_price - modifier.previousPrice
      );
      extraCore.push(substitution(modifier))
      mapWithSelectedModifiers(bucket, modifier, fromItem)
  } else {
      modifier.additionalPrice = modifier.display_price;
      if (modifier.type === DECREASE) {
          const index = lastIndexOf(extraCore, modifier);
          extraCore.splice(index, 1);
      }
      if (modifier.type === INCREASE) {
          extraCore.push(modifier);
      }
      applyAdjustment(memoryChip[modifier?.modifier_group_id].changes);
      mapWithSelectedModifiers(bucket, modifier, fromItem)
  }
  //   addAdditionalPriceToModifier(bucket, modifier)
};

/**
* Apply Adjustment With Respect to price and quantity to array extraCore
* whenever there is change in extraCore array or on alter of adjustment price & quantity
*/
const applyAdjustment = (bucket) => {
  const extraCore = bucket.extraCore;
  let freeQuantity = bucket.adjustmentQuantity;
  let freePrice = bucket.adjustmentPrice;

  bucket.extraCore = extraCore.map(modifier => {
      if (freePrice <= 0 || freeQuantity <= 0) {
          return {
              ...modifier,
              changes: {
                  ...modifier.changes,
                  adjustedPrice: 0,
                  additionalPrice: modifier.display_price,
              }
          };
      }
      const price = toFixedNumber(freePrice - modifier.display_price);
      if (price <= 0) {
          let tempFreePrice = freePrice;
          freeQuantity = freeQuantity - 1;
          freePrice = 0;
          return {
              ...modifier,
              changes: {
                  ...modifier.changes,
                  adjustedPrice: Math.abs(price),
                  additionalPrice: toFixedNumber(modifier.display_price - tempFreePrice)
              },
          };
      } else if (price > 0) {
          freePrice = toFixedNumber(freePrice - modifier.display_price);
          freeQuantity = freeQuantity - 1;
          return {
              ...modifier,
              changes: {
                  ...modifier.changes,
                  adjustedPrice: modifier.display_price,
                  additionalPrice: 0,
              },
          };
      }
      return {
          ...modifier,
          changes: {
              ...modifier.changes,
              adjustedPrice: 0,
              additionalPrice: modifier.display_price
          },
      };
  });
};

const mapWithSelectedModifiers = (bucket, modifier, fromItem) => {
  const extraCore = bucket['memoryChip'][modifier?.modifier_group_id].changes.extraCore;
  const modifiers = bucket.modifiers;
  for (let x = 0; x < modifiers.length; x++) {
      if (modifier.modifier_group_id === modifiers[x].modifier_group_id) {
          modifiers[x].additionalPrice = 0
      }
  }
  for (let i = 0; i < extraCore.length; i++) {
      for (let j = 0; j < modifiers.length; j++) {
          if (extraCore[i].modifier_group_id === modifiers[j].modifier_group_id) {
              if (extraCore[i].modifier_id === modifiers[j].modifier_id) {
                  toFixedNumber(modifiers[j].additionalPrice += extraCore[i].changes.additionalPrice);
              }
          }
      }
  }
  updateOriginalPrice(bucket, fromItem);
}

const updateOriginalPrice = (bucket, fromItem) => {
  const oldPrice = bucket[ADDITIONALPRICE];
  let newPrice = 0;
  const modifiers = bucket.modifiers;
  for (let i = 0; i < modifiers.length; i++) {
      if (modifiers[i].additionalPrice) {
          toFixedNumber(newPrice += modifiers[i].additionalPrice)
      }
  }
  //? Save New Added Price As A Old Price
  itemBuckets.updateSpecificItemBucketKey(
      CORE_MODIFIERS,
      ORIGINALPRICE,
      oldPrice,
      fromItem
  );
  itemBuckets.updateSpecificItemBucketKey(
      CORE_MODIFIERS,
      ADDITIONALPRICE,
      newPrice,
      fromItem
  );
}

const treatCoreModifierAs = (bucket, modifierId, type, action) => {
  const isItCore = bucket[BY_DEFAULT_ADDED_V2].modifiers.find((modifier: ICurrentModifier) => modifier.modifier_id === modifierId);
  if (type === ADD_ONS && !isItCore) {
      return 'ADD_ONS';
  }
  const isCore = bucket?.modifiers.find(
      (modifier) => modifier.modifier_id === modifierId
  );
  if (isCore !== undefined) {
      if (isCore.quantity === 1 && action === INCREASE) {
          return 'ADD_CORE_MODIFIER';
      }
      if (isCore.quantity === 1 && action === DECREASE) {
          return 'ADD_ONS'; // ! remove add_ons
      }
      if (isCore.quantity > 1) {
          return 'ADD_ONS';
      }
      if (isCore.quantity === 0) {
          return 'REMOVED_CORE_MODIFIER';
      }
  } else {
      return 'REMOVED_CORE_MODIFIER';
  }
};

const addAdjustmentStorages = (bucket, modifier_group_id, currentModifierGroup) => {
  bucket['memoryChip'][modifier_group_id].changes = {}
  bucket['memoryChip'][modifier_group_id].changes.extraCore = [];
  bucket['memoryChip'][modifier_group_id].changes.zeroCore = [];
  bucket['memoryChip'][modifier_group_id].changes.adjustmentPrice = currentModifierGroup.price;
  bucket['memoryChip'][modifier_group_id].changes.prevAdjustmentPrice = currentModifierGroup.price;
  bucket['memoryChip'][modifier_group_id].changes.prevAdjustmentQuantity = currentModifierGroup.quantity;
  bucket['memoryChip'][modifier_group_id].changes.adjustmentQuantity = currentModifierGroup.quantity;
  bucket['memoryChip'][modifier_group_id].changes.price = bucket['memoryChip'][modifier_group_id].price;
  bucket['memoryChip'][modifier_group_id].changes.quantity = bucket['memoryChip'][modifier_group_id].quantity;
}

const updateASpecificKeyInAdjustmentStorage = (bucket, modifier_group_id, type, key) => {
  if (type === INCREASE) {
      bucket['memoryChip'][modifier_group_id].changes[key] = bucket['memoryChip'][modifier_group_id].changes.quantity + 1
  }
  if (type === DECREASE) {
      bucket['memoryChip'][modifier_group_id].changes[key] = bucket['memoryChip'][modifier_group_id].changes.quantity - 1
  }
}


const findDefaultAdjustmentPrice = (
    key: number,
    modifierGroups: any,
  ): {price: number; quantity: number} => {
    const currentModifierGroup = modifierGroups.find(
      modifierGroup => modifierGroup.id === key,
    ) as any;
    const actualPrice =
      currentModifierGroup.base > currentModifierGroup.core_price
        ? currentModifierGroup.base - currentModifierGroup.core_price
        : 0;
    const actualQuantity =
      currentModifierGroup.max > currentModifierGroup.core_quantity
        ? currentModifierGroup.max - currentModifierGroup.core_quantity
        : 0;
    return {price: actualPrice, quantity: actualQuantity};
  };
  