import React, { useRef, useState } from "react";
import Spinner from "../../../components/Spinner/Spinner";
import { formatTime } from "../../../helpers/tracking/orderTracking";
import styleClasses from "../order.module.scss";
import _clonedeep from 'lodash.clonedeep'

interface OrderDetailsMobileProps {
  setOrderNo?: (orderNo: number) => void;
  orders?: any[];
  isFetchingOrders: boolean;
}

const OrderDetailsMobile = (props: OrderDetailsMobileProps) => {
  const { setOrderNo, orders, isFetchingOrders } = props;
  const [viewMores, setViewMores] = useState(Array(orders?.length).fill(false));
  const handleClick = (index: number, itemNames: string[]) => {
    const copyViewMores = _clonedeep(viewMores)
    if (!copyViewMores[index] && itemNames.length > 1) {
      copyViewMores[index] = true
    } else {
      setOrderNo(index);
    }
    setViewMores(copyViewMores)
  };

  if (isFetchingOrders) return <Spinner modalSpinner={true} className={styleClasses.live_tracker_loader} />;

  return (
    <>
      <h2 className="font-Visby-cf clr-dark-blue text-uppercase f-s18 text-center f-w8">
        choose your order
      </h2>
      {orders?.map((order, index) => {
        const itemNames = order.items.map((item) => item.name);
        const promiseTime = order?.estimated_delivery_time || order?.promise_time

        return (
          <div
            onClick={() => handleClick(index, itemNames)}
            className={styleClasses.order_detail_mobile_card}
          >
            <h3 className="f-s16 font-Cls ">Order: {index + 1}</h3>
            {!viewMores[index] && (
              <div className="d-flex justify-content-between align-items-center">
                <span className="f-s16 font-rale d-block">{itemNames[0]}</span>
                {itemNames.length > 1 && (
                  <button className="btn btn-custom text_underline font-Visby-cf f-w6 f-s14 clr-dark-blue p-0 ls-normal text-nowrap me-1">
                    view more...
                  </button>
                )}
              </div>
            )}

            {viewMores[index] &&
              itemNames.map((itemName, index) => (
                <div key={index}>
                  <span className="f-s16 font-rale d-block">{itemName}</span>
                </div>
              ))}
            <div className="f-s14 font-Visby-cf clr-text-grey">
              Order Time -{" "}
              <span className="clr-dark-blue">
                {formatTime(promiseTime?.split(" ")?.[1])}
              </span>{" "}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default OrderDetailsMobile;
