import { number } from "card-validator";
import { MutableRefObject } from "react";
import * as CONSTANT from "../constants";

export interface Item {
  id: number;
  name: string;
  description: string;
  calories: number;
  price: number;
  category: string;
  image: string;
  is_try_2_combo?: boolean;
  is_kids_combo?: boolean;
}

export interface Items_Modifier_Groups {
  id: number;
  name: string;
  brink_modifier_group_id: number;
  is_customizable_menu: number;
  label?: string;
  modifiers_groups_modifiers: {
    id: number;
    brink_modifier_id: number;
    name: string;
    price: number;
    image: string;
    calories: number;
    max: number;
    modifier_location: {
      status: string | null;
    }[];
  }[];
}

export interface ComplementaryItems {
  id: number;
  name: string;
  description: string;
  label?: string;
  min: number;
  max: number;
  is_customizable_menu: number;
  complimentary_modifier_group: number;
  modifiers_groups_modifiers: {
    id: number;
    brink_modifier_id: number | null;
    brink_modifier_group_id: number;
    name: string;
    price: number;
    image: string;
    calories: number;
    max: number;
    is_selected: number;
    allergen_description: string | null;
    modifier_location: {
      status: string | null;
    }[];
  }[];
}

export interface IRequiredModifiersGroupsModifiers {
  id: number;
  brink_modifier_id: number;
  name: string;
  price: number;
  image: string;
  calories: number;
  brink_modifier_group_id: number;
  max: number;
  in_item: boolean;
  is_selected?: number;
  required_modifier_location?: {
    status: string | null;
  }[];
  modifier_location: {
    status: string | null;
  }[];
  quantity?: number;
  modifier_id?:number
}

export interface IItemsRequiredModifierGroups {
  id: number;
  name: string;
  brink_modifier_group_id: number;
  is_customizable_menu: number;
  label?: string;
  max?: number;
  min?: number;
  base: number;
  extendable_limit?: number;
  required_modifiers_groups_modifiers: IRequiredModifiersGroupsModifiers[];
}

export interface ICoreModifiers {
  id: number;
  brink_modifier_id: number;
  brink_modifier_group_id: number;
  quantity: string;
  name: string;
  price: number;
  image: string;
  calories: number;
  max: number;
  is_selected?: number;
  is_upsell?:number;
  modifier_group_id?: number;
  modifier_group_min?: number;
  modifier_group_max?: number;
  modifier_group_extendable_limit?: number;
  modifier_location: {
    status: string | null;
  }[];
  core_ingredients_modifier_groups: {
    id: number;
    brink_modifier_group_id: number;
    name: string;
    price: number;
    image: string;
    calories: number;
    min?: number;
    max?: number;
    modifiers_groups_modifiers: {
      id: number;
      brink_modifier_id: number;
      name: string;
      price: number;
      image: string;
      calories: number;
      max: number;
      modifier_location: {
        status: string | null;
      }[];
    }[];
  }[];
}
export interface ICustomizedItem {
  tags?: any;
  id: number;
  category_id: number;
  name: string;
  image: string;
  description: string;
  full_brink_item_id: number;
  half_brink_item_id: number;
  calories: number;
  is_customizable?: boolean;
  full_calories: number;
  price: number;
  is_active: number;
  half_brink_item_price: number;
  items_modifier_groups: Items_Modifier_Groups[];
  core_modifiers: ICoreModifiers[];
  items_required_modifier_groups: IItemsRequiredModifierGroups[];
  item_location: {
    status: string | null;
  }[];
  half_loyalty_points?: number;
  full_loyalty_points?: number;
  category_name?: string;
  complementary_items?: ComplementaryItems[];
  bucket: IBucket[];
  is_create_your_own:boolean
}

export interface IBucket {
  [key: string]: {
    quantity: number;
    modifiers: number[];
    price: number;
  };
}

export interface IModifierGroups {
  id: number;
  name: string;
  label: string;
  brink_modifier_group_id: number;
  min?: number;
  max?: number;
  base?: number;
  extendable_limit?: number;
  modifiers_groups_modifiers: {
    id: number;
    brink_modifier_id: number;
    name: string;
    price: number;
    image: string;
    calories: number;
    max: number;
    is_selected?: number;
    modifier_location: {
      status: string | null;
    }[];
  }[];
}

export interface ICategories {
  id: number;
  name: string;
  image: string;
  items: ICustomizedItem[];
  base?: number
}

export interface ITry2Combo {
  name: string;
  description: string;
  items: ITry2ComboItem[];
}

export interface IKidsCombo {
  name: string;
  description: string;
  items: IKidsComboItem[];
}

export interface ITry2ComboItem {
  id: number;
  price?: number;
  name: string;
  items_modifier_groups: Items_Modifier_Groups[];
  sub_categories: ICategories[];
  complementary_items?: ComplementaryItems[];
  core_modifiers: ICoreModifiers[];
  items_required_modifier_groups: IItemsRequiredModifierGroups[];
}

export interface IKidsComboItem {
  id: number;
  price: number;
  name: string;
  description: string;
  image: string;
  items_modifier_groups?: Items_Modifier_Groups[];
  complementary_items?: ComplementaryItems[];
  core_modifiers?: ICoreModifiers[];
  items_required_modifier_groups?: IItemsRequiredModifierGroups[];
}

export enum Codes {
  ADD = "ADD",
  SUB = "ADD",
  NO = "NO",
}
export interface IFavoriteItem {
  description: string;
  full_calories: number;
  full_calories_label: string;
  half_calories: number;
  half_calories_label: string;
  id: number;
  image: string;
  item_id: number;
  name: string;
  price: number;
  tags: any[];
  cart_directly: boolean;
  category_id: number;
  half_brink_item_id: number;
  calories: number;
  is_active: boolean;
  is_kids_combo?: boolean;
  is_try_2_combo?: boolean;
}

export interface IItemPathToRoute {
  pathname: string;
  state: {
    isReward?: boolean;
    reward_size?: string;
    routeState?: any;
  };
  search: string;
}

export interface IItemCustomization {
  item: any;
  showCustomizedOptions: boolean;
  showCustomization: boolean;
  handleIngredientSelection: (data: any, id?: number) => void;
  modalShow: boolean;
  handleSyncIngredients: (item: any) => void;
  hideModifierModal: () => void;
  unrepeatedIngredients:any[];
  getOrderButtonState?:(type: string) => boolean
  autoScrollDivRef:MutableRefObject<HTMLDivElement>
  firstTimeModal?: boolean;
  CYOIScrollToMissingRequiredArea?:{
    refToScrollToMissingRequiredArea: any[];
    isCYOIRequiredModifierSelected: {
      status: boolean;
      highLightArea: number;
    };
    setIsCYOIRequiredModifierSelected: React.Dispatch<
      React.SetStateAction<{ status: boolean; highLightArea: any }>
    >;
  }
  // firstRef:any
}
