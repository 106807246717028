import React from "react";
import { Row, Col } from "react-bootstrap";
import styleClasses from "../../../containers/account/accounts.module.scss";
import { StoredPaymentMethod } from "../../../models/payment.model";
import NoStoredGiftCardBanner from "./NoStoredGiftCardBanner/noStoredGiftCardBanner";
import StoredGiftCard from "./StoredGiftCard/storedGiftCard";
import { IGiftCard } from "../../../models/giftCard.model";

type IStoredGiftCardsListing = {
  giftCardRecords: IGiftCard[];
  defaultPayment: IGiftCard;
  handleChangeDefault: (id: string) => void;
  handleEdit: (record: IGiftCard) => void;
  setOpenPaymentModal: (boolean) => void;
  setOpenLookUpModal: (boolean) => void;
  closeConfirmationModal?: boolean;
};

export default function StoredGiftCardsListing({
  giftCardRecords,
  defaultPayment,
  setOpenPaymentModal,
  handleEdit,
  handleChangeDefault,
  closeConfirmationModal,
  setOpenLookUpModal,
}: IStoredGiftCardsListing) {
  return giftCardRecords.length == 0 ? (
    <NoStoredGiftCardBanner
      handleModalOpen={() => setOpenPaymentModal(true)}
      handleLookupOpen={() => setOpenLookUpModal(true)}
    />
  ) : (
    <Row>
      <Col className={styleClasses.profile_inn_wrap}>
        <div className="mb-0 ">
          <h4 className="font-Cls f-s24 f-sm-s22 text-start h-24 medium_text mb-4">
            Gift Cards
          </h4>
        </div>
        <div
          className={`${styleClasses.card_info_wrap} text-start ${styleClasses.listing_style}`}
        >
          <div className={styleClasses.card_info}>
            <ul className="list-inline text-start ps-0 pe-3 pe-sm-0">
              {giftCardRecords.map((record, index) => (
                <StoredGiftCard
                  closeConfirmationModal={closeConfirmationModal}
                  key={record.id}
                  record={record}
                  handleEdit={handleEdit}
                  index={index}
                  defaultPayment={defaultPayment}
                  handleChangeDefault={handleChangeDefault}
                  paymentCount={giftCardRecords.length}
                />
              ))}
            </ul>
            <hr className="custom_hr_sty mb-4 mt-5  d-md-none" />
            <div className="d-flex justify-content-center">
              <button
                type="button"
                className="btn btn-large py-2 mt-4 mb-3 f-s16"
                onClick={() => setOpenPaymentModal(true)}
                disabled={giftCardRecords.length >= 5}
              >
                Add A Gift Card
              </button>
            </div>
            <div className="d-flex justify-content-center">
              {/* <button
                type="button"
                className={`btn btn-large ${styleClasses.lookupButton}`}
                onClick={() => setOpenLookUpModal(true)}
                data-testid="gift-card-lookup"
              >
                GIFT CARD LOOKUP
              </button> */}
            </div>
          </div>
        </div>
      </Col>
      <hr className="custom_hr_sty d-none d-md-block" />
    </Row>
  );
}
