
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { personalInfoSchema } from "../../../validationSchemas/personalInfoSchema";
import { FormField } from "../../FormFields/FormField";
import styleClasses from "../../../containers/account/accounts.module.scss";
import DefaultMethodConfirmationModal from "../DefaultMethodConfirmation/DefaultMethodConfirmation";
import { Toast_Func } from "../../../helpers/toast.helper";

export default function VehicleCardDesktop({
  vehicle,
  handleFormSubmission,
  showModal,
  setRemoveVehicle,
  setRemoveVehicleId,
  handleChangeDefault,
  defaultVehicle,
    vehicleCount,
  handleVehicleRemove
}) {
  const initialFormState = {
    color: vehicle.color,
    make: vehicle.make,
    model: vehicle.model,
  };
  const [confirmDefaultMethodSelection, setConfirmDefaultMethodSelection] =
    useState(false);
  return (
    <div className="d-none d-md-block" key={vehicle.id}>
      <div className="d-flex gap-3 pl-2">
        {
          vehicleCount > 1 &&
            <div className="position-relative">
          <span className={styleClasses.select_radio_button}>
            <input
                type="radio"
                id={vehicle.id}
                name={vehicle.id + 'desktopRadio'}
                value={vehicle.id}
                checked={vehicle.id === defaultVehicle?.id}
                onChange={()=>{}}
                onClick={() => {
                  setConfirmDefaultMethodSelection(true);
                }}
            />
          </span>
            </div>
        }

        <label htmlFor={`${vehicle.id} vehicleInfo`} className="w-100">
          {" "}
          <Formik
            initialValues={initialFormState}
            validationSchema={personalInfoSchema}
            onSubmit={handleFormSubmission}
            enableReinitialize
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              isSubmitting,
              submitForm,
            }) => {
              return(
              <form className="new_form_design profile-info-read-only-field" onSubmit={handleSubmit}>
                <Row>
                  <Col md={3} sm={12}>
                    <FormField
                      labelText={"Make"}
                      name="make"
                      type="text"
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      value={values.make}
                      readonly
                      isSearchable={true}
                    />
                  </Col>

                  <Col md={3} sm={12}>
                    <FormField
                      labelText={"Model"}
                      name="model"
                      type="text"
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      value={values.model}
                      readonly
                      isSearchable={true}
                    />
                  </Col>
                  <Col md={3} sm={12}>
                    <FormField
                      labelText={"Color"}
                      name="color"
                      type="text"
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      value={values.color}
                      readonly
                      isSearchable={true}
                    />
                  </Col>
                  <Col
                  lg={2}
                    md={3}
                    sm={12}
                    className="d-flex flex-column align-items-end justify-content-center"
                  >
                    <div className={`${styleClasses.edit_remove_btn} d-flex flex-column justify-content-center`}>
                      <button
                        onClick={() => {
                          if(!vehicle.is_default){
                            handleVehicleRemove(vehicle?.id)
                            setRemoveVehicleId(vehicle.id);
                          } else{
                            Toast_Func({
                              status:false,
                              message:'Default cannot be deleted'
                            })
                          }
                          
                        }}
                        className="btn btn-custom f-s14 font-Visby-cf color-sky-blue-light ms-3 p-0"
                      >
                        <img
                          src={require("../../../../src/images/Remove.svg")}
                          alt="remove"
                        />{" "}
                        Remove
                      </button>
                      <button
                        onClick={() =>
                          showModal("Edit Vehicle Details", vehicle.id)
                        }
                        className="btn btn-custom f-s14 font-Visby-cf color-sky-blue-light ms-3 p-0 mt-2"
                      >
                        <img src={require("../../../../src/images/Edit.svg")} alt="edit"/>{" "}
                        Edit
                      </button>
                    </div>
                  </Col>
                </Row>
              </form>
            )}}
          </Formik>{" "}
        </label>
      </div>
      <DefaultMethodConfirmationModal
        show={confirmDefaultMethodSelection}
        title="Change Default Vehicle info"
        content="You’re requesting to change the default vehicle info. Would you like to continue?"
        btnText={"Change"}
        handleClick={(e) => {
          handleChangeDefault(vehicle.id)
          setConfirmDefaultMethodSelection(false);
        }}
        handleClose={() => {
          setConfirmDefaultMethodSelection(false);
        }}
      />
    </div>
  );
}
