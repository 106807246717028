import { Formik } from "formik";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useQueryClient } from "react-query";
import { CARD_NUMBER_PLACEHOLDER } from "../../../../constants";
import styleClasses from "../../../../containers/account/accounts.module.scss";
import { Toast_Func } from "../../../../helpers/toast.helper";
import { API_ERROR } from "../../../../models/error.model";
import useRemovePaymentMethod from "../../../../react-query-hooks/useRemovePaymentMethod";
import { personalInfoSchema } from "../../../../validationSchemas/personalInfoSchema";
import { FormField } from "../../../FormFields/FormField";
import DefaultMethodConfirmationModal from "../../DefaultMethodConfirmation/DefaultMethodConfirmation";
import useProfile from "../../../../react-query-hooks/useProfile";
import {useAuthState} from "../../../../context/UserAuthentication";
import useCheckMobileScreen from "../../../../hooks/useCheckMobileScreen";
import {useAppSelector} from "../../../../redux/store/store";
import {appliedPaymentMethods} from "../../../../helpers/checkout/PaymentHelper";

interface IRecord {
  id: string | number;
  card: {
    number: number;
    exp_year: number;
    exp_month: number;
    cvc: string;
  };
  name: string;
  postal_code: string;
  type: string;
  is_default: boolean;
}
interface IStoredPaymentCardProps {
  record: IRecord;
  index: number;
  handleEdit: (id: any) => void;
  defaultPayment: IRecord;
  handleChangeDefault: (id: number | string) => void;
  paymentCount?: number;
  closeConfirmationModal?: boolean
}

const handleFormSubmission = async () => {
  console.log("🚀 ~ Submit");
  //TODO: API Integration
};

const StoredPaymentCard: React.FunctionComponent<IStoredPaymentCardProps> = ({
  record,
  handleEdit,
  index,
  defaultPayment,
  handleChangeDefault, paymentCount, closeConfirmationModal
}) => {
  const [isDefault, setIsDefault] = useState(record.is_default);
  const queryClient = useQueryClient();
  const { mutate } = useRemovePaymentMethod();
  let { authInfo } = useAuthState();
  const isItMobile = useCheckMobileScreen()
  const { data: userProfileData } = useProfile(authInfo.userId);
  const [confirmRemovePayment, setConfirmRemovePayment] = useState(false);
  const [
    confirmDefaultPaymentMethodSelection,
    setConfirmDefaultPaymentMethodSelection,
  ] = useState(false);
  const checkout = useAppSelector((state) => state.checkout);
  const paymentMethods = checkout.paymentMethods
  const addedCards = checkout.giftCard
  const allSelectedCards = appliedPaymentMethods(paymentMethods, addedCards);

  const handleCardRemove = (recordID) => {
    // if (record.is_default) { 
    //   Toast_Func({
    //       status: false,
    //       message: 'Default cannot be deleted'
    //     })
    //   return
    // }
    const paymentIndex = allSelectedCards?.findIndex((card) => card.id === recordID )
    if(paymentIndex !== -1)
    {
      Toast_Func({
        status: false,
        message: "Payment selected for order checkout can not be deleted",
      })
    }
    else {
      setConfirmRemovePayment(true)
    }
  }
  const initialFormState = {
    name: record.name || "",
    number: record.card.number || "",
    exp: record.card ? record.card.exp_month + "/" + record.card.exp_year : "",
  };
  const removePaymentMethod = (
    e: React.MouseEvent<HTMLButtonElement>,
    id: string | number
  ) => {
    e.preventDefault();
    mutate(
      {
        id: id,
        customer_id: userProfileData.id
      },
      {
        onSuccess: (data, variables, context) => {
          queryClient.invalidateQueries("paymentMethod");
          queryClient.invalidateQueries(["profile"]);
          setConfirmRemovePayment(false);
          Toast_Func({
            status: true,
            message: "Payment Method removed successfully",
          });
        },
        onError: (error, variables, context) => {
          const {
            data: {
              data: { errors },
            },
          } = (error as API_ERROR).response;
          Toast_Func({ status: false, message: errors[0] });
        },
      }
    );
  };
  return (
    <>
      <li key={defaultPayment?.id.toString()}>
        {isItMobile &&
        <div className="d-md-none">
          <div className="d-flex gap-3 pl-2">
            {
              paymentCount > 1 &&
                <div className="position-relative">
              <span className={styleClasses.select_radio_button}>
                <input
                    type="radio"
                    id={record.id?.toString()}
                    name={record.id + "mobileRadio"}
                    value={record.id}
                    checked={record.id == defaultPayment?.id}
                    onChange={()=>{}}
                    onClick={() => {
                      setConfirmDefaultPaymentMethodSelection(true);
                    }}
                />
              </span>
                </div>
            }
            <label htmlFor={ `${record.id?.toString()} paymentName`} className="w-100">
              <div className={styleClasses.inner_list_wrap}>
                <div className="d-flex justify-content-between align-items-center">
                  <span className={`${styleClasses.info_label} d-block`}>
                    Payment Name
                  </span>
                  <div>
                    {" "}
                    <button
                      type="button"
                      className="btn btn-custom f-s16 text_underline clr-text-grey ms-3 p-0"
                      onClick={() => handleCardRemove(record.id)}
                    >
                      <img
                        src={require("../../../../../src/images/Remove.svg")}
                        alt="remove icon"
                      />
                    </button>
                    {/* Hiding Edit Button as Payment Card cannot be edited anymore 
                    <button
                      type="button"
                      className="btn btn-custom f-s16 text_underline clr-text-grey ms-3 p-0"
                      onClick={() => handleEdit(record)}
                      data-testid="stored-payment-edit-button"
                    >
                      <img
                        src={require("../../../../../src/images/Edit.svg")}
                      />
                    </button> */}
                  </div>
                </div>
                <span className="d-flex justify-content-between align-items-center">
                  <span className={styleClasses.info_text}>{record.name}</span>

                </span>
              </div>
              <div className={`${styleClasses.inner_list_wrap} mb-4`}>
                <span className="d-flex justify-content-between align-items-center">
                  <span className={`${styleClasses.info_label} d-block`}>
                    Card Number
                  </span>
                  <span className={`${styleClasses.info_label} d-block ms-3`}>
                    EXP.
                  </span>
                </span>
                <span
                  className={`d-flex justify-content-between align-items-center ${styleClasses.info_text}`}
                >
                  {CARD_NUMBER_PLACEHOLDER + record.card.number}
                  <span className="ms-3">{`${record.card.exp_month}/${record.card.exp_year}`}</span>
                </span>
              </div>
            </label>
          </div>
        </div>
        }
        {/* Desktop View Design */}
        {!isItMobile &&
        <div className="d-none d-md-block">
          <div className="d-flex gap-3 pl-2">
            {
                paymentCount > 1 &&
                <div className="position-relative">
              <span className={styleClasses.select_radio_button}>
                <input
                    type="radio"
                    id={record.id?.toString()}
                    name={record.id + "desktopRadio"}
                    value={record.id}
                    checked={record.id == defaultPayment?.id}
                    onChange={()=>{}}
                    onClick={() => {
                      setConfirmDefaultPaymentMethodSelection(true);
                    }}
                />
              </span>
                </div>
            }
            <label htmlFor={`${record.id?.toString()} paymentName`} className="w-100">
              <Formik
                initialValues={initialFormState}
                validationSchema={personalInfoSchema}
                onSubmit={handleFormSubmission}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  submitForm,
                }) => (
                  <form className="new_form_design profile-info-read-only-field" onSubmit={handleSubmit}>
                    <Row>
                      <Col md={4} sm={12}>
                        <FormField
                          labelText={"Payment name"}
                          name="name"
                          type="text"
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          value={values.name}
                          readonly
                        />
                      </Col>

                      <Col md={4} sm={12}>
                        <FormField
                          labelText={"Card number"}
                          name="number"
                          type="text"
                          onChange={() => { }}
                          errors={errors}
                          touched={touched}
                          value={CARD_NUMBER_PLACEHOLDER + values.number}
                          readonly
                        />
                      </Col>
                      <Col md={2} sm={12}>
                        <FormField
                          labelText={"Expiration"}
                          name="exp"
                          type="text"
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          value={values.exp}
                          readonly
                        />
                      </Col>
                      <Col
                        md={2}
                        sm={12}
                        className="d-flex flex-column align-items-start justify-content-center"
                      >
                        <div className={`${styleClasses.edit_remove_btn} d-flex flex-column justify-content-center`}>
                          <button
                            onClick={() => handleCardRemove(record.id)}
                            className="btn btn-custom f-s14 font-Visby-cf color-sky-blue-light ms-3 p-0"
                          >
                            <img
                              src={require("../../../../../src/images//Remove.svg")}
                              alt="remove icon"
                            />{" "}
                            Remove
                          </button>
                          {/* Hiding Edit Button as Payment Card cannot be edited anymore 
                          <button
                            onClick={() => handleEdit(record)}
                            className="btn btn-custom f-s14 font-Visby-cf color-sky-blue-light ms-3 p-0 mt-2"
                          >
                            <img
                              src={require("../../../../../src/images/Edit.svg")}
                            />{" "}
                            Edit
                          </button> */}
                        </div>
                      </Col>
                    </Row>
                  </form>
                )}
              </Formik>
            </label>
          </div>
        </div>
        }
      </li>
      <DefaultMethodConfirmationModal
        show={confirmRemovePayment}
        title="Remove This Payment Method"
        content="You’re requesting to remove this payment method. Would you like to continue?"
        btnText={"remove"}
        handleClick={(e) => {
          removePaymentMethod(e, record.id);
        }}
        handleClose={() => {
          setConfirmRemovePayment(false);
        }}
      />

      <DefaultMethodConfirmationModal
        show={confirmDefaultPaymentMethodSelection && !closeConfirmationModal}
        title="Change Default Payment Method"
        content="You’re requesting to change the default payment method. Would you like to continue?"
        btnText={"Change"}
        handleClick={() => {
          handleChangeDefault(record.id);
        }}
        handleClose={() => {
          setConfirmDefaultPaymentMethodSelection(false);
        }}
      />
    </>
  );
};

export default StoredPaymentCard;
