import { useInfiniteQuery, useQuery } from "react-query";
import { orderMicroService } from "../services";

interface IGetOrderHistoryQueryProps {
  page: number;
  orderBy?: string;
  search?: string;
  size?: number;
  column?: string;
  is_favorite?: number;
}

const getOrderHistory = async (id, query: IGetOrderHistoryQueryProps) => {
  const response = await orderMicroService.getOrderHistory(id, query);
  return response.data.data;
};

export default function useOrderHistory(
  id,
  query: IGetOrderHistoryQueryProps = { page: 1 }
) {
  return useInfiniteQuery(
    ["orderHistory", query],
    ({ pageParam = 1 }) => getOrderHistory(id, { ...query, page: pageParam }),
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.current_page < lastPage.last_page) {
          return lastPage.current_page + 1;
        }
        return null;
      },
    }
  );
}
