import * as Yup from "yup";
import valid from "card-validator";

export const paymentRecordSchema = (editMode = false) => {
  return Yup.object().shape({
    name: Yup.string()
      .max(50, "Name should not contain more than 50 characters")
      .matches(
        /^[a-zA-Z\d ]*$/,
        "Name should not contain special characters or numbers"
      ),
    postal_code: Yup.string()
      .min(5, "Zip code should not contain less than 5 digits")
      .max(10, "Zip code should not contain more than 10 digits")
      .required("Postal code is required")
      .matches(/^[\d]*$/, "Postal code should contain digits only"),
    card: Yup.object().shape({
      number: editMode
        ? Yup.string()
        : Yup.string().required("Card number is required").min(14,"Number must be of atleast 14 digits").test(
          "test-number",
          "Card number is invalid",
          (value) => valid.number(value).isValid
        ),
      expiration: Yup.string().required("Expiration Date is required").test(
        "test-expiration",
        "Expiration date is invalid",
        (value) => valid.expirationDate(value).isValid
      ),

      ...(!editMode && {
        cvc: Yup.string().test({
          name:'test-cvc',
          test:(value='')=>{
            return value.length==3||value.length==4 },
          message:'CCV must be 3 or 4 digits long'
        })
        // .test(
        //   "test-cvc",
        //   "CVC number is invalid",
        //   (value) => valid.cvv(value).isValid
        // ),
      })
    }),
  });
};

export const zipCodeSchema = Yup.string()
  .min(5, "Zip code should not contain less than 5 digits")
  .max(10, "Zip code should not contain more than 10 digits")
  .required("Postal code is required")
  .matches(/^[\d]*$/, "Postal code should contain digits only");