import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { reducer } from '../slices/cartSlice';
import { itemSliceReducer } from '../slices/itemSlice';
import { persistReducer, persistStore } from 'redux-persist';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import storage from 'redux-persist/lib/storage';
import { itemCustomizationReducer } from "../slices/itemCustomizationSlice";
import {checkoutReducer} from "../slices/checkoutSlice";
import { specificFlowStatesReducer } from "../slices/handleStatesForSpecificFlows";

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['cart', 'checkout'],
};

const reducers = combineReducers({
    cart: reducer,
    itemCustomization: itemCustomizationReducer,
    Items: itemSliceReducer,
    checkout: checkoutReducer,
    specificFlowStates: specificFlowStatesReducer
});

const rootReducer = (state, action) => {
    return reducers(state, action);
};

export const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.REACT_APP_ENV !== 'production',
})

export const persistor = persistStore(store);


export type RootState = ReturnType<typeof store.getState>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export default store;