import { useMutation } from "react-query";
import axios from "axios";
import {ORDER_MICRO_SERIVCE, PICKUP_SLOT_SERVICE} from "../constants"

const addPickupSlotMethod = async (newPickupSlotMethod: any) => {
    const response = await axios.post(
        `${ORDER_MICRO_SERIVCE}/capacity/available-slots`,
        newPickupSlotMethod
    );
    return response.data.data;
};

export default function useAddPickupSlotMethod() {
    return useMutation((data: { newPickupSlotMethod: any }) =>
        addPickupSlotMethod(data.newPickupSlotMethod)
    );
}
