import { CUSTOMER_MICRO_SERVICE } from "../constants";
import { getUser } from "../helpers/helperMethods";
import { DeliveryAddressFromFields, IAddRewardToDiscountPayload, EmailPayload, SignupFormFields } from "../models/forms.model";
import {IAddBarcodePayload, IGetUserLoyaltyPoints, IUpdatePhonePayload} from "../models/rewards.model";
import { vehicleInfoProps } from "../react-query-hooks/useAddVehicleInfo";
import { HttpService } from "./base.service";

class CustomerBusinessService extends HttpService {
  baseURL = CUSTOMER_MICRO_SERVICE;
  user = getUser();
  
  login = (body: any): Promise<any> => this.post(`login`,body);
  kioskLogin = (body: any): Promise<any> => this.post(`kiosk/login`,body);
  kioskSetting = (kisok_id: any): Promise<any> => this.get(`getKioskSetting/${kisok_id}`);
  logout = (): Promise<any> => this.post(`logout`, null, {headers : {'Refresh-Token': this.user.refresh_token}})
  guestLogin = (body: any): Promise<any> => this.post(`guest-customer`,body);
  createAccount = (body: SignupFormFields): Promise<any> => this.post(`customers`,body);
  changePassword = (body: any): Promise<any> => this.patch(`change-password`,body);
  subscribeEmail = (body: any): Promise<any> => this.post('email-subscription', body);
  verifyEmailLink = (body: any): Promise<any> => this.post('verify-email', body);
  resendVerificationEmail = (body: EmailPayload): Promise<any> => this.post(`resend-verify-email`,body);
  verifyLink = (body: any): Promise<any> => this.post(`verify-link`,body);
  resetPassword = (body: EmailPayload): Promise<any> => this.post(`reset-link`,body);
  forgotPassword = (body: EmailPayload): Promise<any> => this.post(`forgot-password`,body);
  addRewardToDiscount = (body: IAddRewardToDiscountPayload): Promise<any> => this.post('addToDiscountApplied', body);
  getMyRewards = (): Promise<any> => this.get(`loyalty/user-rewards`);
  getOffers = (): Promise<any> => this.get(`loyalty/offers`);
  locationAddToFavorites = (data: {customer_id:number,locations:number[]}): Promise<any> => this.post(`locations`, data);
  removeFavoritesLocation = (location_id: string, userId: string | number): Promise<any> => this.delete(`locations/${location_id}/${userId}`);
  addMyRewards = (body: any): Promise<any> => this.post('loyalty/purchase-reward', body);
  addAddress = (deliveryAddress: DeliveryAddressFromFields): Promise<any> => this.post('addresses',deliveryAddress);
  updateAddress = (addressId: number, body: any): Promise<any> => this.put(`addresses/${addressId}`,body)
  removeAddress = (addressId: number): Promise<any> => this.delete(`addresses/${addressId}`)
  updateCustomerProfile = (body: any): Promise<any> => this.put(`customers`,body)
  getCustomerProfile = (userId: number | string, body: any): Promise<any> => this.get(`customers/${userId}`,body);
  getCustomerRewards = (id: string): Promise<any> => this.get(`customer/rewards/${id}`);
  removeVehicle = (vehicleId: number): Promise<any> => this.delete(`vehicle-info/${vehicleId}`)
  addVehicleInformation = (body: vehicleInfoProps): Promise<any> => this.post('vehicle-info', body);
  updateVehicleDetails = (vehicleId: number | string, body: any): Promise<any> => this.put(`vehicle-info/${vehicleId}`,body)
  getVehicles = (): Promise<any> => this.get(`vehicles`)
  addBarcode = (body: IAddBarcodePayload): Promise<any> => this.post(`loyalty-checkin-by-barcode`,body)
  deleteUser = (userId: number|string ):Promise<any> => this.delete(`customers/${userId}`)
  getUserLoyaltyPoints = (body: IGetUserLoyaltyPoints): Promise<any> => this.get(`loyalty/user-points`,body)
  getRewardCatalog = (customer_id, data): Promise<any> => this.get(`loyalty/rewards`);
  updateReward = (reward_id, data): Promise<any> => this.patch(`loyalty/user-reward/${reward_id}`, data);
  applyPromoCode = (body): Promise<any> => this.get(`loyalty/apply-promo`, body);
  getUserProfileStatus = (id: number): Promise<any> => this.get(`customers/profile-status/${id}`);
  updateCustomerPhone = (date: IUpdatePhonePayload): Promise<any> => this.patch(`customers`, date);
  getCustomerGiftCards = (): Promise<any> => this.get(`customer-gift-cards`);
  removeGiftCard = (cardId: string): Promise<any> => this.delete(`customer-gift-cards/${cardId}`);
  addGiftCard = (data: any): Promise<any> => this.post('customer-gift-cards',data);
  updateGiftCard = (cardId : string, data: any): Promise<any> => this.patch(`customer-gift-cards/${cardId}`, data);
  triggerCampaign = (data: any): Promise<any> => this.post('loyalty/link-campaign-trigger',data);
  lookUpGiftCard = (number: any): Promise<any> => this.get(`gift-card/${number}/balance`);
}

export const customerBusinessService = new CustomerBusinessService();