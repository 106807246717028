import {
  cartItem,
} from "../../models/cart.model";
import { IRewardItem } from "../../models/rewards.model";
import {toFixedNumber} from "../../priceCalculation/helper";

export const isIndexFound = (item, suggestedItem) => {
  let isItemRelatedIdSame = null;
  if (suggestedItem.redirect_to_item) {
    isItemRelatedIdSame = item.item_id == suggestedItem.redirect_item_id;
  } else {
    isItemRelatedIdSame =
      item.category_id == suggestedItem.redirect_category_id;
  }
  const isItemRedeemable = item.reward_redeemable;
  const isItemRedeemed = item.reward_redeemed;
  const areItemRedeemChecksValid = isItemRedeemable || isItemRedeemed;

  const isItemQuantityEqualTo1 = !(item.quantity > 1);
  const isItemNotRedeemedByPoints = !item.redemption_by_points;
  const isItemSizeSame =
    item.item_size.toLowerCase() ==
    suggestedItem.redirect_item_size.toLowerCase();

  return (
    areItemRedeemChecksValid &&
    isItemNotRedeemedByPoints &&
    isItemRelatedIdSame &&
    isItemSizeSame &&
    isItemQuantityEqualTo1
  );
};

export const isItemMappedWithReward = (
  item: cartItem,
  suggestedItem: IRewardItem
) => {
  return item.reward_id == suggestedItem.loyalty_id && item.quantity === 1;
};

export const getPointsRedeemedInCart = (cartItems) =>
  cartItems.reduce((accumulator: number, item: cartItem) => {
    if (item.redemption_by_points) return accumulator + item.redeem_points;
    return accumulator;
  }, 0);


export const cartSubTotal = (cartItems: cartItem[]) => {
  let itemDiscount = 0;
  let subTotal = 0;
  cartItems.forEach((item: cartItem) => {
    const price = item.gross_price ?? item.display_price
    subTotal += toFixedNumber(price * item.quantity)
    if (item?.reward_redeemed || item.redemption_by_points) {
      itemDiscount +=  (item.discount_amount || item.discount || item.base_price)
    }
  });
  return toFixedNumber(subTotal - itemDiscount)
}