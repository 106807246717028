export const item = {
  id: 27,
  name: "Tomato Basil",
  sub_title: null,
  is_featured: 0,
  description:
    "Vine Ripe Tomatoes, Fresh Basil, Chopped Garlic, Parmesan Cheese, Sautéed Celery & Onions, Extra Virgin Olive Oil, & Spices In A Creamy Broth",
  calories: "280",
  calories_label: "Half",
  full_calories: "560",
  full_calories_label: "Large",
  price: 20,
  make_time_minutes: null,
  image: require("../../images/Menu/tab_menu_img.png"),
  created_at: "2021-06-22 13:02:46",
  updated_at: "2021-06-22 13:02:46",
  deleted_at: null,
  category_id: 3,
  protein: null,
  is_active: true,
  is_86_able: 0,
  reward_catalog: 0,
  full_loyalty_points: null,
  half_loyalty_points: null,
  full_brink_item_id: 1,
  half_brink_item_id: 2,
  half_brink_item_price: 10,
  item_location: [
    {
      status: null,
    },
  ],
};

export const dressings = [
  {
    id: 1,
    title: "Buttermilk Ranch",
    image: require("../../images/Menu/image-228.jpg"),
    calories: 100,
    max: 1,
    min: 0,
    brink_modifier_id: 888,
    price: 1.69,
    modifier_location: [
      {
        status: null,
      },
    ],
  },
  {
    id: 2,
    title: "Buttermilk Ranch",
    image: require("../../images/Menu/image-228.jpg"),
    calories: 100,
    max: 1,
    min: 0,
    brink_modifier_id: 888,
    price: 1.69,
    modifier_location: [
      {
        status: "86",
      },
    ],
  },
  {
    id: 3,
    image: require("../../images/dressing1.jpg"),
    title: "Poppyseed",
    calories: 100,
    max: 5,
    min: 0,
    brink_modifier_id: 888,
    price: 1.69,
    modifier_location: [
      {
        status: null,
      },
    ],
  },
  {
    id: 4,
    image: require("../../images/dressing2.jpg"),
    title: "Avocado Herb",
    calories: 100,
    max: 1,
    min: 0,
    brink_modifier_id: 888,
    price: 1.69,
    modifier_location: [
      {
        status: "86",
      },
    ],
  },
  {
    id: 5,
    image: require("../../images/dressing3.jpg"),
    title: "Chipotle Ranch",
    calories: 100,
    max: 1,
    min: 0,
    brink_modifier_id: 888,
    price: 1.69,
    modifier_location: [
      {
        status: "86",
      },
    ],
  },
];

export const itemAddOns = [
  {
    id: 1,
    brink_modifier_group_id: 123,
    label: "Complementary Sides",
    modifiers: [
      {
        id: 6,
        image: require("../../images/Menu/image-430.jpg"),
        title: "Chocolate Dipped Strawberry",
        calories: 100,
        max: 10,
        min: 0,
        price: 0.69,
        brink_modifier_id: 8,
      },
      {
        id: 7,
        image: require("../../images/Menu/image-430.jpg"),
        title: "Chocolate Dipped Strawberry",
        calories: 100,
        max: 1,
        min: 0,
        price: 0.69,
        brink_modifier_id: 8,
      },
      {
        id: 8,
        image: require("../../images/Menu/image-430.jpg"),
        title: "Chocolate Dipped Strawberry",
        calories: 100,
        max: 10,
        min: 0,
        price: 0.69,
        brink_modifier_id: 8,
      },
    ],
  },
];

export const preSelectedIngredients = [
  {
    id: 13,
    image: require("../../images/ingredients.png"),
    title: "Baby Butter Lettuce",
    calories: 100,
    max: 10,
    min: 0,
    modifier_group_name: "Protein",
    alternate: [
      {
        id: 14,
        image: require("../../images/Menu/image-512.jpg"),
        title: "Baby Butter Lettuce",
        calories: 100,
        price: 0.69,
        max: 1,
        min: 0,
      },
      {
        id: 15,
        image: require("../../images/Menu/image-512.jpg"),
        title: "Baby Butter Lettuce",
        calories: 100,
        price: 0.69,
        max: 10,
        min: 0,
      },
      {
        id: 16,
        image: require("../../images/Menu/image-512.jpg"),
        title: "Baby Butter Lettuce",
        calories: 100,
        price: 0.69,
        max: 1,
        min: 0,
      },
    ],
  },
  {
    id: 17,
    image: require("../../images/Menu/image-512.jpg"),
    title: "Baby Butter Lettuce",
    calories: 100,
    max: 10,
    min: 0,
    modifier_group_name: "Protein",
    alternate: [
      {
        id: 18,
        image: require("../../images/Menu/image-512.jpg"),
        title: "Baby Butter Lettuce",
        calories: 100,
        price: 0.69,
        max: 10,
        min: 0,
      },
      {
        id: 19,
        image: require("../../images/Menu/image-512.jpg"),
        title: "Baby Butter Lettuce",
        calories: 100,
        price: 0.69,
        max: 10,
        min: 0,
      },
      {
        id: 20,
        image: require("../../images/Menu/image-512.jpg"),
        title: "Baby Butter Lettuce",
        calories: 100,
        price: 0.69,
        max: 1,
        min: 0,
      },
    ],
  },
  {
    id: 21,
    image: require("../../images/Menu/image-512.jpg"),
    title: "Baby Butter Lettuce",
    calories: 100,
    max: 1,
    min: 0,
    modifier_group_name: "Protein",
    alternate: [
      {
        id: 22,
        image: require("../../images/Menu/image-512.jpg"),
        title: "Green Lettuce",
        calories: 100,
        price: 0.69,
        max: 1,
        min: 0,
      },
      {
        id: 23,
        image: require("../../images/Menu/image-512.jpg"),
        title: "Baby Butter Lettuce",
        calories: 100,
        price: 0.69,
        max: 10,
        min: 0,
      },
      {
        id: 24,
        image: require("../../images/Menu/image-512.jpg"),
        title: "Baby Butter Lettuce",
        calories: 100,
        price: 0.69,
        max: 1,
        min: 0,
      },
    ],
  },
  {
    id: 25,
    image: require("../../images/Menu/image-512.jpg"),
    title: "Baby Butter Lettuce",
    calories: 100,
    max: 10,
    min: 0,
    modifier_group_name: "Protein",
    alternate: [
      {
        id: 26,
        image: require("../../images/Menu/image-512.jpg"),
        title: "Baby Butter Lettuce",
        calories: 100,
        price: 0.69,
        max: 10,
        min: 0,
      },
      {
        id: 27,
        image: require("../../images/Menu/image-512.jpg"),
        title: "Baby Butter Lettuce",
        calories: 100,
        price: 0.69,
        max: 10,
        min: 0,
      },
      {
        id: 28,
        image: require("../../images/Menu/image-512.jpg"),
        title: "Baby Butter Lettuce",
        calories: 100,
        price: 0.69,
        max: 1,
        min: 0,
      },
    ],
  },
];

export const modifierGroups = [
  {
    id: 29,
    name: "Suggested",
    items: [
      {
        id: 30,
        image: require("../../images/Menu/image-512.jpg"),
        title: "Baby Butter Lettuce",
        calories: 100,
        price: 0.69,
        max: 10,
        min: 0,
      },
      {
        id: 31,
        image: require("../../images/Menu/image-512.jpg"),
        title: "Baby Butter Lettuce",
        calories: 100,
        price: 0.69,
        max: 10,
        min: 0,
      },
      {
        id: 32,
        image: require("../../images/Menu/image-512.jpg"),
        title: "Baby Butter Lettuce",
        calories: 100,
        price: 0.69,
        max: 10,
        min: 0,
      },
      {
        id: 33,
        image: require("../../images/Menu/image-512.jpg"),
        title: "Baby Butter Lettuce",
        calories: 100,
        price: 0.69,
        max: 10,
        min: 0,
      },
    ],
  },
  {
    id: 34,
    name: "Proteins",
    items: [
      {
        id: 35,
        image: require("../../images/Menu/image-512.jpg"),
        title: "Baby Butter Lettuce",
        calories: 100,
        max: 1,
        min: 0,
      },
      {
        id: 36,
        image: require("../../images/Menu/image-512.jpg"),
        title: "Baby Butter Lettuce",
        calories: 100,
        max: 1,
        min: 0,
      },
      {
        id: 37,
        image: require("../../images/Menu/image-512.jpg"),
        title: "Baby Butter Lettuce",
        calories: 100,
        max: 1,
        min: 0,
      },
    ],
  },
  {
    id: 38,
    name: "Dairy",
    items: [
      {
        id: 39,
        image: require("../../images/Menu/image-430.jpg"),
        title: "Chocolate Dipped Strawberry",
        calories: 100,
        max: 10,
        min: 0,
      },
      {
        id: 40,
        image: require("../../images/Menu/image-430.jpg"),
        title: "Chocolate Dipped Strawberry",
        calories: 100,
        max: 10,
        min: 0,
      },
      {
        id: 41,
        image: require("../../images/Menu/image-430.jpg"),
        title: "Chocolate Dipped Strawberry",
        calories: 100,
        max: 10,
        min: 0,
      },
    ],
  },
];
