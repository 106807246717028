import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import styleClasses from "./NavbarComponent.module.scss";
import { Navbar, Button, Collapse, Modal } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { NavlinkModel } from "../../models/navlink.model";
import * as CONSTANT from "../../constants";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import UserNavComponent from "./UserNavComponent";
import GuestNavComponent from "./GuestNavComponent";
import MegaMenuDropDown from "../../../src/components/MegaMenuDropDown/MegaMenuDropDown";
import { useAuthState } from "../../context/UserAuthentication/context";
import useGetMenuCategories from "../../react-query-hooks/useGetCategories";
import { getUser } from "../../helpers/helperMethods";
import { brazeLogCustomEventViewPage } from "../../helpers/brazeHelper";
import { CustomModal } from "../Modal/CustomModal";
import { ArrowRightIcon, NotesIcon } from "../../images/svgIcons/svg";
import CareerNavComponent from "./careerNavComponent";

export interface LoginInterface {
  isHeader: any;
  Navlinks: NavlinkModel[];
  classes: string;
  expand: any;
  footer_Nav?: boolean;
  footer_controller?: string;
  footer_mobile_controller?: string;
  modalOpen?: () => void;
  userId?: string;
  shouldShowLiveTracker?: boolean;
  showLoyaltyBanner?: boolean;
  ignorePopUpIdCheck?: boolean;
  careerNav?: any;
}

const NavbarComponent: React.FunctionComponent<LoginInterface> = ({
  isHeader,
  Navlinks,
  classes,
  expand,
  footer_Nav,
  modalOpen,
  footer_controller,
  footer_mobile_controller,
  userId,
  shouldShowLiveTracker,
  showLoyaltyBanner,
  ignorePopUpIdCheck = false,
  careerNav,
}) => {
  const [expanded, setExpanded] = React.useState(false);
  const [signIn_Option, setsignIn_Option] = React.useState<Boolean>();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [openGlutenModal, setOpenGlutenModal] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const history = useHistory();
  const { dispatch, authInfo } = useAuthState();
  const user = getUser();
  const userType = user.type ?? "";
  const cartDispatch = useDispatch();

  const { data: subMenus = [] } = useGetMenuCategories();

  const handleMenuClick = (subMenu) => {
    history.push(
      `${CONSTANT.ROUTE_MENU}/${subMenu.name.replace(/\s/g, "%20")}`
    );
    history.go(0);
    setExpanded(false);
  };
  const handleModalAction = (item) => {
    if (!ignorePopUpIdCheck) {
      item.link == "#popup" ? modalOpen() : setExpanded(false);
    } else {
      setExpanded(false);
    }
  };

  useEffect(() => {
    if (typeof globalThis.callMade == "undefined") {
      // get_SubMenus();
      globalThis.callMade = true;
    }
    let window_height = window.innerHeight * 0.01;
    document.documentElement.style.setProperty(
      "--window_height",
      `${window_height}px`
    );
    window.addEventListener("resize", () => {
      let window_height = window.innerHeight * 0.01;
      document.documentElement.style.setProperty(
        "--window_height",
        `${window_height}px`
      );
    });
    if (location.pathname == CONSTANT.ROUTE_CATERING) {
      setsignIn_Option(true);
    } else {
      setsignIn_Option(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    let body = document.querySelector("body");
    if (expanded) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "initial";
    }
  }, [expanded]);

  const loyaltyRedirectionLink = (item) => {
    return userType === CONSTANT.GUEST_USER || user?.logged_out
      ? item.link
      : item.alternateURL.exist
      ? item.alternateURL.link
      : item.link;
  };

  return (
    <Navbar
      className={` ${styleClasses.nav_wrapper} ${styleClasses.header_content} ${
        footer_Nav ? styleClasses.Footer_nav_wrapper : ""
      }`}
      expand={expand}
      expanded={expanded}
    >
      {isHeader ? (
        <>
          <Navbar.Toggle
            className={` p-0 ${styleClasses.nav_toggle_btn}`}
            aria-controls="basic-navbar-nav"
          >
            <div
              id={styleClasses.navIcon}
              className={expanded ? styleClasses.open : ""}
              onClick={() => setExpanded(expanded ? false : true)}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </Navbar.Toggle>
        </>
      ) : (
        ""
      )}
      <ConditionalWrapper
        condition={isHeader}
        wrapper={(children) => (
          <Navbar.Collapse
            id="basic-navbar-nav"
            className={`${
              shouldShowLiveTracker 
                ? "with-track-basic-navbar"
                : "no-track-basic-navbar"
            }`}
          >
            {children}
          </Navbar.Collapse>
        )}
      >
        <Fragment>
          <div className="w-100">
            {isHeader && <hr className="shadow-divider  border-0 mb-2"></hr>}
            <div
              className={
                footer_mobile_controller
                  ? `${footer_mobile_controller} w-100`
                  : "d-md-none w-100 px-4"
              }
            >
              <Button
                onClick={() => setOpen(!open)}
                aria-controls="example-collapse-text"
                aria-expanded={open}
                className={`${
                  styleClasses.sub_menu_btn
                } d-flex w-100 align-items-center justify-content-between ${
                  shouldShowLiveTracker || showLoyaltyBanner
                    ? null
                    : styleClasses.menu_button_mobile
                }`}
              >
                OUR MENU
              </Button>
              <Collapse in={open}>
                <ul className="ps-0 mb-0 pt-0">
                  <div className="d-flex justify-content-center">
                    <button
                      className="btn btn-large outline my-4"
                      onClick={() => {
                        history.push(CONSTANT.ROUTE_MENU);
                        setExpanded(false);
                      }}
                    >
                      view Full menu{" "}
                    </button>
                  </div>

                  <ul
                    className={`${styleClasses.sub_menu_list} list-inline ps-0 mb-0`}
                  >
                    {subMenus.length > 0 ? (
                      subMenus.map((subMenu) => {
                        return (
                          <li
                            key={subMenu.name}
                            className={styleClasses.sub_menu_item}
                            onClick={() => handleMenuClick(subMenu)}
                          >
                            <div className={styleClasses.menuListingOnMobile}>
                              <div className="d-flex align-items-center">
                                <img
                                  src={`${
                                    CONSTANT.REACT_APP_CLOUD_FRONT
                                  }/images/Menu/${subMenu.name
                                    .replace(/'/g, "")
                                    .toLowerCase()}.png`}
                                  className="img-fluid"
                                  alt={subMenu.name}
                                />
                                <h6> {subMenu.name}</h6>
                              </div>

                              <div>
                                <ArrowRightIcon />{" "}
                              </div>
                            </div>
                          </li>
                        );
                      })
                    ) : (
                      <h5 className="ps-3 text-style2">Loading...</h5>
                    )}
                    {authInfo.type !== CONSTANT.GUEST_USER && (
                      <>
                        <li
                          className={styleClasses.sub_menu_item}
                          onClick={() => {
                            history.push(CONSTANT.VIEW_MORE_RECENT_ORDER);
                            setExpanded(false);
                          }}
                        >
                          <div className={styleClasses.menuListingOnMobile}>
                            <div>
                              {" "}
                              <NotesIcon />
                            </div>

                            <div className="w-100 ms-2">
                              {" "}
                              <h6>Recent Orders</h6>
                            </div>

                            <div>
                              <ArrowRightIcon />
                            </div>
                          </div>
                        </li>
                      </>
                    )}
                  </ul>
                </ul>
              </Collapse>{" "}
            </div>
            {Navlinks.map((item, i) => {
              if (item.link == CONSTANT.TRANSPARENCY_IN_COVERAGE) {
                return (
                  <a
                    key={item.text + "_" + i}
                    onClick={() => {
                      setOpenGlutenModal(true);
                    }}
                    className={
                      classes +
                      " nav-link links   " +
                      styleClasses.menu_links_item
                    }
                  >
                    *Gluten Sensitive
                  </a>
                );
              } else
                return (
                  <>
                    <NavLink
                      exact
                      className={`${classes} nav-link links ${styleClasses.menu_links_item}`}
                      activeClassName={isHeader ? "active" : ""}
                      target={item.link.indexOf(".pdf") !== -1 ? "_blank" : ""}
                      to={"" + loyaltyRedirectionLink(item)}
                      key={i}
                      onClick={() => {
                        if (item.link === CONSTANT.CATERING_CAFE_ZUPAS_URL) {
                          window.open(CONSTANT.CATERING_CAFE_ZUPAS_URL);
                          brazeLogCustomEventViewPage("Catering", false);
                          return;
                        }
                        return handleModalAction(item);
                      }}
                    >
                      {item.text}
                    </NavLink>
                  </>
                );
            })}

            {/* <NavLink
              to={CONSTANT.ROUTE_OUR_KITCHEN}
              className={`${classes} nav-link links ${styleClasses.menu_links_item}`}
              activeClassName={isHeader ? "active" : ""}
              onClick={()=>setExpanded(false)}
            >
              OUR KITCHEN
            </NavLink> */}
             <div
                className={
                  footer_mobile_controller
                    ? `${footer_mobile_controller}`
                    : "d-md-none w-100 cz-px-24"
                }
              >
                <CareerNavComponent />
              </div>
          </div>
          <div className="d-md-none user-profile-details px-4 text-center">
            {authInfo.type !== CONSTANT.GUEST_USER && userId ? (
              <UserNavComponent
                setExpanded={setExpanded}
                userId={userId}
                userType={userType}
                userRefreshToken={authInfo.refresh_token}
              />
            ) : (
              <GuestNavComponent setExpanded={setExpanded} />
            )}
          </div>
          <div
            className={
              footer_controller
                ? `${footer_controller} w-100`
                : " d-md-flex d-none w-100"
            }
          >
            {Navlinks.map((item, i) => {
              if (item.link == CONSTANT.TRANSPARENCY_IN_COVERAGE) {
                return (
                  <a
                    key={item.text + "_" + i}
                    onClick={() => {
                      setOpenGlutenModal(true);
                    }}
                    className={
                      classes +
                      " nav-link links text-uppercase " +
                      styleClasses.menu_links_item
                    }
                  >
                    *Gluten Sensitive
                  </a>
                );
              } else
                return (
                  <NavLink
                    exact
                    className={
                      classes +
                      " nav-link links " +
                      styleClasses.menu_links_item
                    }
                    activeClassName={isHeader ? "active" : ""}
                    target={item.link.indexOf(".pdf") !== -1 ? "_blank" : ""}
                    to={"" + loyaltyRedirectionLink(item)}
                    key={i}
                    onClick={() => {
                      if (item.link === CONSTANT.CATERING_CAFE_ZUPAS_URL) {
                        window.open(CONSTANT.CATERING_CAFE_ZUPAS_URL);
                        brazeLogCustomEventViewPage("Catering", false);
                        return;
                      }
                      return item.link == "#popup"
                        ? () => modalOpen()
                        : () => setExpanded(false);
                    }}
                  >
                    {item.text}
                  </NavLink>
                );
            })}
          </div>
        </Fragment>
      </ConditionalWrapper>
      {/* Download App Modal */}
      <Modal show={show} onHide={handleClose} className="download_app_modal">
        <Modal.Body className="text-center app_container_wrap p-0">
          <div className="text-end me-n2">
            <button
              type="button"
              onClick={handleClose}
              className="btn btn-link theme_modal_close_button mb-3"
            >
              Close
            </button>
          </div>
          <h5 className="text-uppercase">DOWNLOAD the Café Zupas app!</h5>
          <p className="text_style1">
            Have your favorites delivered right to your door or skip the line if
            you prefer to pick up your order at the restaurant.
          </p>
          <div className={`${styleClasses.modal_mockup_img} text-center`}>
            <img
              src={`${CONSTANT.REACT_APP_CLOUD_FRONT}/images/Header/download-app-mockup.png`}
              className="img-fluid"
              alt="app mockup"
            />
          </div>
          <div
            className={`${styleClasses.download_app_btns} d-flex align-items-center justify-content-center`}
          >
            <a
              href={CONSTANT.APPSTORE_CAFEZUPAS_URL}
              className={`${styleClasses.app_store_btn} pe-3`}
            >
              <img
                src={`${CONSTANT.REACT_APP_CLOUD_FRONT}/images/Header/App-store-badge.png`}
                className="img-fluid"
                alt="store badge"
              />
            </a>
            <a
              href={CONSTANT.PLAYSTORE_CAFEZUPAS_URL}
              className={styleClasses.google_play_btn}
            >
              <img
                src={`${CONSTANT.REACT_APP_CLOUD_FRONT}/images/Header/google-play-badge.png`}
                className="img-fluid"
                alt="play badge"
              />
            </a>
          </div>
        </Modal.Body>
      </Modal>
    </Navbar>
  );
};
const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

export default NavbarComponent;
