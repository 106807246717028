import React, { useState } from "react";
import * as CONSTANT from "../../../constants";
interface IItemModifiersProps {
  modifiers: any[];
  paragraphClassName?: string;
  showMore: boolean;
}

const ComboItemModifiers: React.FC<IItemModifiersProps> = ({
  modifiers,
  paragraphClassName,
  showMore
}) => {
  if (modifiers.length === 0) return null;
  
  const handleModifierCode = (code: string, modifier: any) => {
    const quantity = modifier.quantity > 1 ? `(${modifier.quantity})` : ''
    switch (code) {
      case CONSTANT.ADD:
        return <span className="ms-0 mb-2 clr-dark-blue">{`+ ${modifier?.modifier_name} ${quantity}`}</span>;
      case CONSTANT.NO:
        return <span className="ms-0 mb-2 clr-dark-blue">{`- ${modifier?.modifier_name} ${quantity}`}</span>;
      case CONSTANT.SUB:
        return (
          <>
            <span className="ms-0 mb-2 clr-dark-blue">{`+ ${modifier?.substituted_modifier?.modifier_name}`}</span>
            <span className="ms-0 mb-2 clr-dark-blue">{`-${modifier?.modifier_name}`}</span>
          </>
        );
      default:
        return null;
    }
  };
  const sliceTill = showMore ? modifiers.length : 1;
  return (
    modifiers.length > 0 && (
      <p className={paragraphClassName ? paragraphClassName : ""}>
        {modifiers.slice(0, sliceTill).map((modifier: any, index: number) => {
          return (
            <span key={index}>
              {handleModifierCode(modifier.code, modifier)}
              <br />
            </span>
          );
        })}

      </p>
    )
  );
};

export default ComboItemModifiers;
