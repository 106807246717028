import React, { useEffect, useState } from "react";
import useProfile from "../../../react-query-hooks/useProfile";
import useRemoveAddress from "../../../react-query-hooks/useRemoveAddress";
import { useQueryClient } from "react-query";
import { API_ERROR } from "../../../models/error.model";
import { Toast_Func } from "../../../helpers/toast.helper";
import { useAuthState } from "../../../context/UserAuthentication";
import DeliveryadressMobileDesign from "./DeliveryadressMobileDesign";
import DeliveryAdressDesktop from "./DeliveryAdressDesktop";
import useUpdateAddress from "../../../react-query-hooks/useUpdateAddress";
import {ADD_ADDRESS_MODAL, DELIVERY_ORDER_TYPE} from "../../../constants";
import DefaultMethodConfirmationModal from "../DefaultMethodConfirmation/DefaultMethodConfirmation";
import {useAppSelector} from "../../../redux/store/store";
import useCheckMobileScreen from "../../../hooks/useCheckMobileScreen";
const DeliveryAdressListing = ({ showModal }) => {
  const { authInfo } = useAuthState();
  const { isLoading, isFetching, data: userProfileData, error } = useProfile(authInfo.userId);
  const [showRemoveDelivery, setRemoveDelivery] = useState(false);
  const [ isRemovingAddress, setIsRemovingAddress ] = useState<boolean>(false);
  const [removeAddressId, setRemoveAddressId] = useState<null | number>();
  const { mutateAsync } = useUpdateAddress();
  const [defaultAddress, setDefaultAddress] = useState(null);
  const { mutate } = useRemoveAddress();
  const queryClient = useQueryClient();
  const orderTypeSelected = useAppSelector(
      (state) => state.cart.orderType
  );
  const savedOrderDetails = useAppSelector(
      (state) => state.cart.orderDetails
  );
  const isItMobile = useCheckMobileScreen()
  useEffect(() => {
    if (!defaultAddress) {
      setDefaultAddress(userProfileData.addresses.find((address) => address.is_default == true));
    }
  }, [defaultAddress]);
  const isSelectedForDelivery = (id: number) => {
    if(orderTypeSelected == DELIVERY_ORDER_TYPE)
    {
      return savedOrderDetails && savedOrderDetails.delivery_address.id == id
    }
    else
      return false
  }
  const handleChangeDefault = async (id) => {
    const changedRecord = userProfileData.addresses.find((address) => address.id == id);
    setDefaultAddress(changedRecord);
    return mutateAsync(
      {
        deliveryAddress: { ...changedRecord, is_default: 1 },
        addressId: id,
        authentication_token: authInfo.authentication_token,
      },
      {
        onSuccess: (data) => {
          queryClient.invalidateQueries("profile");
          Toast_Func({ status: true, message: "Default updated successfully" });
        },
        onError: (error) => {
          console.log(error);
          Toast_Func({ status: false, message: error });
          setDefaultAddress(userProfileData.addresses.find((rec) => rec.is_default == true));
        },
      }
    );
  };
  const removeAddress = (id: number) => {
    setIsRemovingAddress(true)
    if(!isSelectedForDelivery(id))
    {
      mutate(
          {
            id: id,
            access_token: authInfo.authentication_token
          },
          {
            onSuccess: (data, variables, context) => {
              queryClient.invalidateQueries("profile");
              setRemoveDelivery(false);
              setIsRemovingAddress(false)
              Toast_Func({
                status: true,
                message: "Address removed successfully",
              });
            },
            onError: (error, variables, context) => {
              setRemoveDelivery(false);
              setIsRemovingAddress(false)
              Toast_Func({
                status: false,
                message: (error as API_ERROR).response.data["message"],
              });
            },
          }
      );
    }
    else {
      Toast_Func({
        status: false,
        message: "Address selected for order delivery can not be deleted",
      })
      setRemoveDelivery(false);
      setIsRemovingAddress(false)
    }
  };
  return (
    <div>
      {userProfileData?.addresses.map((address: any, index: number) => (
        <li key={index}>
          {isItMobile ?
          <DeliveryadressMobileDesign
            address={address}
            defaultAddress={defaultAddress}
            handleChangeDefault={handleChangeDefault}
            setRemoveDelivery={setRemoveDelivery}
            setRemoveAddressId={setRemoveAddressId}
            showModal={showModal}
            addressCount={userProfileData?.addresses.length}
          /> :
          <DeliveryAdressDesktop
            address={address}
            defaultAddress={defaultAddress}
            handleChangeDefault={handleChangeDefault}
            setRemoveDelivery={setRemoveDelivery}
            setRemoveAddressId={setRemoveAddressId}
            showModal={showModal}
            addressCount={userProfileData?.addresses.length}
          />
         }
        </li>
      ))}
      <hr className="custom_hr_sty mb-4 mt-5  d-md-none" />
      <div className="d-flex justify-content-center mt-4">
        <button
          type="button"
          className="btn btn-large py-2 f-s16"
          onClick={() => {
            showModal(ADD_ADDRESS_MODAL);
          }}
          disabled={userProfileData?.addresses.length >= 5}
        >
          Add delivery address
        </button>
      </div>
      <DefaultMethodConfirmationModal
        show={showRemoveDelivery}
        title=" Remove This delivery address"
        content=" You’re requesting to remove this delivery address. Would you like to continue?"
        btnText={"remove"}
        handleClick={(e) => {
          if ( isRemovingAddress ) return;
          removeAddress(removeAddressId);
        }}
        shouldDisableButton={isRemovingAddress}
        handleClose={() => {
          setRemoveDelivery(false);
        }}
      />
    </div>
  );
};

export default DeliveryAdressListing;
