import { useMutation } from "react-query";
import { paymentService } from "../services";

const removePaymentMethod = async (methodId: string | number, customer_id: string | number) => {
    const response = await paymentService.removePaymentMethod(methodId, customer_id)
    return response.data.data;
};

export default function useRemovePaymentMethod() {
    return useMutation((data: { id: string | number, customer_id: string | number }) =>
        removePaymentMethod(data.id, data.customer_id)
    );
}
