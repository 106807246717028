import { createSlice } from '@reduxjs/toolkit'

const initialStateValues = {
    isUserComingFromCartToLogin: false
}

export const specificFlowStates = createSlice({
    name: 'specificFlowStateHandling',
    initialState: initialStateValues,
    reducers: {
        userClickOnLoginFromCheckout:(state)=>{
            state.isUserComingFromCartToLogin = true;
        },
        resetIsUserComingFromCartToLoginState:(state) => {
            state.isUserComingFromCartToLogin = false
        }
    },
})

// Action creators are generated for each case reducer function
export const { userClickOnLoginFromCheckout, resetIsUserComingFromCartToLoginState } = specificFlowStates.actions


export const specificFlowStatesReducer = specificFlowStates.reducer;


