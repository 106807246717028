
import {cartItem} from "../../models/cart.model";


export const getStructuredItem = (item: cartItem, location_id: number) => {
    return {
        'item_id': item?.item_id?.toString(),
        'item_name': item?.name,
        'discount': item?.discount_amount || item?.discount || 0,
        'index': item?.index || 0,
        'item_brand': "Cafe Zupas",
        'item_category': item?.category_name?.toString() || item?.category_id?.toString(),
        'item_list_id': item?.category_id?.toString(),
        'item_list_name': item?.category_name || item?.name?.toString(),
        'location_id': location_id?.toString() || "15",
        'price': item?.display_price || item?.base_price || item?.price,
        'quantity': item?.quantity
    }
}

export const getStructuredItemList = (cartItems: cartItem[], location_id: number) => {
    let items =
        cartItems?.map((item: cartItem) => {
            return getStructuredItem(item, location_id)
        })
    return items
}


