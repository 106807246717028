import { useQuery } from "react-query";
import { customerBusinessService } from "../services";
import { GUEST_USER } from "../constants";

const getLoyaltyPoints = async (customer_id: string) => {
  const response = await customerBusinessService.getUserLoyaltyPoints({
    customer_id,
  });
  return response.data.data;
};

export default function useGetUsersLoyaltyPoints(
  customer_id: string,
  userType: string
) {
  return useQuery("userLoyaltyPoints", () => getLoyaltyPoints(customer_id), {
    enabled: userType !== GUEST_USER && !!customer_id,
  });
}