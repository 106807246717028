import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";

interface ModalProps {
  showModal: boolean;
  title: string;
  closeModal: () => void;
  children?: React.ReactNode;
  modalBodyClass?: string;

}

export const CustomModal: React.FunctionComponent<ModalProps> = ({
  showModal,
  title,
  closeModal,
  children,
  modalBodyClass
}) => {
  return (
    <Modal
      show={showModal}
      centered
      className="theme-modal-style app_container_wrap  border-radius-15 px-5"
      onHide={closeModal}
      size="lg"
    >
      <Modal.Header className="x-4">
        <button
          type="button"
          className="btn modal-close-button pe-0"
          onClick={closeModal}
        >
          Close
        </button>
        <h5 className="f-s22 font-Cls text-start ps-4 pt-4 mt-2 mb-0 text-capitalize w-75 full_width_mob">
          {title}
        </h5>
      </Modal.Header>
      <Modal.Body className={`${modalBodyClass ? modalBodyClass : ""} px-lg-5 pt-0`}>
        {children}
      </Modal.Body>
    </Modal>
  );
};
