import React, { useState } from "react";
import styleClasses from "../../../../containers/account/accounts.module.scss";
import { IGiftCard } from "../../../../models/giftCard.model";
interface IStoredGiftCardProps {
  record: IGiftCard;
  handleEdit: (record: IGiftCard) => void;
  defaultPayment: IGiftCard;
  paymentCount?: number;
  setConfirmDefaultPaymentMethodSelection: (boolean) => void;
  handleCardRemove: (id) => void;
}

const handleFormSubmission = async () => {
  console.log("🚀 ~ Submit");
  //TODO: API Integration
};

const StoredCardMobileView: React.FunctionComponent<IStoredGiftCardProps> = ({
  record,
  handleEdit,
  defaultPayment,
  paymentCount,
  setConfirmDefaultPaymentMethodSelection,
  handleCardRemove,
}) => {
  return (
    <>
      <div className="d-md-none">
        <div className="d-flex gap-3 pl-2">
          {paymentCount > 1 && (
            <div className="position-relative">
              <span className={styleClasses.select_radio_button}>
                <input
                  type="radio"
                  id={record.id?.toString()}
                  name={record.id + "mobileRadio"}
                  value={record.id}
                  checked={record.id == defaultPayment?.id}
                  onChange={() => {}}
                  onClick={() => {
                    setConfirmDefaultPaymentMethodSelection(true);
                  }}
                />
              </span>
            </div>
          )}
          <label htmlFor={`${record.id?.toString()} giftCard`} className="w-100">
            <div className={styleClasses.inner_list_wrap}>
              <div className="d-flex justify-content-between align-items-center">
                <span className={`${styleClasses.info_label} d-block`}>
                  Gift Card Name
                </span>
                {!record.is_shared && !record.is_cz_dollar_gift_card ? (
                  <div>
                    {" "}
                    <button
                      type="button"
                      className="btn btn-custom f-s16 text_underline clr-text-grey ms-3 p-0"
                      onClick={() => handleCardRemove(record.id)}
                    >
                      <img
                        src={require("../../../../../src/images/Remove.svg")}
                        alt="remove icon"
                      />
                    </button>
                    <button
                      type="button"
                      className="btn btn-custom f-s16 text_underline clr-text-grey ms-3 p-0"
                      onClick={() => handleEdit(record)}
                      data-testid="stored-payment-edit-button"
                    >
                      <img
                        src={require("../../../../../src/images/Edit.svg")}
                      />
                    </button>
                  </div>
                ) : null}
              </div>
              <span className="d-flex justify-content-between align-items-center">
                <span className={styleClasses.info_text}>{record.name}</span>
              </span>
            </div>
            <div className={`${styleClasses.inner_list_wrap} mb-4`}>
              <span className="d-flex justify-content-between align-items-center">
                <span className={`${styleClasses.info_label} d-block`}>
                  Gift Card Number
                </span>
              </span>
              <span
                className={`d-flex justify-content-between align-items-center ${styleClasses.info_text}`}
              >
                {record.gift_card_no}
              </span>
            </div>
          </label>
        </div>
      </div>
    </>
  );
};

export default StoredCardMobileView;
