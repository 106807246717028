import { useQuery } from "react-query";
import { StoredPaymentMethod } from "../models/payment.model";
import { paymentService } from "../services";
import {AUTH_USER, GUEST_USER} from "../constants";

const getPaymentMethods = async (customer_id: string) => {
    const response = await paymentService.getCustomerPaymentMethods(customer_id)
    return response?.data?.data?.data;
};

export default function usePaymentMethods(customer_id: string, userType: string = AUTH_USER) {

    return useQuery<StoredPaymentMethod[]>(
        ["paymentMethod", customer_id],
        () => getPaymentMethods(customer_id), {
            enabled: !!customer_id && userType !== GUEST_USER
        }
    );
}
