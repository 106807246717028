import React, { useEffect, useState } from "react";
import { formatDate, formatTime } from "../../../components/utils";
import {
  DELIVERY_ORDER_TYPE,
  PICK_UP_ORDER_TYPE,
  IN_STORE,
  CURBSIDE,
  NO_TIME_SLOTS_PRESENT,
} from "../../../constants";
import { getDestination } from "../../../helpers/checkout/PlaceOrderHelper";
import { getCurrentDate } from "../../../helpers/checkout/timeSlotHelper";

interface IOrderDetailProps {
  pickUpSlotDetails: any;
  orderType: string;
  isOrdered?: boolean;
  handleShowPickupMethodModal?: (boolean) => void;
  handleShowDeliveryMethodModal?: (boolean) => void;
  orderTypeValidationMessage?: string
  setOrderTypeValidationMessage?: (string) => void
  convertedDate?: string;
  pickUpMethod?: string,
}

const OrderDetailsPickupMethod = (props: IOrderDetailProps) => {
  const {
    isOrdered,
    handleShowPickupMethodModal,
    handleShowDeliveryMethodModal,
    pickUpSlotDetails,
    orderType,
    orderTypeValidationMessage,
    convertedDate = "",
    pickUpMethod, setOrderTypeValidationMessage
  } = props;
  const [isConvertedDate, setConvertedDate] = useState(convertedDate);
  const obj = {
    PICK_UP_ORDER_METHOD: "Pickup Method",
    DELIVERY_ORDER_METHOD: "Delivery",
  };

  useEffect(() => {
    if (!convertedDate && pickUpSlotDetails?.date) {
      if (pickUpSlotDetails?.time) setOrderTypeValidationMessage("")
      let time = "";
      time = formatTime(time, pickUpSlotDetails);
      const date = formatDate(pickUpSlotDetails.date);
      const dateStr = date + " @ " + time;
      setConvertedDate(dateStr);
    }
    else if (!convertedDate) {
      setConvertedDate(formatDate(getCurrentDate()) + "@" + NO_TIME_SLOTS_PRESENT)
    }
  }, [pickUpSlotDetails]);

  const handleModal = () => {
    if (orderType === PICK_UP_ORDER_TYPE) {
      handleShowPickupMethodModal(true);
    } else {
      handleShowDeliveryMethodModal(true);
    }
  };

  let orderTypeTitle = pickUpMethod || getDestination(pickUpSlotDetails?.pickupMethod)?.order_type
  if (orderTypeTitle === "Online Takeout") orderTypeTitle = IN_STORE
  if ([IN_STORE, CURBSIDE].includes(orderTypeTitle)) orderTypeTitle += " Pickup"
  if (orderType === DELIVERY_ORDER_TYPE) orderTypeTitle = "Delivery"

  return (
    <>
      <hr className="custom_hr_sty d-md-none d-block mt-5" />
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="f-s20 text-start h-22 text-uppercase font-Cls fw-normal f-sm-s16 pt-2">
          {orderType === PICK_UP_ORDER_TYPE
            ? obj.PICK_UP_ORDER_METHOD
            : obj.DELIVERY_ORDER_METHOD}
        </h4>
        <button
          className={`${isOrdered ? "d-none" : ""} btn btn-custom f-s16 text_underline clr-text-grey ms-3 p-0 d-md-none`}
          onClick={handleModal}
        >
          <img src={require("../../../../src/images/Edit.svg")} alt="edit"/>{" "}
        </button>
      </div>
      <div className="d-flex justify-content-between align-items-center pb-4 pb-md-0 pt-md-0 ">
        <div>
          <span className={"clr-dark-red f-s14 d-flex"}> {orderTypeValidationMessage} </span>
          {isConvertedDate && (
            <span className="d-block text-start font-rale f-s16 f-w5 f-sm-s14">
              {isConvertedDate}
            </span>
          )}
        </div>
        <div className={isOrdered ? "d-none" : ""} >
          <button
            className={`btn btn-custom f-s16 text_underline clr-text-grey ms-3 p-0 d-none d-md-block`}
            onClick={handleModal}
          >
            Edit
          </button>
        </div>
      </div>
      <hr className="custom_hr_sty d-md-block d-none my-4" />
    </>
  );
};

export default OrderDetailsPickupMethod;
