import React from "react";
import { Modal, Button } from "react-bootstrap";

interface IConfirmReorderModalProps {
  showModal: boolean;
  loading: boolean;
  handleClose: () => void;
  confirmChange: () => void;
}
const ConfirmReorderModal: React.FC<IConfirmReorderModalProps> = ({
  showModal,
  handleClose,
  confirmChange,
  loading
}) => {
  return (
    <>
      <Modal
        show={showModal}
        onHide={handleClose}
        centered
        className="theme-modal-style app_container_wrap no-padding border-radius-8 no-border"
      >
        <Modal.Header className="pt-0" id="close_button2">
          <button
            type="button"
            className="btn modal-close-button pe-0 blue-icon"
            onClick={handleClose}
          >
            Close
          </button>
        </Modal.Header>
        <Modal.Body className="text-center py-5">
          <h6 className="font-Cls ls-normal mb-sm-4 mb-3 f-s22 text-capitalize">Are you sure?</h6>
          <p className="f-s14 mb-4">
    Your current cart will be replaced with this order?
          </p>
          <div className="text-center">
            <button
              type="button"
              className="btn btn-large mb-sm-4 mb-3 d-block mx-auto"
              onClick={confirmChange}
              disabled={loading}
            >
              Yes
            </button>
            <button
              type="button"
              onClick={handleClose}
              className="btn btn-custom f-s16 font-Vcf clr-dark-blue p-0 ls-05"
            >
              No, Keep the Cart
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ConfirmReorderModal;
