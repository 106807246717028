import { useMutation } from "react-query";
import { customerBusinessService } from "../services";

export interface vehicleInfoProps {
  make:string,
  model:string,
  color:string,
}
const addVehicleInfo = async (vehicleInfo: vehicleInfoProps) => {
  const response = await customerBusinessService.addVehicleInformation(vehicleInfo)
  return response.data.data;
};

export default function useAddVehicleInfo() {
  return useMutation((data: { vehicleInfo: vehicleInfoProps}) =>
    addVehicleInfo(data.vehicleInfo)
  );
}
