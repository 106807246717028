import React from "react";
import { DeliveryAddressFromFields } from "../../../models/forms.model";
import styleClasses from "../ItemGrid.module.scss";

interface ISelectedAddressForDelivery {
    selectedAddress: DeliveryAddressFromFields
}

const SelectedAddressForDelivery = (props: ISelectedAddressForDelivery) => {
    const {
        selectedAddress
    } = props

    return(
        <>
            <div className="my-4">
                <span className="d-block f-s14 font-Visby-cf f-w5">{selectedAddress.address_name}</span>
                <span className="d-block f-s14 font-Visby-cf f-w5">{selectedAddress.street_name}, {selectedAddress?.apartment_no}</span>
                <span className="d-block f-s14 font-Visby-cf f-w5">{selectedAddress.city}, {selectedAddress.state} {selectedAddress.zipcode}</span>
            </div>

        </>
    )
}

export default SelectedAddressForDelivery;