import React, { useEffect, useState } from "react";
import ContactInfoForm from "../../../components/Forms/ContactInfoForm";
import { CustomModal } from "../../../components/Modal/CustomModal";
import { useAuthState } from "../../../context/UserAuthentication";
import { getUser } from "../../../helpers/helperMethods";

interface IContactMethodProps {
  isOrdered?: boolean,
  orderDetails?: any;
  contactInfoMessage?: string,
  setContactInfoMessage?: (string) => void
}
const ContactMethod = (props: IContactMethodProps) => {
  const { isOrdered, contactInfoMessage, setContactInfoMessage, orderDetails } = props
  const [isChecked, setIsChecked] = useState(true);
  const { authInfo } = useAuthState();
  const currentUser = getUser();
  const { first_name, last_name, email, phone } = currentUser;
  const isContactInfoComplete = first_name && last_name && email && phone;
  const [showContactForm, setShowContactForm] = useState(false);
  const handleShowContactForm = () => setShowContactForm(true);
  const handleCloseContactForm = () => setShowContactForm(false);

  useEffect(() => {
    if (isContactInfoComplete)
      setContactInfoMessage?.("");
  }, [currentUser])
  
  const formatPhoneNumber = (str) => {
    //Filter only numbers from the input
    let cleaned = ('' + str).replace(/\D/g, '');
    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    };
    return null
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="f-s20 text-start h-22 text-uppercase font-Cls fw-normal f-sm-s16">
          Contact method
        </h4>
        {(authInfo.userId || currentUser.email || currentUser.first_name || currentUser.phone) &&
          <button className={`${isOrdered ? "d-none" : null} btn btn-custom  d-md-none f-s16 text_underline clr-text-grey ms-3 p-0`}
            onClick={handleShowContactForm}
          >
            <img src={require('../../../../src/images/Edit.svg')} alt="edit"/>
          </button>
        }
      </div>
      <div className="d-flex justify-content-between align-items-center pb-md-0 pb-4">
        <div>
          <span className={"clr-dark-red f-s14 d-flex"}>{contactInfoMessage}</span>
          <span className="d-block text-start font-Visby-cf f-s16 fw-bold  f-sm-s14 f-sm-w5">
            { isOrdered ? 
              `${orderDetails?.customer?.first_name} ${orderDetails?.customer?.last_name}` : 
              `${currentUser?.first_name} ${currentUser?.last_name}`
            }
          </span>
          <span className="d-block text-start font-rale f-s16 f-w5 mb-2 f-sm-s14 f-sm-w5">
            {isOrdered ? orderDetails?.customer?.email : currentUser?.email}
          </span>
          <span className="d-block text-start font-rale f-s16 f-w5 mb-2 f-sm-s14 f-sm-w5">
            {formatPhoneNumber(isOrdered ? orderDetails?.customer?.phone: currentUser?.phone)}
          </span>
        </div>
        <div className={isOrdered ? "d-none" : null}>
          {(authInfo.userId || currentUser.email || currentUser.first_name || currentUser.phone) &&
            <button className={`btn btn-custom f-s16 text_underline clr-text-grey ms-3 p-0 d-none d-md-block`}
              onClick={handleShowContactForm}
            >
              <span className=""> Edit </span>
            </button>
          }
        </div>
      </div>

      {!authInfo.userId && (!currentUser.email && !currentUser.first_name && !currentUser.phone) &&
        <div className="d-flex justify-content-center justify-content-md-start mb-md-0 mb-4 col-md-6 ">
          <button type="button" className="btn btn-large outline mt-md-3 mt-0 w-100" onClick={handleShowContactForm}>
            Add contact info
          </button>
        </div>
      }

      <CustomModal
        showModal={showContactForm}
        closeModal={handleCloseContactForm}
        title={`Add Contact Info`}

      >
        <ContactInfoForm
          closeModal={handleCloseContactForm}
          currentGuestUserInfo={currentUser}
        />
      </ CustomModal>

    </>
  );
};

export default ContactMethod;
