import { useQuery } from "react-query";
import { locationMenuService } from "../services";
import { FOUNTAIN_DRINK } from "../constants";

interface IQueryParams {
  location_id: string;
  category_id: string;
  item_id: string;
}

const getItem = async ({ location_id, category_id, item_id }: IQueryParams) => {
  const response = await locationMenuService.getLocationMenuItem({
    location_id,
    category_id,
    item_id
  })

  const isItemFountainDrink = (item) => {
    return item.name.toLowerCase() === FOUNTAIN_DRINK.toLowerCase();
  }
  const category = response?.data?.data?.categories[0];
  const item = response?.data?.data?.categories[0]?.items[0];
  item.basePrice = category?.base
  const itemModifierGroups = !isItemFountainDrink(item) ? item.items_required_modifier_groups.map(
    (group) => ({
      ...group,
      required_modifiers_groups_modifiers:
        group.required_modifiers_groups_modifiers.sort(
          (a, b) => Number(b.in_item) - Number(a.in_item)
        ),
    })
  )
  :
  item.items_required_modifier_groups
  ;
  return {
    ...item,
    itemModifierGroups,
  };
};

export default function useItem(queryParams: IQueryParams) {
  
  const isEnabled = !(queryParams.item_id === undefined || queryParams.item_id === null || !queryParams.location_id)
  return useQuery<any>(
    ["item", queryParams.item_id],
    () => getItem(queryParams),
    {
      staleTime: 1000 * 60 * 60,
      enabled: isEnabled
    }
  );
}
