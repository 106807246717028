import React from "react";
import styleClasses from "../../../../containers/account/accounts.module.scss";

interface NoStoredPaymentsBannerProps {
  handleModalOpen: () => void;
}

const NoStoredPaymentsBanner: React.FunctionComponent<
  NoStoredPaymentsBannerProps
> = ({ handleModalOpen }) => {
  return (
    <>
      <div className={styleClasses.profile_inn_wrap + " text-center"}>
        <div className="mb-0 ">
          <h4 className="font-Cls text-start h-24 medium_text mb-4">Payment</h4>
        </div>
        <div className={styleClasses.profile_content_wrap + " mx-auto"}>
          <div className={styleClasses.inn_content_wrap + " mx-auto"}>
            <img
              src={require("../../../../images/payment-circle-icon-blue.svg")}
              className="img-fluid"
              alt="payment circle icon"
            />
            <h1 className="h-24  font-Cls medium_text">
              You have no payment
              <br /> method...yet!
            </h1>
            <p className="f-sm-s14 mx-auto">
              Add a payment method to your account for a<br /> faster checkout
              experience!
            </p>
            <hr className="custom_hr_sty mt-5 mb-3  d-md-none" />
            <button
              type="button"
              className="btn btn-large"
              onClick={handleModalOpen}
              data-testid="add-new-payment-button"
            >
              ADD A PAYMENT METHOD
            </button>
          </div>
        </div>
      </div>
      <hr className="custom_hr_sty  d-none d-md-block" />
    </>
  );
};

export default NoStoredPaymentsBanner;
