import React from "react";
import { Row } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars";
import { suggestedItem } from "../../../models/cart.model";
import styleClasses from "../../Header/Header.module.scss";
import SuggestedItemCard from "./SuggestedItemCard";

interface ISuggestedItemsList {
  list: any[];
  title: string;
  setMyRewards: (arr: any[]) => void
  isRewardList: boolean;
  closeModal:() => void;
  addToOrder: any;
}

const SuggestedItemsList: React.FC<ISuggestedItemsList> = ({
  list,
  title,
  setMyRewards,
  isRewardList,
  closeModal,
  addToOrder,
}) => {
  
  const getBtnText = (reward) => {
    if (isRewardList) {
      return reward.is_active_in_cart ? 'Redeemed' : 'Redeem'
    }
    return isRewardList ? 'Redeem' : 'Add';
  }

  if (!list.length) return null;

  return <div className={styleClasses.other_products_wrap}>
      <h4 className="f-s22 f-sm-s16 font-Cls lh-normal ls-normal mb-3">
        {title} 
      </h4>
      <Row className={styleClasses.suggestion_row + " flex-nowrap pt-1"}>
        <Scrollbars
          className={styleClasses.other_products_Scrollbar + " mb-mb-4"}
          renderThumbHorizontal={(props) => (
            <div {...props} className={styleClasses.Scrollbar_thumb} />
          )}
          renderView={(props) => (
            <div {...props} className={styleClasses.Scrollbar_view} />
          )}
        >
          {list.map((item: suggestedItem, index: number) => (
            <SuggestedItemCard
              rewardList={list}
              setMyRewards={setMyRewards}
              addToOrder={addToOrder}
              closeModal={closeModal}
              suggestedItem={item}
              isRewardList={isRewardList}
            />
          ))}
        </Scrollbars>
      </Row>
      </div>
};

export default SuggestedItemsList;
