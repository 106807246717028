import { useMutation } from "react-query";
import { DeliveryAddressFromFields } from "../models/forms.model";
import { customerBusinessService } from "../services";

const addAddress = async (deliveryAddress: DeliveryAddressFromFields, token: string) => {
  const response = await customerBusinessService.addAddress(deliveryAddress)
  return response.data.data;
};

export default function useAddAddress() {
  return useMutation((data: { deliveryAddress: DeliveryAddressFromFields, authentication_token: string }) =>
    addAddress(data.deliveryAddress, data.authentication_token)
  );
}
