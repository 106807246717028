import React, { useState } from "react";
import PlacesAutocomplete, {
  geocodeByPlaceId
} from "react-places-autocomplete";

const GooglePlacesSearchInput = ({defaultAddress="", handleAddressChange, setIsAddressChanged, setIsTypedAddress}) => {
  const [stateaddress, setAddress] = useState(defaultAddress);

  const handleChange = (address) => {
    setIsTypedAddress(true)
    setAddress(address);
  };

  const searchOptions = {
    componentRestrictions: { country: ['us'] },
  }

  const handleSelect = async (address, placeId) => {
    setIsTypedAddress(false)
    setAddress(address)
    setIsAddressChanged(true)
    const [place] = await geocodeByPlaceId(placeId);
    const { long_name: street = "" } = place.address_components.find((c) => c.types.includes("street_number")) || {};
    const { long_name: route = "" } = place.address_components.find((c) => c.types.includes("route")) || {};
    const { long_name: city = "" } = place.address_components.find((c) => c.types.includes("locality")) || {};
    const { long_name: state = "" } = place.address_components.find((c) => c.types.includes("administrative_area_level_1")) || {};
    const { long_name: country = "" } = place.address_components.find((c) => c.types.includes("country")) || {};
    const { long_name: postalCode = "" } = place.address_components.find((c) => c.types.includes("postal_code")) || {};

    let streetName = street + " " + route
    let cityName = city 

    handleAddressChange({streetName, cityName, state, country, postalCode, address})
  };

  return (
    <PlacesAutocomplete
      value={stateaddress}
      onChange={handleChange}
      onSelect={handleSelect}
      highlightFirstSuggestion={true}
      shouldFetchSuggestions={true}
      searchOptions={searchOptions}
    >

      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <label className="d-flex justify-content-between">
            Address
          </label>
          <input
            {...getInputProps({
              placeholder: "Select Address from here ",
              className: "form-control"
            })}
          />
          <div className="autocomplete-dropdown-container">
            {loading && <div className="loading">Loading...</div>}
            {suggestions.map((suggestion, index) => {
              const className = suggestion.active ? "suggestion-item--active" : "suggestion-item";
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className
                  })}
                  key={index}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default GooglePlacesSearchInput;