import { useMutation } from "react-query";
import { IUseCoupon } from "../../models/order.model";
import {customerBusinessService} from "../../services";

const coupon = async (payload: IUseCoupon) => {
    const response = await customerBusinessService.applyPromoCode(payload);
    return response.data;
};

export default function useCoupon() {
    return useMutation((payload: IUseCoupon) => coupon(payload));
}
