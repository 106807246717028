import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

interface ImageProps {
    source: string;
    alt?: string;
    className?: string;
}
const Image: React.FunctionComponent<ImageProps> = ({className, source, alt}) => {
    
    return (
        <LazyLoadImage className={className} src={source} alt={alt}/>
    );
}

export default Image;
