import React from "react";
import styleClasses from "../../../components/ItemGrid/ItemGrid.module.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Image, Tooltip, OverlayTrigger } from "react-bootstrap";
import { REACT_APP_CLOUD_FRONT } from "../../../constants";
import { Tag } from "../../../models/Menu.model";
import { symbolDetails } from "./SymbolDetails";

interface SymbolsProps {
  tags: Tag[];
}
const TagSymbol: React.FunctionComponent<SymbolsProps> = ({ tags }) => {

 
  // Function to render tooltip according to provided class name respective to symbol
  const renderTooltip = (tag, styleClass) => {
    return (
      <Tooltip className={styleClass} id="tooltip-vegeterian">
        {tag.name}
      </Tooltip>
    );
  };

  // Function to return icon and class name according to symbol name
  const getSymbolDetail = (name: string) => {
    const lowerCaseName = name.toLowerCase();
    for(const keyword in symbolDetails){
      if(lowerCaseName.includes(keyword)){
        return  symbolDetails[keyword];
      }
    }
  };

  return (
    <span className={styleClasses.item_icon_wrap + " d-flex flex-wrap"}>
      {tags?.map((tag) => {
        const detail = getSymbolDetail(tag.name);
        if (!detail) return "";
        return (
          <OverlayTrigger
            key={tag.id}
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip(tag, detail.className)}
          >
            <Image src={detail.icon} className="tooltip-icon" alt="toolTip"/>
          </OverlayTrigger>
        );
      })}
    </span>
  );
};

export default TagSymbol;
