import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import styleClasses from "../../Header/Header.module.scss";
import QuantityCounter from "./QuantityCounter";
import ActionButtons from "./ActionButtons";
import ItemPrice from "./ItemPrice";
import { IOfferItem, quantityCounterOperations } from "../../../models/cart.model";
import {
  COMPLIMENTARY_MODIFIER,
  GUEST_USER,
  KIDS_COMBO_CAPITALIZE,
  NO,
  TRY_2_COMBO_CAPITALIZE,
  TRY_TO_COMBO_ITEM
} from "../../../constants";
import { useAppDispatch, useAppSelector } from "../../../redux/store/store";
import { cartItem } from "../../../models/cart.model";
import { setShowCart } from "../../../redux/slices/cartSlice";
import ItemRemoveModal from "./itemRemoveModal";
import CartComboModifiers from "./CartComboModifiers";
import { useAuthState } from "../../../context/UserAuthentication";
import useRedeemOffer from "../../../hooks/useRedeemOffer";
interface ICartComboItemCard {
  item: cartItem;
  selectedOfferItem: IOfferItem;
  rewardsDiscountedSubTotal: number;
  handleQuantityChange: (
    item_id: string | number,
    iteratingIndex: number,
    itemQuantity: number
  ) => void;
  handleItemRemove: (
    item_id: string | number,
    iteratingIndex: number,
    closeModal: Function
  ) => void;
  isActive: boolean;
  redeemable: any;
  setIsRedeeming: Dispatch<SetStateAction<boolean>>;
  isRedeeming: boolean;
  isAutoRedeeming: boolean;
  itemId: number;
  iteratingIndex: number;
  loading: boolean;
  redeemForPoints: () => any;
  redeemfromMyRewards: () => any;
  togglingPoints: any;
  togglingReward: any;
}
const CartComboItemCard: React.FC<ICartComboItemCard> = ({
  item,
  selectedOfferItem,
  rewardsDiscountedSubTotal,
  // itemId,
  loading,
  iteratingIndex,
  handleQuantityChange,
  handleItemRemove,
  redeemable,
  isRedeeming,
  isAutoRedeeming,
  redeemForPoints,
  redeemfromMyRewards,
  togglingPoints,
  togglingReward
}) => {
  const [itemQuantity, setItemQuantity] = useState<number>(item.quantity ?? 1);
  const { tempLoyalityPoints, loyalityPoints }=useAppSelector(state=>state.Items.item)
  const isAutoRedeem = useAppSelector(state=>state.cart.auto_redeem_rewards)
  const { authInfo } = useAuthState();
  const [removeModal, setRemoveModal] = useState(false);
  const history = useHistory();
  const location = useLocation();
  let { search } = location;
  const query = new URLSearchParams(search);
  const editItemId = query.get("id");
  const dispatch = useAppDispatch();

  const handleQuantityChangeWrapper = async (
    operation: quantityCounterOperations
  ) => {
    if (isAutoRedeeming) return;
    let quantity = itemQuantity;
    if (operation === quantityCounterOperations.increase) {
      quantity = itemQuantity + 1;
    } else {
      quantity = itemQuantity > 1 ? itemQuantity - 1 : 1;
    }
    setItemQuantity(quantity);
    handleQuantityChange(item.id, iteratingIndex, quantity);
  };

  const { reVerifyOfferEligibility } = useRedeemOffer();
  useEffect(() => {
    reVerifyOfferEligibility(selectedOfferItem, item, rewardsDiscountedSubTotal);
  }, [rewardsDiscountedSubTotal])

  const handleItemEdit = () => {
    const path = item.item_type === TRY_TO_COMBO_ITEM ? TRY_2_COMBO_CAPITALIZE : KIDS_COMBO_CAPITALIZE
    history.push({
      pathname: `/menu/${path}`,
      search: `?id=${item.id}`,
      state: {
        showCustomization: true,
        isItemRedeemed: item?.reward_redeemed || item?.redemption_by_points
      },
    });
    dispatch(setShowCart(false));
  };

  useEffect(()=>{
    if(item.quantity) setItemQuantity(item.quantity)
  },[item.quantity])

  const discountedPrice = () => {
    return parseFloat(((item.gross_price * itemQuantity) - (item.discount_amount || item.discount || item.base_price)).toFixed(2))
  };

  const displayPrice = () => {
    const price = item.gross_price ?? item.display_price;
    return parseFloat((price * itemQuantity).toFixed(2))
  };

  const showItemRemoveModal = () => {
    setRemoveModal(true);
  };
  const hideItemRemoveModal = () => {
    setRemoveModal(false);
  };
  const unavailableItems = item.modifiers.filter((element)=> {
    if (element.is_item && !element.available && element.code !== NO)
      return element
    else if
    (item?.unavailable_modifiers?.includes(element?.modifier_name) && element?.modifier_type === COMPLIMENTARY_MODIFIER
        && element.quantity > 0 )
      return element
  }).map((item)=>item.modifier_name)
  let unavailableModifiers = []
  item.modifiers.forEach((item: any) => {
    if(item?.modifier_type !== COMPLIMENTARY_MODIFIER) unavailableModifiers = unavailableModifiers.concat(item.unavailable_modifiers)
  })

  const isComboEditAble = () => {
    return parseInt(editItemId) === item.id || unavailableItems.length > 0 ? true : false
  }
  
  return (
    <>
      <li className="text-center mb-3">
        <div
          className={`${styleClasses.list_item} text-center mb-0 w-100 ${
            (!item.available || unavailableItems.length > 0 || unavailableModifiers.length) && styleClasses.error_state
          }`}
        >
          <h1 className="d-flex justify-content-between font-Cls f-s16 ls-normal lh-normal m-0 text-start">
            <span>{item.name}</span>
            <ActionButtons
              onEdit={handleItemEdit}
              onRemove={showItemRemoveModal}
              isFree={item.free}
              isCombo={isComboEditAble()}
            />
            <ItemRemoveModal
              showModal={removeModal}
              handleClose={hideItemRemoveModal}
              removeItem={() =>
                handleItemRemove(item.id, iteratingIndex, hideItemRemoveModal)
              }
              loading={loading}
              item={item}
              userId={authInfo?.userId}
            />
          </h1>
          <CartComboModifiers modifiers={item.modifiers}/>

          {authInfo.type !== GUEST_USER ? (
            <>
              {item.reward_id ? (
                <button
                  disabled={itemQuantity > 1}
                  type="button"
                  className={`btn btn-large  ${
                    !item.reward_redeemed
                      ? "outline"
                      : `${styleClasses.loyalty_reward_btn}  primary`
                  } my-3 active`}
                  onClick={() => {
                    if (!isAutoRedeem) redeemfromMyRewards();
                  }}
                >
                  {isRedeeming && !item.reward_redeemed
                    ? "Please wait"
                    : item.reward_redeemed
                    ? "Reward Redeemed"
                    : "Redeem Reward"}
                </button>
              ) : (
                redeemable && redeemable.redeem_points < loyalityPoints && (
                  <button
                    disabled={
                      itemQuantity > 1 ||
                      (tempLoyalityPoints < redeemable?.redeem_points &&
                        !redeemable?.redemption_by_points)
                    }
                    type="button"
                    className={`btn btn-large my-3 px-0 px-md-3 ${styleClasses.radeemPointsBtn} ${
                      !item.redemption_by_points ? "outline" : "primary"
                    }`}
                    onClick={() => redeemForPoints()}
                  >
                    {isRedeeming && !item.free
                      ? "Please wait"
                      : item.redemption_by_points
                      ? `Redeemed For ${redeemable.redeem_points} Points`
                      : `Redeem For ${redeemable.redeem_points} Points`}
                  </button>
                )
              )}
            </>
          ) : null}
          <span className="d-block f-s14 font-Visby-cf f-w6 mt-1 text-start text-capitalize">{item?.recipient_name}</span>
          <span
            className={
              styleClasses.card_increments_wrapper +
              " d-flex justify-content-between mt-3"
            }
          >
            <QuantityCounter
              quantity={itemQuantity}
              editQuantity={handleQuantityChangeWrapper}
              disabled={!item.available || unavailableItems.length > 0 || !!item.redemption_by_points || !!item.reward_redeemed || togglingPoints || togglingReward }
            />
            <ItemPrice
              price={displayPrice()}
              isRedeemed={!!item.reward_redeemed ||!!item.redemption_by_points }
              discountPrice={discountedPrice()}
            />
          </span>
        </div>
        {
          !item.available ? (
            <span className="text-center clr-dark-red f-s14 font-rale d-inline-block pt-2 cursor-pointer" onClick={showItemRemoveModal}>
              This item is no longer available. Please remove from your order.
            </span>
          ) :
          unavailableItems.length > 0 ? (
            <span className="text-center clr-dark-red f-s14 font-rale d-inline-block pt-2 cursor-pointer" onClick={showItemRemoveModal}>
              Unfortunately, we are currently out of {unavailableItems.join(', ')} today.
            </span>
          ) :
          unavailableModifiers.length > 0 && (
            <span className="text-center clr-dark-red f-s14 font-rale d-inline-block pt-2 cursor-pointer" onClick={showItemRemoveModal}>
              Unfortunately, we are currently out of {unavailableModifiers?.join(', ')} today.
            </span>
          )
        }
      </li>
    </>
  );
};

export default CartComboItemCard;
