import React, { useEffect } from "react";
import { useAuthState } from "../../context/UserAuthentication";
import { useAppSelector } from "../../redux/store/store";
import { getUser } from "../../helpers/helperMethods";

function LogOutAction() {
  const { dispatch, authInfo } = useAuthState();
  const isLogoutAction = useAppSelector((state) => state.cart.isLogoutAction);

  useEffect(() => {
    let user = getUser();
    if (!user.token && authInfo.userId) {
      dispatch({ type: "LOGOUT" });
    }
  }, [isLogoutAction]);

  return <></>;
}
export default LogOutAction;
