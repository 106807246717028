import { Formik } from "formik";
import React, { useState } from "react";
import {CURRENT_USER, ROUTE_CHECKOUT} from "../../../../constants";
import { getUser, getVisitorId } from "../../../../helpers/helperMethods";
import {ContactInfoFormFields, IGuestContactInfo} from "../../../../models/forms.model";
import { FormField } from "../../../FormFields/FormField";
import SignUpForm from "./SignUpForm";
import {guestContactInfoFormSchema} from "../../../../validationSchemas/guestContactInfoFormSchema";
import {setShowCart} from "../../../../redux/slices/cartSlice";
import {useHistory} from "react-router";
import {useAppDispatch} from "../../../../redux/store/store";

interface IContactInfoForm {
  currentGuestUserInfo?: any;
  closeModal?: () => void;
  isGuest?: boolean;
  subTotal: number;
}

const GuestLogin: React.FC<IContactInfoForm> = ({
  closeModal,
  subTotal,
  currentGuestUserInfo: { first_name, last_name, email, phone, isInfo },
}) => {

  const initialFormState: ContactInfoFormFields = {
    first_name: isInfo ? first_name : "",
    last_name: isInfo ? last_name : "",
    email: isInfo ? email : "",
    phone: isInfo ? phone : "" ,
  };
  const [showGuestPasswordFields, setShowGuestPasswordFields] = useState<boolean>(false);
  const history  = useHistory();
  const dispatch = useAppDispatch();
  const handleGuestSubmission = () => {
      closeModal();
      history.push(ROUTE_CHECKOUT);
      dispatch(setShowCart(false));
  }
  const [contactInfo, setContactInfo] = useState<IGuestContactInfo>(null)
  const handleFormSubmission = async (
    values: ContactInfoFormFields,
  ) => {
      let user = getUser();
      user.full_name = values.first_name.concat(" ", values.last_name);
      localStorage.setItem(
          CURRENT_USER,
          JSON.stringify({
              ...user,
              visitor_id: getVisitorId(),
              isInfo: true,
              ...values,
          })
      );
      setContactInfo({
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email
      })
      setShowGuestPasswordFields(true)
  };
  return (
    <>
      <h5 className="f-s22 font-Cls  text-center mx-auto text-capitalize full_width_mob">
        Guest Checkout
      </h5>
      <p className="f-s14 font-rale mb-0 text-center">
        Add contact information for order updates!
      </p>

        {
            !showGuestPasswordFields ?
                <Formik
                    initialValues={initialFormState}
                    validationSchema={guestContactInfoFormSchema}
                    onSubmit={handleFormSubmission}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                          submitForm,
                      }) => (
                        <form
                            className="new_form_design pt-3 delivery_location_select full_width_mob"
                            onSubmit={handleSubmit}
                        >
                            <FormField
                                labelText={"First Name"}
                                type={"text"}
                                name={"first_name"}
                                errors={errors}
                                touched={touched}
                                onChange={handleChange}
                                value={values.first_name}
                            />
                            <FormField
                                labelText={"Last Name"}
                                type={"text"}
                                name={"last_name"}
                                errors={errors}
                                touched={touched}
                                onChange={handleChange}
                                value={values.last_name}
                            />
                            <FormField
                                labelText={"Email Address"}
                                type={"email"}
                                name={"email"}
                                errors={errors}
                                touched={touched}
                                onChange={handleChange}
                                value={values.email}
                            />
                            <div className="d-flex justify-content-center">
                                <button
                                    type="submit"
                                    className="btn btn-large py-2 f-s16 mb-4 mt-3 text-uppercase w-100"
                                    disabled={isSubmitting}
                                    onClick={submitForm}
                                >
                                    CONTINUE
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>
                :
                <>
                    <SignUpForm closeModal={closeModal} guestContactInfo={contactInfo} subTotal={subTotal}/>
                    <a
                        onClick={handleGuestSubmission}
                        className="clr-dark-grey d-block f-s16 font-Visby-cf f-w8 text-center underline"
                    >
                        No, Proceed As Guest
                    </a>
                </>
        }
    </>
  );
};

export default GuestLogin;