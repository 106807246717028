import { useRef, useEffect } from 'react';

const useUnload = callBack => {
  const cb = useRef(callBack)

  useEffect(() => {
    cb.current = callBack;
  }, [callBack]);

  useEffect(() => {
    const onUnload = (...args) => cb.current?.(...args);

    window.addEventListener("beforeunload", onUnload);

    return () => window.removeEventListener("beforeunload", onUnload);
  }, []);
};

export default useUnload;