import { useMutation } from "react-query";
import { customerBusinessService } from "../services";

const barCodeRequest = async (data: any, access_token: string) => {
  const response = await customerBusinessService.addBarcode({
    bar_code: data.barCode
  })
  return response.data.data;
};

export default function useBarCodeRequest() {
  return useMutation((data: { bar_code: any; access_token: string; }) =>
    barCodeRequest(data.bar_code, data.access_token)
  );
}
