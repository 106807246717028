import React from "react";
import styleClasses from "./Confirmation.module.scss";
import { Modal, Button, Container, Row, Col } from "react-bootstrap";

interface IConfirmationModal {
  show: boolean;
  title: string;
  content: string;
  btnText: string;
  handleClose: () => void;
  handleClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}
const ConfirmationModal: React.FunctionComponent<IConfirmationModal> = ({
  show,
  handleClose,
  handleClick,
  title,
  content,
  btnText,
}) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className="theme-modal-style app_container_wrap nor-padding border-radius-15"
    >
      <Modal.Body className="app_container_wrap px-1 py-2 text-center">
        <Container>
          <Row>
            <Col xs="12" sm="12" className="pt-4">
              <h5 className={styleClasses.modal_title}>{title}</h5>
              <p className={styleClasses.modal_description + " f-s14"}>
                {content}
              </p>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer className="flex-column">
        <Button
          variant="btn-custom"
          className="btn btn-large dark-grey d-block mx-auto sm-w100"
          onClick={handleClick}
        >
          {btnText}
        </Button>
        <Button
          variant="btn-custom"
          onClick={handleClose}
          className="btn btn-custom text_underline font-Vcf"
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmationModal;