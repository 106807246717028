import React from "react";
import { Row, Col } from "react-bootstrap";
import NoStoredPaymentsBanner from "./noStoredPaymentBanner/noStoredPaymentBanner";
import StoredPaymentCard from "./storedPaymentCard/storedPaymentCard";
import styleClasses from "../../../containers/account/accounts.module.scss";
import { StoredPaymentMethod } from "../../../models/payment.model";

type IProps = {
  paymentRecords: StoredPaymentMethod[];
  defaultPayment: any;
  handleChangeDefault: any;
  handleEdit: any;
  setOpenPaymentModal: any;
  closeConfirmationModal?: boolean
};

export default function StoredPaymentsListing({
  paymentRecords,
  defaultPayment,
  setOpenPaymentModal,
  handleEdit,
  handleChangeDefault, closeConfirmationModal
}: IProps) {
  return paymentRecords.length == 0  ? (
    <NoStoredPaymentsBanner handleModalOpen={() => setOpenPaymentModal(true)} />
  ) : (
    <Row>
      <Col className={styleClasses.profile_inn_wrap}>
        <div className="mb-0 ">
          <h4 className="font-Cls f-s24 f-sm-s22 text-start h-24 medium_text mb-4">Payment</h4>
        </div>
        <div
          className={`${styleClasses.card_info_wrap} text-start ${styleClasses.listing_style}`}
        >
          <div className={styleClasses.card_info}>
            <ul className="list-inline text-start ps-0 pe-3 pe-sm-0">
              {paymentRecords.map((record, index) => (
                <StoredPaymentCard
                  closeConfirmationModal={closeConfirmationModal}
                  key={record.id}
                  record={record}
                  handleEdit={handleEdit}
                  index={index}
                  defaultPayment={defaultPayment}
                  handleChangeDefault={handleChangeDefault}
                  paymentCount={paymentRecords.length}
                />
              ))}
            </ul>
            <hr className="custom_hr_sty mb-4 mt-5  d-md-none" />
            <div className="d-flex justify-content-center">
              <button
                type="button"
                className="btn btn-large py-2 mt-4 mb-4 f-s16"
                onClick={() => setOpenPaymentModal(true)}
                disabled={paymentRecords.length >=5}
              >
                Add A Payment Method
              </button>
            </div>
          </div>
        </div>
      </Col>
      <hr className="custom_hr_sty d-none d-md-block" />
    </Row>
  );
}
