import * as Yup from "yup";

export const guestContactInfoFormSchema = Yup.object().shape({
    first_name: Yup.string()
        .required("First name is required")
        .matches(
            /^[a-zA-Z ]+$/,
            "First name should not contain special characters or numbers"
        ),
    last_name: Yup.string()
        .required("Last name is required")
        .matches(
            /^[a-zA-Z ]+$/,
            "Last name should not contain special characters or numbers"
        ),
    email: Yup.string()
        .email("Enter a valid email")
        .required("Email address is required"),
});
