import {formatInTimeZone} from 'date-fns-tz'
import { SELECTED_STORE } from '../../constants'


export const getCurrentDate = () => {
    const selectedLocationTimezone = JSON.parse(localStorage.getItem(SELECTED_STORE))?.time_zone
    const currentDate = new Date()
    if (selectedLocationTimezone) {
        return formatInTimeZone(currentDate, selectedLocationTimezone, 'yyyy-MM-dd')
    } else {
        const day = currentDate.getDate();
        const month = currentDate.getMonth() + 1;
        const year = currentDate.getFullYear();
        const dateStr = year + "-" + ( (('0' + month).slice(-2)) ) + "-" + ('0' + day).slice(-2);
        return dateStr;    
    }
}