import React, { useEffect, useState } from "react";
import Select, { StylesConfig } from "react-select";

import { SelectOptions } from "../../models/forms.model";

interface FormFieldProps {
  labelText?: string;
  OptionalText?: string;
  placeholder?: string;
  value?: any;
  readonly?: boolean;
  name: string;
  type: string;
  onChange: ((e: React.ChangeEvent<any>) => void) & ((values: any) => void);
  options?: SelectOptions[];
  errors?: any;
  touched?: any;
  parentDivClass?: string;
  inputFieldClass?: string;
  title?: string;
  isSearchable?: boolean;
  onFocus?: (e: React.ChangeEvent<any>) => void;
  onBlur?: (e: React.ChangeEvent<any>) => void;
  maxLength?:number;
  numbersWithRestrictedLimit?:boolean
  handleLoading?:{enable:boolean,inputValue:string}
  isOptionDisable?: boolean;
}

const FormField: React.FC<FormFieldProps> = ({
  labelText,
  OptionalText,
  name,
  type,
  onChange,
  value,
  readonly,
  errors,
  touched,
  options,
  parentDivClass,
  inputFieldClass,
  placeholder,
  title,
  isSearchable,
  onFocus,
  onBlur,
  maxLength,
  numbersWithRestrictedLimit=false,
  handleLoading={enable:false,inputValue:''},
  isOptionDisable
}) => {

  const [inputProps, setInputProps] = useState<any>({})

  useEffect(() => {
    if(handleLoading.enable){
      setInputProps({...inputProps, noOptionsMessage : ({ inputValue: string }) => handleLoading.inputValue})
    }
  },[handleLoading])

function numberOnly(e) {
  e.target.value = e.target.value.replace(/[^0-9]/gi, "");
}

// `isOptionDisabled` function to disable options if 5 are already selected
const isOptionDisabled = (option: SelectOptions) => {
  if (type.toLowerCase() === "multiselect" && Array.isArray(value)) {
    return value.length >= 5 && !value.some((selected) => selected.value === option.value);
  }
  return false;
};

 // Custom styles to visually indicate disabled options
 let customStyles: StylesConfig<SelectOptions, boolean> = {
  option: (provided, state) => {
    return ({
    ...provided,
    color: state.isDisabled ? "#999999 !important" : "#000", // Gray out the text if disabled
    backgroundColor: state.isDisabled ? "#f5f5f5 !important" : state.isFocused ? "#e5f4ff" : "#fff", // Lighter background for disabled options
    cursor: state.isDisabled ? "not-allowed !important" : "pointer", // Show not-allowed cursor for disabled options
  })},
  control: (provided) => ({
    ...provided,
    borderColor: errors[name] && touched[name] ? "red" : provided.borderColor,
    boxShadow: errors[name] && touched[name] ? "0 0 0 1px red" : provided.boxShadow,
  }),
};

  return (
    <div className={`form-group ${parentDivClass ? parentDivClass : ""}`}>
      {/* {!OptionalText ? <label>{labelText}</label> : <label className="d-flex justify-content-between">{labelText} <span className="f-s14 text-transform-none font-rale f-w5 ls-normal">{OptionalText}</span></label>} */}
      {labelText && !OptionalText ? <label>{labelText}</label> : <label className="d-flex justify-content-between">{labelText} <span className="f-s14 text-transform-none font-rale f-w5 ls-normal">{OptionalText}</span></label>}
      {type.toLowerCase() === "select" ||
      type.toLowerCase() === "multiselect" ? (
        <div className={`form-group ${inputFieldClass ? inputFieldClass : ""}`}>
          <Select
            key={isOptionDisable ? value?.length : null} // Add a dynamic key to force re-render
            value={value}
            options={options}
            classNamePrefix="react-select"
            className={errors[name] && touched[name] ? 'input_error' : ''}
            placeholder={placeholder}
            isSearchable={isSearchable ? true : false}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            onChange={onChange}
            isMulti={type.toLowerCase() === "multiselect"}
            isOptionDisabled={isOptionDisable ? isOptionDisabled : false} // Added here
            styles={isOptionDisable ? customStyles : ''} // Applied custom styles
            {...inputProps}
          />
        </div>
      ) : (
        <input
          name={name}
          type={type}
          className={`form-control ${inputFieldClass ? inputFieldClass : ""} ${errors[name] && touched[name] ? 'input_error' : ''}`}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          readOnly={readonly}
          title={title}
          onBlur={onBlur}
          onFocus={onFocus}
          onInput={(e) => numbersWithRestrictedLimit && numberOnly(e)}
          maxLength={maxLength}
        />
      )}
      {errors && touched && errors[name] && touched[name] && (
        <span className={"clr-dark-red f-s14 font-rale mt-1 d-inline-block"}>{errors[name]}</span>
      )}
    </div>
  );
};

export { FormField };
