import { useQuery } from "react-query";
import { shouldRelease } from "../helpers/helperMethods";
import { locationMenuService } from "../services";
import { IGetUpsellItemsForAddToOrderQueryParams } from "../models/location.model";

const getUpsellItemsForAddToOrder = async (payload: IGetUpsellItemsForAddToOrderQueryParams) => {
  const response = await locationMenuService.getUpsellItemsForAddToOrder(payload)
  return response.data.data;
};

export default function useGetUpsellItemsForAddToOrder(payload: IGetUpsellItemsForAddToOrderQueryParams) {
  return useQuery(["upsellItems"], () => getUpsellItemsForAddToOrder(payload), {
    enabled: !!payload?.location_id,
    cacheTime: 0
  });
}
