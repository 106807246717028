import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { useQueryClient } from "react-query";
import { useHistory, useLocation } from "react-router";
import { FormField } from "../FormFields/FormField";
import { deliveryAddressSchema } from "../../validationSchemas/deliveryAddressSchema";
import { DeliveryAddressFromFields } from "../../models/forms.model";
import useAddAddress from "../../react-query-hooks/useAddAddress";
import { Toast_Func } from "../../helpers/toast.helper";
import { API_ERROR } from "../../models/error.model";
import useProfile from "../../react-query-hooks/useProfile";
import { useAuthState } from "../../context/UserAuthentication";
import useUpdateAddress from "../../react-query-hooks/useUpdateAddress";
import { Col, Row } from "react-bootstrap";
import useGetAllStates from "../../react-query-hooks/useGetAllStates";
import GooglePlacesSearchInput from "../GooglePlacesSearch/GooglePlacesSearch";
import { GUEST_USER, MAX_STREET_2_LENGTH } from "../../constants";

interface FormProps {
  closeModal: () => void;
  addressId?: number;
  addressForOneTime?: number;
  handleAddressForOneTime?: (data) => void;
  temporaryRecord?: boolean;
  editTempraryRecord?: (data) => void;
  address?: any;
}

export const DeliveryAddressForm: React.FunctionComponent<FormProps> = ({
  closeModal,
  addressId,
  addressForOneTime,
  handleAddressForOneTime,
  temporaryRecord,
  editTempraryRecord,
  address,
}) => {

  const { pathname } = useLocation();
  const history = useHistory();
  let queryClient = useQueryClient();
  let { authInfo } = useAuthState();
  let { data } = useProfile(authInfo.userId);
  let { mutateAsync: addAddress } = useAddAddress();
  let { mutateAsync: updateAddress } = useUpdateAddress();

  const [saveToAccountCheck, setSaveToAccountCheck] = useState(false);
  const [ stateOfStatesFetching, setStateOfStatesFetching ] = useState(null);


  const { data: allStates, isFetching } = useGetAllStates();
  // const [selectedState,setSelectedState]=useState(null)
  const getAddress = () => {
    if (temporaryRecord) return address;
    else if (addressId) return data.addresses.find((address: any) => address.id === addressId);
    else return "";
  };
  let addressData = getAddress();
  const [isDefault, setIsDefault] = useState(addressData.is_default);
  const [statesList, setStatesList] = useState([]);

  const [street_name, setStreet_name] = useState(addressData?.street_name ? addressData?.street_name :'')
  const [city, setCity] = useState(addressData?.city ? addressData?.city :'')
  const [state, setState] = useState(addressData?.state ? addressData?.state :'')
  const [zipcode, setZipcode] = useState(addressData?.zipcode ? addressData?.zipcode :'')
  const [country, setCountry] = useState('')
  const [fullAddress, setFullAddress] = useState(addressData?.full_address ? addressData?.full_address :'')
  const [isAddressChanged, setIsAddressChanged] = useState(false)
  const [isTypedAddress, setIsTypedAddress] = useState<boolean>(false)


  useEffect(() => {
    const controller = new AbortController();
    
    return () => {
      // cancel the request before component unmounts
      controller.abort();
    };
  }, []);

  useEffect(() => {
    if (!isFetching && allStates?.length > 0) {
      setStatesList(
        allStates.map((state) => {
          return { label: state.abbr, value: state.abbr };
        })
      );
    }
  }, [allStates]);

  useEffect(() => {
    if(isFetching){
      setStateOfStatesFetching('Loading...')
    }else if(allStates?.length <= 0 || !allStates){
      setStateOfStatesFetching('No state availabe!')
    }
  },[isFetching,allStates])

  const selectedState = statesList.find(state => state.value == addressData.state)
  const initialFormState: DeliveryAddressFromFields = {
    address_name: addressData ? addressData.address_name : "",
    optional_address_details:  addressData ? addressData.apartment_no : "",
    // street_name: addressData ? addressData.street_name : "",
    // apartment_no: addressData ? addressData.apartment_no : "",
    // city: addressData ? addressData.city : "",
    // state: addressData ? addressData.state : "",
    // zipcode: addressData ? addressData.zipcode : "",
  };
  const handleEditTemporaryRecord = (modifiedValues) => {
    let modifiedValuesToEdit = {
      ...modifiedValues,
      id: addressForOneTime - 1,
    };
    editTempraryRecord(modifiedValuesToEdit);
  };

  const addAddressForOneTime = (modifiedValues) => {
    let modifiedValuesToEdit = {
      ...modifiedValues,
      id: addressForOneTime,
    };
    handleAddressForOneTime(modifiedValuesToEdit);
  };


  const handleAddressChange= (e) => {
    setStreet_name(e.streetName)
    setCity(e.cityName)
    setState(e.state)
    setZipcode(e.postalCode)
    setFullAddress(e.address)
    setCountry(e.country)
  }


  const handleFormSubmission = (values: DeliveryAddressFromFields, { setSubmitting }: any) => {
    let { address_name
      // , street_name, city, zipcode, state 
    } = values;
    address_name = address_name?.trim()
    if(isTypedAddress || street_name?.trim() === '' || city?.trim() === '' || state?.trim() === '' || zipcode?.trim() === ''){
      Toast_Func({
        status: false,
        message: "Address should contain valid / nearby street city and state.",
      });
      setSubmitting(false);
      return;
    }

    let modifiedValues = {
      ...values,
      street_name,
      city,
      state,
      zipcode,
      country,
      customer_id: authInfo.userId,
      apartment_no:values.optional_address_details,
      // full_address: `${street_name}, ${city}, ${zipcode}, ${state}`,
      full_address: fullAddress,
      is_default: isDefault ? 1 : 0,
      ...(address_name ? { address_name } : { address_name: street_name }),
    };

    if (history.location.pathname != "/profileDetails") {
      if (temporaryRecord) {
        handleEditTemporaryRecord(modifiedValues);
        return;
      }

      if (!saveToAccountCheck && !addressId) {
        addAddressForOneTime(modifiedValues);
        closeModal();
        setSubmitting(false);
        Toast_Func({
          status: true,
          message: "Address successfully added",
        });
        return;
      }
    }

    let mutate = addressId ? updateAddress : addAddress;
    return mutate(
      {
        deliveryAddress: modifiedValues,
        ...(addressId && { addressId }),
        authentication_token: authInfo.authentication_token,
      },
      {
        onSuccess: (data, variables, context) => {
          queryClient.invalidateQueries("profile");
          closeModal();
          setSubmitting(false);
          Toast_Func({
            status: true,
            message: "Address successfully added",
          });
        },
        onError: (error, variables, context) => {
          const {
            data: {
              data: { errors },
            },
          } = (error as API_ERROR).response;
          closeModal();
          setSubmitting(false);
          Toast_Func({ status: false, message: errors[0] });
        },
      }
    );
  };

  const setDefaultCheckBox = history.location.pathname != "/profileDetails";
  // if (isFetching) {
  //   return <Spinner />;
  // }
  return (
    <Formik 
    initialValues={initialFormState} 
    // validationSchema={deliveryAddressSchema} 
    onSubmit={handleFormSubmission}>
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue
        // submitForm,
      }) => (
        <form className="position-relative new_form_design" onSubmit={handleSubmit}>
          {authInfo.type !== GUEST_USER &&
            <Row>
              <div className="form-group">
                <label className="d-flex justify-content-between">
                  Name Your Saved Address
                </label>
                <input
                  name="address_name"
                  type="text"
                  className="form-control no-style"
                  onChange={(e) => {
                    handleChange(e)
                    if(e?.target?.value?.trim() !== addressData?.address_name?.trim()){
                       setIsAddressChanged(true)
                    }}
                  }
                  value={values.address_name}
                />
                {errors.address_name && touched.address_name && (
                  <span className={"clr-dark-red f-s11"}>{errors.address_name}</span>
                )}
              </div>
            </Row>
          }
          <Row>
           <GooglePlacesSearchInput
              defaultAddress={addressData?.full_address}
              handleAddressChange={handleAddressChange}
              setIsAddressChanged={setIsAddressChanged}
              setIsTypedAddress={setIsTypedAddress}
           />
          </Row>
          <div className='my-3'>
            <FormField
                labelText={"Apt, Floor, Suite, Building, Company (Optional)"}
                name="optional_address_details"
                value={values.optional_address_details}
                type="text"
                onChange={handleChange}
                errors={errors}
                touched={touched}
                placeholder="Apt, Floor, Suite, Building, Company"
                maxLength={MAX_STREET_2_LENGTH}
            />
          </div>
          <div className="d-flex justify-content-center">
            <button type="submit" className="btn btn-large py-2 my-3 f-s16" disabled={isSubmitting}>
              {addressId != null && typeof addressId != "undefined" ? "CONFIRM CHANGES" : "Save delivery address"}
            </button>
          </div>
        </form>
      )}
    </Formik>
  );
};
