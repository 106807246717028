import { toast } from 'react-toastify';
import { GET_REWARD_CATALOG } from '../constants';
export interface Toast {
    status: boolean;
    message: any;
}
export function Toast_Func(data:Toast) {
    if (data.status == true) {
        toast.success(data.message,{
            position: "top-center",
            className: "custom-success-toast",
        });
    } else {
        if (typeof data.message === 'object') {
            for (var k in data.message) {
                if (Array.isArray(data.message[k])) {
                    for (var j in data.message[k]) {
                        toast.error(data.message[k][j],{
                            position: "top-center",
                        });
                    }
                } else {
                    toast.error(data.message,{
                        position: "top-center",
                        className: "custom-error-toast",
                    });
                }
            }
        }
        else {
            toast.error(data.message,{
                position: "top-center",
                className: "custom-error-toast",
            });
        }
    }
}

export function logErrorMessage(queryKey: string) {
    switch(queryKey) {
        case GET_REWARD_CATALOG:
          Toast_Func({status: false, message: `Reward catalog could not be fetched`})
          break;
        default: 
          // Toast_Func({status: false, message: `${error.message}`})
          break;
    }
}
