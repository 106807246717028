import React from 'react'
import { Modal } from 'react-bootstrap';

interface GlutenSensitiveModalProps {
    openGlutenModal: boolean,
    setOpenGlutenModal: (value: boolean) => void,

}
const GlutenSensitiveModal: React.FC<GlutenSensitiveModalProps> = ({ openGlutenModal, setOpenGlutenModal }) => {
    return (
        <div>
            <Modal
                show={openGlutenModal}
                onHide={() => {
                    setOpenGlutenModal(false);
                }}
                centered
                className="theme-modal-style app_container_wrap  border-radius-15 px-5 order_type_modal"
                size="sm">
                {" "}
                <Modal.Header className="x-4 justify-content-center">
                    <button
                        type="button"
                        className="btn modal-close-button pe-0 sm-blue-icon"
                        onClick={() => {
                            setOpenGlutenModal(false);
                        }}>
                        Close
                    </button>
                    <h5 className="f-s22 font-Cls text-center  mt-4 mb-0 text-capitalize ">
                        {"*Gluten Sensitive"}
                    </h5>
                </Modal.Header>
                <Modal.Body className="pt-0">
                    <p className="px-md-4 px-2 text-justify pb-3">
                        {" "}
                        Even with strict adherence toward maintaining clean and organized
                        kitchens, we have too much wheat and gluten present to be able to
                        eliminate the cross-contamination on our equipment and food
                        preparation areas. If you are highly allergic or intolerant to
                        gluten, we ask you to exercise judgment regarding your individual
                        needs.
                    </p>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default GlutenSensitiveModal