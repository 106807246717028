import { Modal } from "react-bootstrap";
import React from "react";
import { IOfferItemDetails } from "../../../models/cart.model";
import useMarketLocations from "../../../react-query-hooks/useLocations";
import { 
  OFFER_DISCOUNTED_SUBTOTAL_ZERO, 
  OFFER_ELIGIBLE_ITEMS, 
  OFFER_ELIGIBLE_LOCATIONS, 
  OFFER_ELIGIBLE_SUBTOTAL_RANGE, 
  OFFER_REWARD_REDEEMED, 
  PICK_UP_ORDER_TYPE
} from "../../../constants";

interface IOfferEligibilityModalProps {
  show: boolean;
  offerFailedCriteria: string;
  redeemedItemNames: string[];
  offerDetails: IOfferItemDetails;
  closeModal: () => void;
}

const OfferEligibilityModal: React.FC<IOfferEligibilityModalProps> = ({
  show,
  closeModal,
  redeemedItemNames,
  offerFailedCriteria,
  offerDetails,
}) => {
  const {
    offer_redemption_restrictions: {
      check_amount_restriction: {
        maximum_check_amount: maxAmount = 0,
        minimum_check_amount: minAmount = 0,
      } = {},
    },
    eligible_locations,
    eligible_items,
  } = offerDetails;

  const shouldCheckAmountRestriction = !!minAmount || !!maxAmount;

  const { data: markets } = useMarketLocations();
  const showEligibleLocations =
    markets
      ?.filter((location) => eligible_locations.includes(location.id))
      .map((market) => market.name) || [];
  const showEligibleItems =
    Object.values(eligible_items)?.map((eligibleItem) => eligibleItem.name) ||
    [];

  return (
    <Modal
      show={show}
      centered
      className="theme-modal-style app_container_wrap  border-radius-15 px-5 order_type_modal"
      onHide={closeModal}
      size="sm"
    >
      <Modal.Header className="pb-0 pt-4 justify-content-center">
        <button
          type="button"
          className="btn modal-close-button pe-0 sm-blue-icon"
          onClick={closeModal}
        >
          Close
        </button>
        <h4 className="f-s22 font-Cls text-center fw-normal lh-normal mb-0 text-capitalize">
          Eligibility Criteria
        </h4>
      </Modal.Header>
      <Modal.Body className="mb-0 px-md-5">
        <>
          {showEligibleItems.length ? (
            <div className="mb-3">
              <h5 className={`f-s18 font-Cls fw-normal lh-22 mb-0 text-capitalize`}>
                Eligible Items
              </h5>
              {showEligibleItems.map((eligibleItem) => (
                <div className="w-100">
                  <div className="mb-1">
                    <span className="d-block f-s14 f-sm-s12 font-Visby-cf f-w5 text-capitalize clr-dark-blue ms-1">
                     - {eligibleItem}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          ) : null}
          {showEligibleLocations.length ? (
            <div className="mb-3">
              <h5 className={`f-s18 font-Cls fw-normal lh-22 mb-0 text-capitalize ${offerFailedCriteria === OFFER_ELIGIBLE_LOCATIONS ? "clr-red-dark" : ""}`}>
                Eligible Locations
              </h5>
              {showEligibleLocations.map((eligibleLocation) => (
                <div className="w-100">
                  <div className="mb-1">
                    <span className="d-block f-s14 f-sm-s12 font-Visby-cf f-w5 clr-dark-blue ms-1">
                      - {eligibleLocation}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          ) : null}
          {shouldCheckAmountRestriction && (
            <div className="mb-3">
              <h5 className={`f-s18 font-Cls fw-normal lh-22 mb-0 text-capitalize  ${offerFailedCriteria === OFFER_ELIGIBLE_SUBTOTAL_RANGE ? "clr-red-dark" : ""}`}>
                Amount Restriction:{" "}
              </h5>
              <div className="mb-1">
              <span className="d-block f-s16 font-Visby-cf f-w5 ">
                 {(minAmount &&
                  maxAmount &&
                  `Amount should be greater than $${minAmount} and smaller than $${maxAmount}`) ||
                  (minAmount &&
                    `Amount should be greater than $${minAmount}`) ||
                  (maxAmount && `Amount should be less than $${maxAmount}`)}
              </span>
              </div>
            </div>
          )}
          {offerFailedCriteria === OFFER_REWARD_REDEEMED && (
            <>
              <h5 className="f-s18 font-Cls fw-normal lh-22 mb-0 text-capitalize clr-red-dark">
                Item Redeemed:{" "}
              </h5>
              <span className="d-block f-s16 font-Visby-cf f-w5 ">
                {redeemedItemNames.join(', ')} can either be redeemed by reward or by offer.
              </span>
            </>
           )} 
          {offerFailedCriteria === OFFER_DISCOUNTED_SUBTOTAL_ZERO && (
            <>
              <span className="d-block f-s16 font-Visby-cf f-w5 clr-red-dark">
                Cart Is Already Free.
              </span>
            </>
          )}
          {offerFailedCriteria === PICK_UP_ORDER_TYPE && (
            <>
              <span className="d-block f-s16 font-Visby-cf f-w5 clr-red-dark">
                You can only apply this offer on delivery orders.
              </span>
            </>
          )}
          {!offerFailedCriteria ? (
            <>
              <span className="d-block f-s18 font-Visby-cf f-w5 clr-red-dark">
                Your Cart Is Empty.
              </span>
            </>
          ) : null}
        </>
      </Modal.Body>
    </Modal>
  );
};

export default OfferEligibilityModal;
