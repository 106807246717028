import { useMutation } from "react-query";
import { IUpdateOrderPayload } from "../../models/order.model";
import { orderMicroService } from "../../services";

const updateItemOrder = async (payload: IUpdateOrderPayload) => {
    const { item_id, item} = payload;
    const response = await orderMicroService.updateItem(item_id, item);
    return response.data.data;
};

export default function useUpdateItem() {
    return useMutation((payload: IUpdateOrderPayload) => updateItemOrder(payload));
}