import { useMutation } from "react-query";
import { ItemFields } from "../../models/Menu.model";
import { orderMicroService } from "../../services";

const addFavoriteOrder = async (item) => {
  //TODO: Replace customer id with an actual current login customer id
  const data = {
    is_favorite: true,
    favorite_order_name: item.order_name,

  };
  const response = await orderMicroService.addOrderItemToFavorites(item.id,data)
  return response.data.data;
};

export default function useAddFavorites() {
  return useMutation((item: ItemFields) => addFavoriteOrder(item));
}
