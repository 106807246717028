import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { isSizeEqual } from "../components/utils";
import { HALF_SIZE} from "../constants";
import { useStoreLocation } from "../context/StoreLocation";
import { useAuthState } from "../context/UserAuthentication";
import { guestLogin } from "../context/UserAuthentication/actions";
import { brazeLogCustomEventAddedToCart } from "../helpers/brazeHelper";
import { getAddToOrderPayloadToSync, getUpdateOrderPayloadToSync } from "../helpers/cartHelperMethods/dataTransformation";
import { createOrderPayloadModifiers } from "../helpers/customizedItem/customizedItem.helper";
import { getUser, getVisitorId } from "../helpers/helperMethods";
import { Toast_Func } from "../helpers/toast.helper";
import { IRouteParams } from "../models/order";
import { IAddToOrderPayload, IUpdateOrderItemPayload } from "../models/order.model";
import useAddToCart from "../react-query-hooks/Cart/useAddToCart";
import useUpdateItem from "../react-query-hooks/Cart/useUpdateItem";
import useGetRewards from "../react-query-hooks/useGetRewards";
import useItem from "../react-query-hooks/useItem";
import {
  setOrderId,
  addItemToOrder,
  updateItemToOrder,
} from "../redux/slices/cartSlice";
import { useAppDispatch, useAppSelector } from "../redux/store/store";
import useCheckMobileScreen from "./useCheckMobileScreen";
import {customerBusinessService} from "../services";
// import useSyncCart from "./useSyncCart";

interface IAddToOrder {
  addToOrder: (structuredOrder, isCombo?: boolean, quantity?:number) => Promise<boolean>;
  updateOrder: (structuredOrder, isCombo?: boolean, quantity?:number) => Promise<boolean>;
  loading: boolean;
}

interface ILocation {
  search: string;
  state: any;
}

interface IResponse {
  success: boolean;
  status?: string;
  items?: any[];
  message: string;
  response?: any;
}

const useAddToOrder = (): IAddToOrder => {
  let { mutateAsync: addToCart } = useAddToCart();
  const cart = useAppSelector(state => state.cart);
  const isItMobile = useCheckMobileScreen();
  let { mutateAsync: updateOrderToCart } = useUpdateItem();
  const [loading, setLoading] = useState(false);
  const history:any = useHistory();
  const dispatch = useAppDispatch();
  const { locationInfo } = useStoreLocation();
  const location_id = locationInfo.selectedStore?.id
  const { authInfo, dispatch: authDispatch } = useAuthState();
  let { id: item_id } = useParams<IRouteParams>();
  if (item_id && item_id.includes("?")) {
    item_id = item_id.split("?")[0];
  }
  const { data: allRewards = [] } = useGetRewards(
    authInfo.userId,
    locationInfo.selectedStore?.id,
    1,
    1,
    1,
    authInfo?.type
  );

  const order = useSelector((state: any) => state.itemCustomization);
  let { search, state }: ILocation = useLocation();
  const query = new URLSearchParams(search);
  const category_id = query.get("category_id");
  const { data: item } = useItem({
    location_id: location_id,
    category_id: category_id,
    item_id: item_id,
  });
  const showCustomization = state?.showCustomization ?? false
  // const showCustomization = (state as { showCustomization?: boolean })
  //   ?.showCustomization;
  // const reward_size = (state as { reward_size?: string })?.reward_size;
  // const index = (state as { index?: number })?.index;
  // const isRedeemed = (state as { isRedeemed?: boolean })?.isRedeemed;
  // const rewardedItem = (state as { rewardedItem?: any })?.rewardedItem;

  const getItemRedeemPoints = (order: any) => {
    return order.item_size === HALF_SIZE.id ? order.half_loyalty_points
        : order.full_loyalty_points
  }
  const createOrderObj = (order: any, isCombo) => {
    if (isCombo) return order;
    let finalOrder = {};
    if (state?.reward_size) {
      const itemFoundInRewards = allRewards?.rewards.filter((reward) => {
        if (reward.item_id) {
          return (
            !reward.is_active_in_cart &&
            reward.redirect_item_id == order.item_id &&
            isSizeEqual(order, reward) &&
            !reward.is_redeemed && !reward.is_expired
          );
        }
        return (
          !reward.is_active_in_cart &&
          reward.redirect_category_id == category_id &&
          isSizeEqual(order, reward) &&
          !reward.is_redeemed && !reward.is_expired
        );
      });
      itemFoundInRewards.sort((a, b) => {
        if (a.redirect_item_id) {
          return -1;
        }
        return 1;
      });
      if (itemFoundInRewards.length === 0) {
        finalOrder = {
          ...order,
          id: order.item_id,
          price: order.display_price ? order.display_price : order.price,
          category_id,
        };
      } else {
          finalOrder = {
            ...order,
            id: order.item_id,
            price: order.display_price ? order.display_price : order.price,
            category_id,
            points: itemFoundInRewards[0].points,
            reward_id: itemFoundInRewards[0]?.loyalty_id,
            reward_redeemed: true
          };
      }
      if (state?.reward_size == HALF_SIZE.id) {
        finalOrder["price"] = item.half_brink_item_price;
        finalOrder["display_price"] = item.half_brink_item_price;
      }
    } else {
      finalOrder = {
        ...order,
        id: order.item_id,
        price: order.display_price ? order.display_price : order.price,
        category_id,
        free: state?.reward_size ? true : false,
      };
    }
    //In case of Edit
    if (state?.isRedeemed) {
      // let updatedTree = null;
      if (order.size != state?.rewardedItem.size) {
        if (order.quantity > 1) {
          // finalOrder = {
          //   ...finalOrder,
          //   quantity: order.quantity - 1,
          // };
          if (state?.rewardedItem) {
            // const cartCopy = structuredClone(Cart.cart);
            // updatedTree = updateItemFromCart(
            //   order.item_id,
            //   index,
            //   { ...rewardedItem, quantity: 1 },
            //   cartCopy
            // );
            // updatedTree = structuredClone(updatedTree);
            // updatedTree.itemTree = compareTree(
            //   finalOrder,
            //   updatedTree.itemTree,
            //   false,
            //   updatedTree,
            //   dispatch,
            //   incrementIndex
            // );
            // dispatch(updateCart(updatedTree));
          }
        }
      }
    }
    return finalOrder;
  };
  const authenticateGuest = async () => {
    try {
      const payload = { visitor_id: getVisitorId()}
      return await guestLogin(authDispatch, dispatch,payload)
    } catch (error) {
      return false;
    }
  }
  const addToOrder = async (structuredOrder: any, isCombo: boolean = false, quantity) => {
    if(history.location.state?.is_recommended){
      structuredOrder.is_recommended_item = true;
      structuredOrder.isUpsellItem= true;
      history.replace({
        ...history.location,
        state: undefined,
      });
    }
    setLoading(true);
    const user =  getUser()
    if( !user.token ){
      const response = await authenticateGuest()
      if( !response ) {
        setLoading(false);
        return false;
      }
    }
    
    const isUpsellItem = structuredOrder.isUpsellItem;
    delete structuredOrder.isUpsellItem;
    
    if (!isUpsellItem && showCustomization && order.id)
      return updateOrder(structuredOrder, isCombo, quantity);
    
    let finalOrder = createOrderObj(structuredOrder, isCombo);
    const dataForBackend = await dataToSyncBackendRequest(finalOrder,isCombo, quantity);
    const isSynced = await syncOrderWithBackend(dataForBackend);
    // if( isSynced.success ){
    //   Toast_Func({
    //     status: true,
    //     message: "Added to Cart!"
    //   })
    // }
    setLoading(false);
    return isSynced.success;
  };

  const updateOrder = async (structuredOrder: any, isCombo: boolean = false, quantity) => {
    const dataForBackend = await dataToSyncUpdateOrderBackendRequest(structuredOrder,isCombo, quantity);
    const isSynced = await syncOrderWithBackend(dataForBackend, true);
    Toast_Func({ status: isSynced.success, message: isSynced.message });
    if (!isCombo && isSynced.success) history.push('/menu/Seasonal')  //history.go(-1); 
    setLoading(false);
    return isSynced.success;
  };

  const syncOrderWithBackend = async (payload, isUpdate = false): Promise<IResponse> => {
    try {
      let response;
      if( isUpdate ){
        response = await updateOrderToCart({ item_id: order.id, item: payload});
      } else {
        if(payload.items[0].reward_redeemed)
        {
          customerBusinessService.updateReward(payload?.items[0]?.reward_id, {is_active_in_cart: Number(payload?.items[0]?.reward_redeemed)})
        }
        response = await addToCart(payload);
      }
      if (response) {
        //putting this in order to check if the cart?.orderId is present or not
        if (response.id) dispatch(setOrderId(response.id));
        !isUpdate && brazeLogCustomEventAddedToCart(cart, response?.id, payload.items, isItMobile)
        !isUpdate ? dispatch(addItemToOrder(response.items)) : dispatch(updateItemToOrder(response)) 
        return {
          success: true,
          status: "complete",
          message: "Item updated successfuly",
          response: response
        };
      } else {
        return {
          success: false,
          status: "failed",
          message: "Either item or any modifier is unavailable!",
        };
      }
    } catch (error) {
      return {
        success: false,
        status: "failed",
        message: "Either item or any modifier is unavailable!",
      };
    }
  };
  const dataToSyncBackendRequest = async (finalOrder,isCombo, quantity?) => {
    const OrderData = {
      ...finalOrder,
      set_id: 1,
      quantity: quantity ?? 1,
    };
    const payload: IAddToOrderPayload = getAddToOrderPayloadToSync(authInfo);
    payload.items = [OrderData];
    payload.items.forEach((element) => {
      element = createOrderPayloadModifiers(element, isCombo)
    });
    return payload;
  };


  const dataToSyncUpdateOrderBackendRequest = async (finalOrder,isCombo, quantity:number) => {
    let payload: IUpdateOrderItemPayload = getUpdateOrderPayloadToSync(authInfo);
    payload = {
      ...payload,
      ...finalOrder,
      id: order.id,
      quantity: quantity ?? 1,
    }
    const finalPayload = createOrderPayloadModifiers(payload, isCombo)
    return finalPayload;
  };

  return { addToOrder,updateOrder, loading };
};

export default useAddToOrder;
