import React, { useEffect, useState } from "react";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useQueryClient } from "react-query";
import useProfile from "../../../react-query-hooks/useProfile";
import { useAuthState } from "../../../context/UserAuthentication";
import styleClasses from "../../../containers/account/accounts.module.scss";
import { Toast_Func } from "../../../helpers/toast.helper";
import { API_ERROR } from "../../../models/error.model";
import useRemoveVehicle from "../../../react-query-hooks/useRemoveVehicle";
import { VehicleInfoFormFields } from "../../../models/forms.model";
import VehicleCardDesktop from "./VehicleCardDesktop";
import VehicleCardMobile from "./VehicleCardMobile";
import useUpdateVehicleDetails from "../../../react-query-hooks/useUpdateVehicleDetails";
import DefaultMethodConfirmationModal from "../DefaultMethodConfirmation/DefaultMethodConfirmation";
import useCheckMobileScreen from "../../../hooks/useCheckMobileScreen";
import {useAppSelector} from "../../../redux/store/store";

interface VehicleDetailsTabProps {
  showModel?: (title?: string, id?: number, forOneTimeOnly?: boolean) => void;
  vehicleInfoForOneTime: VehicleInfoFormFields[];
  removeVehicleInfoForOneTime?: (id: number) => void;
  vehicles: any;
}

const handleFormSubmission = async () => {
  console.log("🚀 ~ Submit");
  //TODO: API Integration
};

const VehicleInfo: React.FunctionComponent<VehicleDetailsTabProps> = ({
  showModel,
  vehicleInfoForOneTime,
  removeVehicleInfoForOneTime,
  vehicles,
}) => {
  const { authInfo } = useAuthState();
  const { isLoading, data, error } = useProfile(authInfo.userId);
  const queryClient = useQueryClient();
  const { mutateAsync } = useUpdateVehicleDetails();
  const [showRemoveVehicle, setRemoveVehicle] = useState(false);
  const [isRemovingVehicle,setIsRemovingVehicle] = useState<boolean>(false);
  const [removeVehicleId, setRemoveVehicleId] = useState<null | number>();
  const [defaultVehicle,setDefaultVehicle]=useState(null)
  const { mutate } = useRemoveVehicle();
  const isItMobile = useCheckMobileScreen()
  useEffect(()=>{
    if(!defaultVehicle){
      setDefaultVehicle(vehicles.find(vehicle=>vehicle.is_default==true))
    }
  },[defaultVehicle])
  const handleChangeDefault = async (id) => {
    const changedRecord = vehicles.find((vehicle) => vehicle.id == id);
    setDefaultVehicle(changedRecord);
    return mutateAsync(
      {
        vehicleInfo: {...changedRecord,is_default:1},
        vehicleId: id,
      },
      {
        onSuccess: (data) => {
          Toast_Func({ status: true, message: "Default updated successfully" });
          queryClient.invalidateQueries("profile");
        },
        onError: (error) => {
          console.log(error)
          Toast_Func({ status: false, message: error });
          setDefaultVehicle(vehicles.find(rec=>rec.is_default==true))
        },
      }
    );
  };
  const savedOrderDetails = useAppSelector(
      (state) => state.cart.orderDetails
  );
  const handleVehicleRemove = (recordID) => {
    if(savedOrderDetails?.vehicleDetails?.id == recordID)
    {
      Toast_Func({
        status: false,
        message: "Vehicle selected for order checkout can not be deleted",
      })
    }
    else {
      setRemoveVehicle(true)
    }
  }
  const removeVehicle = (id: number) => {
    setIsRemovingVehicle(true);
    mutate(
      {
        id: id,
      },
      {
        onSuccess: (data, variables, context) => {
          queryClient.invalidateQueries("profile");
          setRemoveVehicle(false);
          setIsRemovingVehicle(false);
          Toast_Func({
            status: true,
            message: "Vehicle removed successfully",
          });
        },
        onError: (error, variables, context) => {
          setRemoveVehicle(false);
          setIsRemovingVehicle(false);
          Toast_Func({
            status: false,
            message: (error as API_ERROR).response.data["message"],
          });
        },
      }
    );
  };
  
  return (
    <div className={`${styleClasses.user_info_wrap} text-start`}>
      <Row>
        <Col>
          <div className={`${styleClasses.user_info} ${styleClasses.listing_style}`}>
          <h6 className="f-s14 text-uppercase f-s14 h-17">Stored Vehicle</h6>
            <ul className="list-inline text-start mb-0 ps-0 pe-3 pe-sm-0">
              {data.vehicles.map((vehicle) => (
                <li key={vehicle.id} className={`${styleClasses.inner_list_wrap} mb-0`}>
                  {isItMobile ? 
                  <VehicleCardMobile
                    vehicle={vehicle}
                    handleFormSubmission={handleFormSubmission}
                    showModal={showModel}
                    setRemoveVehicle={setRemoveVehicle}
                    setRemoveVehicleId={setRemoveVehicleId}
                    handleChangeDefault={handleChangeDefault}
                    defaultVehicle={defaultVehicle}
                    vehicleCount={data.vehicles?.length}
                    handleVehicleRemove={handleVehicleRemove}
                  /> :         
                  <VehicleCardDesktop
                    vehicle={vehicle}
                    handleFormSubmission={handleFormSubmission}
                    showModal={showModel}
                    setRemoveVehicle={setRemoveVehicle}
                    setRemoveVehicleId={setRemoveVehicleId}
                    handleChangeDefault={handleChangeDefault}
                    defaultVehicle={defaultVehicle}
                    vehicleCount={data.vehicles?.length}
                    handleVehicleRemove={handleVehicleRemove}
                  /> 
                  }
                </li>
              ))}
              <hr className="custom_hr_sty mb-4 mt-5 d-md-none" />
              <div className="d-flex justify-content-center ">
                <button
                  type="button"
                  className={`${styleClasses.add_vehicle_btn} btn btn-large py-2 f-s16`}
                  onClick={() => showModel(" Vehicle Details")}
                  disabled={data?.vehicles?.length >= 5}
                >
                  Add Vehicle Details
                </button>
              </div>
            </ul>
            <OverlayTrigger
              trigger={["hover","focus"]}
              overlay={
                data?.vehicles?.length >= 4 ? (
                  <Tooltip className="custom_tooltip" id="tooltip-disabled">
                    Maximum 5 Vehicles are allowed.
                  </Tooltip>
                ) : (
                  <span className="d-none"></span>
                )
              }
            >
              <></>
            </OverlayTrigger>
          </div>
          <div className="d-flex justify-content-center mt-4"></div>
        </Col>
      </Row>
      <DefaultMethodConfirmationModal
        show={showRemoveVehicle}
        title="Remove This Vehicle Details"
        content="You’re requesting to remove this Vehicle Details. Would you like to continue?"
        btnText={"remove"}
        handleClick={(e) => {
          if ( isRemovingVehicle ) return;
          removeVehicle(removeVehicleId);
        }}
        shouldDisableButton={isRemovingVehicle}
        handleClose={() => {
          setRemoveVehicle(false);
        }}
      />

    </div>
  );
};

export default VehicleInfo;
