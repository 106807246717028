import React, { useState } from "react";
import styleClasses from "./liveTracking.module.scss";
import {
  CURBSIDE,
  ROUTE_CHECKOUT,
  ROUTE_MENU,
  SCHEDULED,
} from "../../constants";
import OrderDetails from "../../containers/order/OrderDetails/OrderDetails";
import PaymentMethod from "../../containers/order/PaymentMethod/PaymentMethod";
import Billing from "../Cart/Billing/Billing";
import TrackingVehicleDetails from "../OnlineOrderTracking/TrackingVehicleDetails";
import Button from "../Buttons/Button";
import CancelOrderModal from "./CancelOrderPrompt";
import { orderMicroService } from "../../services";
import { Toast_Func } from "../../helpers/toast.helper";
import { useHistory, useLocation } from "react-router";
import { getOrderType } from "../../helpers/checkout/PlaceOrderHelper";
import useCheckMobileScreen from "../../hooks/useCheckMobileScreen";
import { brazeLogCustomEventOrderCancelled } from "../../helpers/brazeHelper";
import { getUserId } from "../../helpers/helperMethods";
import { useQueryClient } from "react-query";
import { useFavoriteLikeOrDisLike } from "../OnlineOrderTracking/OrderTrackingDetails";
import {
  AddToFavoriteModalConfirmation,
  LabelFavoriteOrder,
} from "../../containers/account/profileDetails";
import FavoriteButton from "../../containers/try2/Customization/FavoriteButton";
import { updateTrackOrder } from "../../redux/slices/checkoutSlice";
import { useAppDispatch } from "../../redux/store/store";

interface ICustomerVehicle {
  car_make?: string;
  car_model?: string;
  car_color?: string;
}

const OrderDetailsColumn: React.FC<any> = ({
  cartItems,
  pickUpMethod,
  order,
  refetch,
  handleClose,
  isPublic,
  encryptedOrderId,
  titlespace,
  isOrderCancelledOrFailed,
  doorDashStatus,
  orderNo,
  setIsActiveOrders,
}) => {
  const {
    customer: { car_make, car_model, car_color } = {} as ICustomerVehicle,
  } = order;
  const isItMobile = useCheckMobileScreen();
  const queryClient = useQueryClient();
  const location = useLocation();
  const [showCancelOrder, setShowCancelOrderModal] = useState(false);
  const [loading, setLoading] = useState(false);
  let canCancelOrder = !order.brink_order_id && !order.is_cancelled;
  if (doorDashStatus && doorDashStatus !== SCHEDULED) canCancelOrder = false;
  const history = useHistory();
  const refetchOrderDetails = () => refetch({ orderId: order?.orderId });
  const dispatch = useAppDispatch();
  const {
    decideShowLabelOrDelectionModal,
    labelOrderModal,
    closeLabelOrderModal,
    onAddLabelOrderHandler,
    favoriteModal,
    hideFavoriteModal,
    removeOrderFromFavoriteHandler,
    labelFavoriteItemName,
    isFavoriteItem,
  } = useFavoriteLikeOrDisLike({ orderDetails: order, refetchOrderDetails });

  const cancelOrder = async () => {
    try {
      setLoading(true);
      if (isPublic) {
        const response = await orderMicroService.cancelEncryptedOrder(
          encryptedOrderId
        );
        Toast_Func({
          status: true,
          message: response.data.data,
        });
        dispatch(updateTrackOrder(false));
        history.push(ROUTE_MENU);
      } else {
        const response = await orderMicroService.cancelOrder(order.id);
        setIsActiveOrders(Boolean(response?.data?.active_orders));
        if (response.status) {
          Toast_Func({
            status: true,
            message: response.data.data,
          });
          dispatch(updateTrackOrder(false));
          handleClose();
          if (orderNo === 0 && location.pathname === ROUTE_CHECKOUT)
            history.push(ROUTE_MENU);
        }
      }
      brazeLogCustomEventOrderCancelled(order, isItMobile);
      setShowCancelOrderModal(false);
      setLoading(false);
      queryClient.refetchQueries(["myRewards", getUserId()], null, {
        throwOnError: true,
      });
    } catch (error) {
      Toast_Func({
        status: false,
        message:
          error?.response?.data?.message ?? "Order could not be cancelled!",
      });
      setShowCancelOrderModal(false);
      refetch({});
      setLoading(false);
    }
  };
  return (
    <div className={`${styleClasses.order_detail_col} position-relaative`}>
      <div className="d-flex">
        <h2 className="mb-0 ps-2 font-Cls fw-normal text-start text-md-center f-s16 f-sm-only-s20 text-uppercase py-3 py-md-0">
          Order Details
        </h2>
        {
          !isPublic &&
            <FavoriteButton
              favIcon={true}
              buttonHandler={decideShowLabelOrDelectionModal}
              className={`d-block d-lg-block position-static ms-auto mb-2 favorite-icon-blue`}
              // item={order}
              favourite={isFavoriteItem}
              fromOrder={true}
              isIconblueFill={true}
            />
        }
      </div>
      <hr className="mb-3 mt-md-2 d-md-block d-none" />
      <div className="ps-md-4 pe-md-3 ">
        <OrderDetails
          isOrdered={true}
          Items={cartItems}
          Titlehide="d-none"
          Imagehide="d-md-block d-none"
          ScrollCard={`${styleClasses.scroll_card_detail}`}
          favoriteButtonStyles={`${styleClasses.favoriteButtonStyles}`}
          Buttonspace="me-2"
          orderDetails={order}
          labelFavoriteItemName={labelFavoriteItemName}
          isFavoriteItem={isFavoriteItem}
          refetchOrderDetails={refetchOrderDetails}
          decideShowLabelOrDelectionModal={decideShowLabelOrDelectionModal}
        />
      </div>
      {pickUpMethod === CURBSIDE && (
        <div className="ps-md-4 pe-md-3">
          <TrackingVehicleDetails
            titleStyle={
              "cz-dark-gray font-Cls f-sm-only-s20 f-w5 f-s16 pt-md-0 pt-4"
            }
            make={car_make}
            model={car_model}
            color={car_color}
          />
        </div>
      )}
      <div className="ps-md-4 pe-md-3">
        <hr className="my-md-4 my-2 d-md-none" />
        {!order?.is_free && (
          <PaymentMethod
            titleStyle={"cz-dark-gray font-Cls fw-normal f-sm-s16 f-s20"}
            LineHide="d-md-none"
            paymentDetails={order.payment_details ?? []}
            tableEndSpacing={styleClasses.tableEndSpacing}
          />
        )}
      </div>
      <div className="pe-3">
        <Billing
          CheckoutBtnHide="d-none"
          OrderTotal="pt-md-4 ps-md-4 ps-0"
          listspace="pt-0 ps-md-4 ps-0 pe-0 pe-md-4"
          cartItems={cartItems}
          isOrdered={true}
          titleStyle={"cz-dark-gray ps-0 ps-md-4"}
          orderTippingAmount={order?.tip}
          tax={order?.tax}
          titlespace={titlespace}
          orderType={getOrderType(Number(order.destination_id))}
          order={order}
        />
      </div>
      {canCancelOrder && (
        <div className="text-center mt-4">
          <Button
            type="button"
            className="mb-5 position-relative"
            onClick={() => setShowCancelOrderModal(true)}
          >
            Cancel Order
          </Button>
        </div>
      )}
      <LabelFavoriteOrder
        showAddLabelModal={labelOrderModal}
        hideLabelModal={closeLabelOrderModal}
        onAddLabelOrderHandler={onAddLabelOrderHandler}
      />
      <AddToFavoriteModalConfirmation
        showAddAndRemoveModal={favoriteModal}
        hideAddAndRemoveModal={hideFavoriteModal}
        id={"favoutie-modal"}
        name={"Modal"}
        onRemoveClickHandler={() => {
          hideFavoriteModal();
          removeOrderFromFavoriteHandler();
        }}
      />
      <CancelOrderModal
        showModal={showCancelOrder}
        loading={loading}
        handleClose={() => setShowCancelOrderModal(false)}
        cancelOrder={cancelOrder}
        order={order}
      />
    </div>
  );
};

export default OrderDetailsColumn;
