import {
  ACTIVE,
  ASSEMBLED,
  ASSIGNED,
  CANCELLED,
  COMPLETE,
  CURBSIDE,
  CURBSIDE_DESTINATION_ID,
  DELIVERED,
  DELIVERY,
  DELIVERY_DESTINATION_ID,
  FAILED,
  GOOD_LIFE_LANE,
  GOOD_LIFE_LANE_DESTINATION_ID,
  IN_STORE,
  IN_STORE_DESTINATION_ID,
  PREPARED,
  PROCESSING,
  QUEUE_FOR_DOOR_DASH,
  SCHEDULED,
} from "../../constants";
//AWS Imports 
import { Consumer } from 'sqs-consumer';
import { SQS } from 'aws-sdk';
import { formatTime } from "./orderTracking";


export const PickUpMethods = {
  [DELIVERY_DESTINATION_ID]: DELIVERY,
  [IN_STORE_DESTINATION_ID]: IN_STORE,
  [CURBSIDE_DESTINATION_ID]: CURBSIDE,
  [GOOD_LIFE_LANE_DESTINATION_ID]: GOOD_LIFE_LANE,
};

export const OrderStatusHeadline = {
  [PROCESSING]: "Your Order is Being Processed",
  [ACTIVE]: "Your Order is Being Prepared",
  [PREPARED]: "Your Order is Being Prepared",
  [ASSEMBLED]: "Your Order is Being Packaged",
  [COMPLETE]: "Your Order is Ready to Pick-Up",
  [SCHEDULED]: "Driver Has Not Been Assigned Yet",
  [ASSIGNED]: "Driver is Enroute to Pick Up Your Order",
  [DELIVERY]: "Your Order is On The Way",
  [CANCELLED]: "Your Order has been Cancelled",
  [FAILED]: "Your Order is Failed",
};

export const formatOrderTime = (orderDateTime) => {
  const time = orderDateTime?.split(" ")?.[1]
  return formatTime(time)
}

export const formatOrderHeadline = (pickUpMethod: string, orderSatus: string, doorDashStatus: string) => {
  const isOrderReady = orderSatus === COMPLETE
  const isDeliveryOrderReady = pickUpMethod === DELIVERY && isOrderReady
  const isOrderReadyForRider = isDeliveryOrderReady && doorDashStatus === ASSIGNED;
  const isRiderUnAssigned = isDeliveryOrderReady && [null, SCHEDULED].includes(doorDashStatus);
  const isOrderDelivered = isDeliveryOrderReady && doorDashStatus === DELIVERED;

  if ( isOrderDelivered ) return DELIVERED
  if ( isRiderUnAssigned ) return OrderStatusHeadline[SCHEDULED]
  if ( isOrderReadyForRider ) return OrderStatusHeadline[ASSIGNED]
  if ( isOrderCancelledOrFailed(orderSatus) ) return OrderStatusHeadline[orderSatus]

  const orderStatusHeadline = isDeliveryOrderReady
    ? OrderStatusHeadline[DELIVERY]
    : OrderStatusHeadline[orderSatus];
  return orderStatusHeadline || OrderStatusHeadline[PROCESSING]
}

export const isOrderCancelledOrFailed = (orderStatus: string) => {
  return [CANCELLED, FAILED].includes(orderStatus)
}

export const getStatusQueueApp = ({
  orderId,
  setState,
  queueFor = QUEUE_FOR_DOOR_DASH,
}: {
  orderId: string | number;
  setState: (data: any) => void;
  queueFor: string;
}) => {  
  const queue = queueFor === QUEUE_FOR_DOOR_DASH ? "doordash" : "trackingorder"
  const queueUrl = `https://sqs.${process.env.REACT_APP_AWS_SQS_REGION}.amazonaws.com/${process.env.REACT_APP_AWS_USER_ID}/${queue}${orderId}.fifo`
  const app = Consumer.create({
    queueUrl,
    handleMessage: async (message) => {
      setState(JSON.parse(message.Body));
    },
    sqs: new SQS(),
    authenticationErrorTimeout: 5000,
  });

  app.on("error", (err) => {
    console.log("error:", err.message);
    app.stop()
  });

  app.on("processing_error", (err) => {
    console.log("processing_error:", err.message);
  });

  app.on("timeout_error", (err) => {
    console.log("timeout_error:", err.message);
  });

  return app;
};
