import React from "react";
import {LocationModel} from "../../../models/location.model";


interface ISelectedLocationForPickup {
    selectedLocation: LocationModel,
}


const SelectedLocationForPickup = (props: ISelectedLocationForPickup) => {
    const {
        selectedLocation,
    } = props

    return(
        <>
            <div className="justify-start-center my-4">
                <span className="d-block f-s14 font-Visby-cf f-w5">
                  {selectedLocation.name}
                </span>
                <span className="d-block f-s14 font-Visby-cf f-w5">
                  {selectedLocation.address}
                </span>
                <span className="d-block f-s14 font-Visby-cf f-w5">
                  {selectedLocation.city}, {selectedLocation.state}{" "}
                  {selectedLocation.zip}
                </span>
            </div>
        </>
    )
}

export default SelectedLocationForPickup;