import { useMutation } from "react-query";
import { IAddToOrderPayload } from "../../models/order.model";
import { orderMicroService } from "../../services";

const addToCart = async (payload: IAddToOrderPayload) => {
    const response = await orderMicroService.addItemToCart(payload);
    return response.data.data;
};

export default function useAddToCart() {
    return useMutation((payload: IAddToOrderPayload) => addToCart(payload));
}
