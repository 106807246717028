import React from "react";
import styleClasses from "../../ConfirmatioModal/Confirmation.module.scss";
import { Modal, Button, Container, Row, Col } from "react-bootstrap";

interface IConfirmationModal {
  show: boolean;
  title: string;
  content: string;
  btnText: string;
  handleClose: () => void;
  handleClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  shouldDisableButton?: boolean;
}
const DefaultMethodConfirmationModal: React.FunctionComponent<
  IConfirmationModal
> = ({ show, handleClose, handleClick, title, content, btnText, shouldDisableButton }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className="theme-modal-style app_container_wrap nor-padding border-radius-15"
    >
      <Modal.Header className="x-4">
        <button
          type="button"
          className="btn modal-close-button pe-0"
          onClick={handleClose}
        >
          Close
        </button>
      </Modal.Header>
      <Modal.Body className="app_container_wrap px-1 py-2 text-center">
        <Container>
          <Row>
            <Col xs="12" sm="12" className="pt-4">
              <h5 className="font-Cls f-s22 text-capitalize">{title}</h5>
              <p className={styleClasses.modal_description + " f-s14"}>
                {content}
              </p>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer className="flex-column">
        <Button
          variant="btn-custom"
          className="btn btn-large  d-block mx-auto sm-w100"
          onClick={handleClick}
          disabled={shouldDisableButton}
        >
          {btnText}
        </Button>
        <Button
          variant="btn-custom"
          onClick={handleClose}
          className="btn btn-custom  font-Vcf color-sky-blue-light"
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DefaultMethodConfirmationModal;
