import React, { useState } from "react";
import { useQueryClient } from "react-query";
import { Toast_Func } from "../../../../helpers/toast.helper";
import { API_ERROR } from "../../../../models/error.model";
import DefaultMethodConfirmationModal from "../../DefaultMethodConfirmation/DefaultMethodConfirmation";
import useCheckMobileScreen from "../../../../hooks/useCheckMobileScreen";
import {useAppSelector} from "../../../../redux/store/store";
import {appliedPaymentMethods} from "../../../../helpers/checkout/PaymentHelper";
import useRemoveGiftCard from "../../../../react-query-hooks/useRemoveGiftCard";
import {IGiftCard} from "../../../../models/giftCard.model";
import StoredCardMobileView from "./StoredCardMobileView";
import StoredCardDesktopView from "./StoredCardDesktopView";
interface IStoredGiftCardProps {
  record: IGiftCard;
  index: number;
  handleEdit: (record: IGiftCard) => void;
  defaultPayment: IGiftCard;
  handleChangeDefault: (id: string) => void;
  paymentCount?: number;
  closeConfirmationModal?: boolean;
}

const handleFormSubmission = async () => {
  console.log("🚀 ~ Submit");
  //TODO: API Integration
};

const StoredGiftCard: React.FunctionComponent<IStoredGiftCardProps> = ({
  record,
  handleEdit,
  index,
  defaultPayment,
  handleChangeDefault, paymentCount, closeConfirmationModal
}) => {
  const queryClient = useQueryClient();
  const { mutate: removeCard } = useRemoveGiftCard();
  const isItMobile = useCheckMobileScreen()
  const [confirmRemovePayment, setConfirmRemovePayment] = useState(false);
  const [
    confirmDefaultPaymentMethodSelection,
    setConfirmDefaultPaymentMethodSelection,
  ] = useState(false);
  const checkout = useAppSelector((state) => state.checkout);
  const paymentMethods = checkout.paymentMethods
  const addedCards = checkout.giftCard
  const allSelectedCards = appliedPaymentMethods(paymentMethods, addedCards);

  const handleCardRemove = (recordID) => {
    const paymentIndex = allSelectedCards?.findIndex((card) => card.id === recordID )
    if(paymentIndex !== -1)
    {
      Toast_Func({
        status: false,
        message: "Payment selected for order checkout can not be deleted",
      })
    }
    else {
      setConfirmRemovePayment(true)
    }
  }
  const initialFormState = {
    name: record.name || "",
    number: record.gift_card_no || "",
  };
  const removePaymentMethod = (
    e: React.MouseEvent<HTMLButtonElement>,
    id: string
  ) => {
    e.preventDefault();
    removeCard(
      {
        cardId: id,
      },
      {
        onSuccess: (data, variables, context) => {
          queryClient.invalidateQueries("giftCards");
          queryClient.invalidateQueries(["profile"]);
          setConfirmRemovePayment(false);
          Toast_Func({
            status: true,
            message: "Payment Method removed successfully",
          });
        },
        onError: (error: any) => {
          Toast_Func({ status: false, message: error?.response?.data?.message });
        },
      }
    );
  };
  return (
    <>
      <li key={defaultPayment?.id.toString()}>
        {
          isItMobile ?
            <StoredCardMobileView
                record={record}
                handleEdit={handleEdit}
                defaultPayment={defaultPayment}
                setConfirmDefaultPaymentMethodSelection={setConfirmDefaultPaymentMethodSelection}
                handleCardRemove={handleCardRemove}
                paymentCount={paymentCount}
            />
              :
              <StoredCardDesktopView
                  record={record}
                  handleEdit={handleEdit}
                  defaultPayment={defaultPayment}
                  setConfirmDefaultPaymentMethodSelection={setConfirmDefaultPaymentMethodSelection}
                  handleCardRemove={handleCardRemove}
                  paymentCount={paymentCount}
              />
        }
      </li>
      <DefaultMethodConfirmationModal
        show={confirmRemovePayment}
        title="Remove This Gift Card"
        content="You’re requesting to remove this Gift Card. Would you like to continue?"
        btnText={"remove"}
        handleClick={(e) => {
          removePaymentMethod(e, record.id);
        }}
        handleClose={() => {
          setConfirmRemovePayment(false);
        }}
      />

      <DefaultMethodConfirmationModal
        show={confirmDefaultPaymentMethodSelection && !closeConfirmationModal}
        title="Change Default Gift Card"
        content="You’re requesting to change the default gift card. Would you like to continue?"
        btnText={"Change"}
        handleClick={() => {
          handleChangeDefault(record.id);
        }}
        handleClose={() => {
          setConfirmDefaultPaymentMethodSelection(false);
        }}
      />
    </>
  );
};

export default StoredGiftCard;
