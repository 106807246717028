import React from "react";
import styleClasses from "../../Header/Header.module.scss";

interface IAutoRedeemToggle {
    handleAutoUseReward?: () => void;
    autoRedeem: boolean;
    isLoading: boolean;
}

const AutoRedeemToggle: React.FC<IAutoRedeemToggle> = (props) => {
    const {
        handleAutoUseReward,
        autoRedeem,
        isLoading
    } = props;

    return (
         <>
            <div className={styleClasses.use_reward}>
                <div className="form-group theme_check_boxes Switch_btn rounded mb-0">
                  <input
                    disabled={isLoading}
                    type="checkbox"
                    id={`radiobox_cart`}
                    name="address_group"
                    checked={autoRedeem}
                    onChange={handleAutoUseReward}
                  />
                  <label
                    htmlFor={`radiobox_cart`}
                    className="d-flex align-items-center justify-content-center f-s18 f-sm-s16"
                  >
                    <span className="box_wrap mt-1"></span>
                    <span
                      className={`${styleClasses.info_label} d-flex justify-content-between align-items-center font-Visby-cf f-w9 f-s14 clr-dark-blue`}
                    >
                      <img
                        src={require("../../../images/Reward.svg")}
                        className="img-fluid me-2"
                        alt="reward"
                      />{" "}
                      Automatically Use My Rewards
                    </span>
                  </label>
                </div>
            </div>
            <hr className="shadow-divider mb-4" />
        </>
  );
};

export default AutoRedeemToggle;
