import { useQuery } from "react-query";
import { locationMenuService } from "../services";

interface IGetFavoriteLocationPayload {
  favorites_only: boolean;
  user_id: number;
}

const getFavoriteLocations = async (data: IGetFavoriteLocationPayload) => {
  const response = await locationMenuService.getNearbyLocations(data);
  return response.data.data;
};

export default function useGetFavoriteLocations(
  customerId: number,
  isGuestUser: boolean
) {
  const payload: IGetFavoriteLocationPayload = {
    favorites_only: true,
    user_id: customerId,
  };
  return useQuery("getFavoriteLocations", () => getFavoriteLocations(payload), {
    enabled: !isGuestUser && !!customerId,
  });
}
