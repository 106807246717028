import React from "react";
import { Modal } from "react-bootstrap";
import styleClasses from "./ItemGrid.module.scss";
import ItemPrices from "./ItemPrices";
import IMenuItems from "./Types";

interface ModalProps {
  showModal: boolean;
  closeModal: () => void;
  itemDetials: IMenuItems;
  getClories: (itemDetials: IMenuItems) => string;
  location?: any
}
export const ItemDetailsModal: React.FunctionComponent<ModalProps> = ({
  showModal,
  closeModal,
  itemDetials,
  getClories,
  location
}) => {
  return (
    <Modal
      show={showModal}
      centered
      className="theme-modal-style app_container_wrap xs-modal border-radius-15 align-sm-bottom"
      onHide={closeModal}
    >
      <Modal.Header className="pt-0">
        <button
          type="button"
          className="btn modal-close-button pe-0"
          onClick={closeModal}
        >
          Close
        </button>
      </Modal.Header>
      <Modal.Body>
        <div
          className={`${styleClasses.item_main_div} d-flex flex-column h-100 no-shadow`}
        >
          <div
            className={`${styleClasses.item_title_wrap} menu-title d-flex align-items-start justify-content-start text-start`}
          >
            <h6 className="sub_title1">{itemDetials.name}</h6>
          </div>

          <div
            className={`${styleClasses.item_calor_wrap} menu-calories d-flex justify-content-start`}
          >
            <h6 className="h-14 clr-text-grey font-rale mb-3">
              {getClories(itemDetials)}
            </h6>
          </div>
          <p
            className={`${styleClasses.item_subtitle_wrap_} text-start mb-3 p-16`}
          >
            {itemDetials.description}
          </p>
          <h6 className="text-start h-16 font-Vcf ls-normal text-uppercase">
            {location?.selectedStore && <ItemPrices getItem={itemDetials}/> }
          </h6>
        </div>
      </Modal.Body>
    </Modal>
  );
};
