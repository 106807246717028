import { useMutation } from "react-query";
import { customerBusinessService } from "../services";

const updateAddress = async (deliveryAddress: any, addressId: number,authentication_token:string) => {
  const response = await customerBusinessService.updateAddress(addressId, deliveryAddress)
  return response.data.data;
};

export default function useUpdateAddress() {
  return useMutation((data: { deliveryAddress: any; addressId: number,authentication_token:string }) =>
    updateAddress(data.deliveryAddress, data.addressId,data.authentication_token)
  );
}
