import React, { useState } from "react";
import ConfirmationModal from "../../ConfirmatioModal/Confirmation";
import styleClasses from "../../../containers/account/accounts.module.scss";
import { EDIT_ADDRESS_MODAL_TITLE } from "../../../constants";
import DefaultMethodConfirmationModal from "../DefaultMethodConfirmation/DefaultMethodConfirmation";
import { Toast_Func } from "../../../helpers/toast.helper";
const DeliveryadressMobileDesign = ({ address, defaultAddress, handleChangeDefault, setRemoveDelivery,setRemoveAddressId,showModal, addressCount }) => {
  const [confirmDefaultDeliveryMethodSelection, setConfirmDefaultDeliveryMethodSelection] = useState(false);
  return (
    <div>
      <div className="d-md-none">
        <div className="d-flex gap-3 pl-2">
          {
            addressCount > 1 &&
              <div className="position-relative">
            <span className={styleClasses.select_radio_button}>
              <input
                  type="radio"
                  id={address.id}
                  name={address.id + 'mobileRadio'}
                  value={address.id}
                  checked={address.id === defaultAddress?.id}
                  onClick={() => { setConfirmDefaultDeliveryMethodSelection(true) }}
              />
            </span>
              </div>
          }
          <label htmlFor={address.id} className="w-100">
            <div className={styleClasses.inner_list_wrap}>
              <div className={`${styleClasses.info_label}  mb-2 d-flex justify-content-between align-items-center`}>
                <span className={styleClasses.info_label}> Name Your Saved Address</span>
                <div>
                  {" "}
                  <button
                  onClick={() => {
                    if(!address.is_default){
                      setRemoveDelivery(true);
                      setRemoveAddressId(address.id);
                    } else{
                      Toast_Func({
                        status:false,
                        message:'Default cannot be deleted'
                      })
                    }
                  }}
                    className="btn btn-custom f-s16 color-sky-blue-light ms-3 p-0"
                  >
                    <img
                      src={require("../../../../src/images/Remove.svg")}
                      alt="Remove icon"
                    />
                  </button>
                  <button
                    type="button"
                    className="btn btn-custom f-s16 text_underline clr-text-grey ms-3 p-0"
                    onClick={() =>
                      showModal(EDIT_ADDRESS_MODAL_TITLE, address.id)
                    }
                    data-testid="stored-payment-edit-button"
                  >
                    <img
                      src={require("../../../../src/images/Edit.svg")}
                      alt="Edit icon"
                    />
                  </button>
                </div>
              </div>
              <span className="d-flex justify-content-between align-items-center">
                <span className={styleClasses.info_text}>{address.address_name}</span>
              </span>
            </div>
            <div className={`${styleClasses.inner_list_wrap} mb-4`}>
              <span className="d-flex justify-content-between align-items-center">
                <span className={`${styleClasses.info_label} d-block mb-2`}>
                  Address
                </span>
              </span>
              <span
                className={`${styleClasses.info_text}`}
              >
                <div className={`${styleClasses.info_text}`}>{address.street_name}</div>
                <div className={`${styleClasses.info_text}`}>{address.city}, {address.state}{address.zipcode}</div>
              </span>
            </div>
            {
              address.apartment_no &&
                <div className={`${styleClasses.inner_list_wrap} mb-4`}>
              <span className="d-flex justify-content-between align-items-center">
                <span className={`${styleClasses.info_label} d-block mb-2`}>
                  Apt, Floor, Suite, Building, Company
                </span>
              </span>
                  <span
                      className={`${styleClasses.info_text}`}
                  >
                <div className={`${styleClasses.info_text}`}>{address.apartment_no}</div>
              </span>
                </div>
            }
          </label>
        </div>
      </div>
      <DefaultMethodConfirmationModal
        show={confirmDefaultDeliveryMethodSelection}
        title="Change Default Delivery Address"
        content="You’re requesting to change the default delivery address. Would you like to continue?"
        btnText={"Change"}
        handleClick={(e) => {
          handleChangeDefault(address.id)
        }}
        handleClose={() => {
          setConfirmDefaultDeliveryMethodSelection(false);
        }}
      />
    </div>
  );
};

export default DeliveryadressMobileDesign;
