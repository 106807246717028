import React from "react";
import { Spinner } from "react-bootstrap";

interface IButtonProps  {
  className: string;
  id?: string;
  type: 'submit' | 'reset' | 'button' | undefined;
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
  LoaderPosition?: string;
  isItSmall?: boolean;
}
const Button: React.FC<IButtonProps> = (props: IButtonProps) => {
  const {isItSmall = false} = props;
  const onButtonClick = () => {
    if(props.loading ) return;
    if (props.onClick) {
      props.onClick();
    }
  };
  return (
    <button
      type={props.type}
      className={`btn ${isItSmall ? 'btn-small': 'btn-large'} position-relative  ${props.className ?? ""}`}
      disabled={props.disabled}
      onClick={onButtonClick}
      id={props.id}
    >
      {props.children}
      {props.loading && (
        <Spinner animation={"border"} size="sm" className={`ms-2 ${props.LoaderPosition}`}  />
       )} 
    </button>
  );
};

export default Button;
