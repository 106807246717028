import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import {
  ForgotPasswordFormFields,
} from "../../../../models/forms.model";
import EmailLogin from "./EmailLogin";
import ForgotPassword from "../../../../containers/account/forgotPassword";
import GuestLogin from "./GuestLogin";
import SignUpForm from "./SignUpForm";
import SocailLogin from "./SocailLogin";
import {getUser} from "../../../../helpers/helperMethods";
import styles from './guestLogin.module.scss';

interface IGuestCheckoutModalProps {
  showContactForm?: boolean;
  handleCloseContactForm?: () => void;
  subTotal: number;
}

const GuestLoginModal = (props: IGuestCheckoutModalProps) => {
  const {
    showContactForm,
    handleCloseContactForm,
    subTotal
  } = props;
  const  currentGuestUserInfo = getUser()
  const [showGuestContactForm, setShowGuestContactForm] = useState<boolean>(false);
  const [showForgotPassword, setShowForgotPassword] = useState<boolean>(false);
  const [showSignUp, setshowSignUp] = useState<boolean>(false);
  const guestHandleclick = () => {
    setShowGuestContactForm(true);
  };
  const handleShowForgotPassword = () => {
    setShowForgotPassword(true);
  };
  const handleSignupClick = () => {
    setshowSignUp(true);
  };
  const handleSignInClick = () => {
    setshowSignUp(false);
  };
  const handleSubmitAsGuest = async (
      values: ForgotPasswordFormFields,
    ) => {
  };
  const handleCloseModal = () => {
    handleCloseContactForm();
    setShowGuestContactForm(false);
    setShowForgotPassword(false);
    setshowSignUp(false);
  }
  return (
    <>
      <Modal
        show={showContactForm}
        onHide={handleCloseModal}
        className= {`theme-modal-style p-0 app_container_wrap nor-padding border-radius-15  checkout-modal-mob ${styles.guest_login_modal}`}
        centered
      >
        <Modal.Header className="flex-column pt-5 pb-0 mx-3 position-relative">
          <button
            type="button"
            className="btn modal-close-button pe-0"
            onClick={handleCloseModal}
          >
            Close
          </button>
          {!showGuestContactForm && !showForgotPassword && (
            <>
              <h5 className="f-s22 font-Cls  text-center mx-auto text-capitalize full_width_mob">
                {showSignUp ? "Create A Loyalty Account" : "Log In"}
              </h5>
              <p className="f-s14 font-rale mb-0 text-center">
                {showSignUp
                  ? `You are almost there! Points towards free food are just a click away.`
                  : "Log In or Sign up to earn points towards free food."}
              </p>
              <SocailLogin />

              <span className="content-divider login-content-divider position-static my-0">
                <span className="f-s14 font-Visby-cf text-lowercase w-auto px-3 f-w5">
                  {showSignUp
                    ? "Or sign up with email"
                    : "Or log in with email"}
                </span>
              </span>
            </>
          )}
        </Modal.Header>
        <Modal.Body className="pb-4">
          {showGuestContactForm ? (
            <GuestLogin
              closeModal={handleCloseModal}
              currentGuestUserInfo={currentGuestUserInfo}
              subTotal={subTotal}
            />
          ) : (
            <>
              {showForgotPassword ? (
                <ForgotPassword isGuestModal={true} />
              ) : showSignUp ? (
                <SignUpForm closeModal={handleCloseModal} />
              ) : (
                <EmailLogin handleForgotPassword={handleShowForgotPassword} closeModal={handleCloseModal} />
              )}
            </>
          )}
          {!showGuestContactForm && !showForgotPassword && (
            <>
              <p className="p-16 f-s16 font-Visby-cf f-w8 text-center pt-3">
                {showSignUp
                  ? "Already have an account? "
                  : "Don’t have an account?"}
                <a
                  onClick={showSignUp ? handleSignInClick : handleSignupClick}
                  className="font-Visby-cf f-s16 underline clr-dark-blue"
                >
                  {showSignUp ? "Log In" : " Sign Up"}
                </a>
              </p>
              {
                  Boolean(process.env.REACT_APP_ENABLE_GUEST_CHECKOUT) ?
                      <a
                          onClick={guestHandleclick}
                          className="clr-dark-grey d-block f-s16 font-Visby-cf f-w8 text-center pt-2 underline"
                      >
                        {!showSignUp && "Proceed As Guest"}
                      </a>
                      :
                      null
              }
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GuestLoginModal;
