import React from "react";
import { Formik } from "formik";
import { useQueryClient } from "react-query";

import { FormField } from "../FormFields/FormField";
import { personalInfoSchema } from "../../validationSchemas/personalInfoSchema";
import { IPersonalInfoFormFields } from "../../models/forms.model";
import useProfile from "../../react-query-hooks/useProfile";
import useUpdateProfile from "../../react-query-hooks/useUpdateProfile";
import { useAuthState } from "../../context/UserAuthentication";
import { Toast_Func } from "../../helpers/toast.helper";
import { API_ERROR } from "../../models/error.model";

interface FormProps {
  closeModal: () => void;
}

export const PersonalInfoForm: React.FunctionComponent<FormProps> = ({
  closeModal,
}) => {
  const { mutateAsync } = useUpdateProfile();
  const { authInfo } = useAuthState();
  const { data } = useProfile(authInfo.userId);
  const queryClient = useQueryClient();

  const initialFormState: IPersonalInfoFormFields = {
    first_name: data.first_name,
    last_name: data.last_name,
    email: data.email,
    phone: data.phone,
    birthday: data.birthday,
  };

  const handleFormSubmission = async (
    values: IPersonalInfoFormFields,
    { setSubmitting }: any
  ) => {
    const finalValues = {
      id: authInfo.userId,
      ...(values.first_name != initialFormState.first_name && { first_name: values.first_name }),
      ...(values.last_name != initialFormState.last_name && { last_name: values.last_name }),
      ...(values.email != initialFormState.email && { email: values.email }),
      ...(values.phone != initialFormState.phone && { phone: values.phone }),
      ...(values.birthday != initialFormState.birthday && { birthday: values.birthday }),
    }
    setSubmitting(true);
    return mutateAsync(
      {
        editedProfile: finalValues,
        authentication_token: authInfo.authentication_token,
      },
      {
        onSuccess: (data, variables, context) => {
          queryClient.invalidateQueries("profile");
          closeModal();
          Toast_Func({
            status: true,
            message: "Profile successfully updated.",
          });
        },
        onError: (error, variables, context) => {
          const {
            data: {
              data: { errors },
            },
          } = (error as API_ERROR).response;
          closeModal();
          Toast_Func({ status: false, message: errors[0] });
        },
      }
    );
  };
  var str: number | string = new Date().getDate();
  var str2: number | string = (new Date().getMonth() + 1);
  if (new Date().getDate() < 10) {
    str = '0' + str;
  }
  if ((new Date().getMonth() + 1) < 10) {
    str2 = '0' + str2;
  }
  var maxValue = new Date().getFullYear() + '-' + str2 + '-' + str;

  return (
    <Formik
      initialValues={initialFormState}
      validationSchema={personalInfoSchema}
      onSubmit={handleFormSubmission}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        isSubmitting,
        submitForm,
      }) => (
        <form className="new_form_design" onSubmit={handleSubmit}>
          <div className="form-group">
            <label className="d-flex justify-content-between">
              EMAIL ADDRESS
              <span className="clr-text-grey f-s13 text-lowercase font-rale f-w5 ls-normal">
                cannot be edited
              </span>
            </label>
            <input
              type="email"
              className="form-control"
              readOnly
              value={values.email}
              disabled={true}
            />
          </div>
          <FormField
            labelText={"FIRST NAME"}
            name="first_name"
            type="text"
            onChange={handleChange}
            errors={errors}
            touched={touched}
            value={values.first_name}
          />
          <FormField
            labelText={"LAST NAME"}
            name="last_name"
            type="text"
            onChange={handleChange}
            errors={errors}
            touched={touched}
            value={values.last_name}
          />
          <FormField
            labelText={"PHONE NUMBER"}
            name="phone"
            type="text"
            onChange={handleChange}
            errors={errors}
            touched={touched}
            value={values.phone}
          />
          <div className="form-group">
            <label className="d-flex justify-content-between">
              BIRTHDAY
            </label>
            <div className="position-relative">
              <input
                name="birthday"
                type="date"
                className="form-control no-style type-data"
                max={maxValue}
                onKeyDown={(e) => e.preventDefault()}
                onChange={handleChange}
                value={values.birthday}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="d-flex justify-content-between">
              FAVORITE LOCATION
            </label>
            <div className="position-relative">
              <input
                name="favorite_location"
                type="text"
                className="form-control pe-4"
                onChange={handleChange}
              />
              <button type="button" className="btn btn-custom p-0 f-s0 arrow-right position-absolute">arrow</button>
            </div>
          </div>
          <button
            type="button"
            className="btn btn-large py-2 f-s16 w-100"
            onClick={submitForm}
            disabled={isSubmitting}
          >
            CONFIRM CHANGES
          </button>
        </form>
      )}
    </Formik>
  );
};
