import { Formik } from 'formik'
import React, {useEffect, useState} from 'react'
import {VehicleInfoFormFields} from '../../../../../models/forms.model'
import { FormField } from '../../../../FormFields/FormField'
import useVehicleList from "../../../../../react-query-hooks/useVehicleList";
import useAddVehicleInfo from "../../../../../react-query-hooks/useAddVehicleInfo";
import {useAuthState} from "../../../../../context/UserAuthentication";
import {Toast_Func} from "../../../../../helpers/toast.helper";
import {API_ERROR} from "../../../../../models/error.model";
import {useQueryClient} from "react-query";
import {useAppDispatch, useAppSelector} from "../../../../../redux/store/store";
import { updateOrderDetails } from "../../../../../redux/slices/cartSlice";
import {vehicleInfoSchema} from "../../../../../validationSchemas/vehicleInfoSchema";
import {isGuestUser} from "../../../../../helpers/helperMethods";

interface IAddNewVehicle {
    showAddNewVehicleDetails: (isShown) => void;
    isShownVehicleDetails: (isShown) => void;
    handleNewAddedUserVehicle: (vehicle) => void;
    isNewVehicle? : boolean;
    closeShowVehicleModal? : (isShown) => void;
    editGuestVehicle: VehicleInfoFormFields;
}
const AddNewVehicle = (props: IAddNewVehicle) => {
    const {
        showAddNewVehicleDetails,
        isShownVehicleDetails,
        handleNewAddedUserVehicle,
        isNewVehicle,
        closeShowVehicleModal,
        editGuestVehicle
    } = props
    const { data: vehicleList, isFetching} = useVehicleList()
    const [vehicleMake, setVehicleMake] = useState([])
    const [vehicleModels, setVehicleModels] = useState([])
    const orderTypeDispatch = useAppDispatch()

    const [ colors, setColors ] = useState([])
    const { mutateAsync: addVehicleInfo } = useAddVehicleInfo()
    const { authInfo } = useAuthState();
    const queryClient = useQueryClient();
    let orderDetails = useAppSelector(
        (state) => state.cart.orderDetails
    );
    

    const valueModifier = (value: string) => { return { value: value, label: value } };

    useEffect(()=>{
        if( editGuestVehicle?.make && vehicleModels.length === 0) modelsValue(editGuestVehicle?.make)
    },[editGuestVehicle, vehicleList ])

    const initialFormState: VehicleInfoFormFields = {
        make: editGuestVehicle?.make || "",
        model: editGuestVehicle?.model || "",
        color: editGuestVehicle?.color || "",
        is_default: editGuestVehicle?.is_default || false,
    };

    const handleNewAdded = (orderDetails) => {
        orderTypeDispatch(updateOrderDetails(orderDetails))
        closeShowVehicleModal(true)
    }

    const handleFormSubmission = async (
        values: VehicleInfoFormFields
    ) => {
        let modifiedValues = {
            ...values,
            customer_id: authInfo.userId,
            is_default: 0,
        };
        orderDetails = {
            ...orderDetails,
            vehicleDetails: modifiedValues
        }
        !isGuestUser(authInfo) ? addVehicleInfo(
            {
                vehicleInfo: modifiedValues,
            },
            {
                onSuccess: (data, variables, context) => {
                    queryClient.refetchQueries("profile");
                    orderTypeDispatch(updateOrderDetails(orderDetails))
                    handleNewAddedUserVehicle({value: data.make, label: `${data.make} ${data.model} ${data.color}`, id: data.id, isDefault: data.is_default})
                    isNewVehicle ? showAddNewVehicleDetails(false) : closeShowVehicleModal(true)
                    Toast_Func({
                        status: true,
                        message: "Vehicle information successfully added",
                    });
                },
                onError: (error, variables, context) => {
                    const {
                        data: {
                            data: { errors },
                        },
                    } = (error as API_ERROR).response;
                    Toast_Func({ status: false, message: errors[0] });
                },
            }
        )
        : handleNewAdded(orderDetails)
    };

    const modelsValue = (makeType) => {
        const models = vehicleList?.vehicles
            .find((element: any) => element.make === makeType)
            ?.model.map((element) => ({ value: element, label: element }))
        setVehicleModels(models)
    };

    useEffect(() => {
        if(vehicleList?.vehicles)
        {
            const vehicles = vehicleList.vehicles.map((vehicle) => ({value: vehicle.make, label: vehicle.make}))
            let vehicleColors = vehicleList.colors.map((element) => ({ value: element, label: element }));
            setVehicleMake(vehicles)
            setColors(vehicleColors)
        }
    }, [vehicleList]);

    return (
        <div>
            <div className="px-md-5 px-0">
                <button className="ps-0 btn btn-custom f-s14 font-rale   align-items-center gap-2 d-flex mb-2"
                onClick={ () =>{ isNewVehicle ? showAddNewVehicleDetails(false) : isShownVehicleDetails(false)} }>
                    <img src={require("../../../../../../src/images/arrow-back.svg")} alt="arrow back"/>
                    Back
                </button>
                <h5 className="f-s18 font-Cls py-2 text-md-start text-center w-100  mb-2 text-capitalize">
                    Add Vehicle
                </h5>

                <div>
                    <Formik
                        initialValues={initialFormState}
                        validationSchema={vehicleInfoSchema}
                        onSubmit={handleFormSubmission}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            setFieldValue,
                            handleSubmit,
                            isSubmitting,
                            submitForm,
                        }) => (
                            <form className="new_form_design" onSubmit={handleSubmit}>
                                <FormField
                                   value={values.make ? valueModifier(values.make) : null}
                                   options={vehicleMake}
                                   name={"make"}
                                   type={"select"}
                                   labelText={"MAKE"}
                                   placeholder={"Make a selection"}
                                   errors={errors}
                                   touched={touched}
                                   onChange={(make) => {
                                     setFieldValue("make", make.value);
                                     setFieldValue("model", "");
                                     modelsValue(make.value);
                                   }}
                                   inputFieldClass={"custom_select mb-0"}
                                   handleLoading={{enable: isFetching, inputValue: "Loading..."}}
                                   isSearchable={true}

                                />
                                <FormField
                                     value={values.model ? valueModifier(values.model) : null}
                                     // options={modelsValue(values.model)}
                                     options={vehicleModels}
                                     name={"model"}
                                     type={"select"}
                                     labelText={"Model"}
                                     placeholder={"Make a selection"}
                                     errors={errors}
                                     touched={touched}
                                     onChange={(model) => setFieldValue("model", model.value)}
                                     inputFieldClass={"custom_select mb-0"}
                                     handleLoading={{enable: isFetching, inputValue: "Loading..."}}
                                     isSearchable={true}
                                />
                                <FormField
                                     value={values.color ? valueModifier(values.color) : null}
                                     options={colors}
                                     name={"color"}
                                     type={"select"}
                                     labelText={"COLOR"}
                                     placeholder={"Make a selection"}
                                     errors={errors}
                                     touched={touched}
                                     onChange={(color) => setFieldValue("color", color.value)}
                                     inputFieldClass={"custom_select mb-0"}
                                     handleLoading={{enable: isFetching, inputValue: "Loading..."}}
                                     isSearchable={true}
                                />
                                <div className="d-flex justify-content-center mt-4">
                                    <button
                                        type="button"
                                        className="btn btn-large py-2 f-s16 mt-3 mb-5 w-75"
                                        onClick={submitForm}
                                        disabled={isSubmitting}
                                    >
                                        Save Vehicle
                                    </button>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    )
}

export default AddNewVehicle