import { useMutation } from "react-query";
import {customerBusinessService, paymentService} from "../services";

const addGiftCard = async (newGiftCard: any) => {
    const response = await customerBusinessService.addGiftCard(newGiftCard)
    return response.data.data;
};

export default function useAddGiftCard() {
    return useMutation((data) =>
        addGiftCard(data)
    );
}
